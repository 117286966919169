import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import * as S from './styles';

export default function MultiItem({
  value,
  onRemove,
  name,
}) {
  return (
    <S.MultiItem title={value}>
      <S.TruncatedText>{value}</S.TruncatedText>
      <S.Close onClick={() => { onRemove(value, name); }}>
        <CloseIcon />
      </S.Close>
    </S.MultiItem>
  );
}

MultiItem.propTypes = {
  onRemove: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
