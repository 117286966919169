import {
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import { merge } from 'lodash';
import Layout from 'layouts/main-layout';
import NoMatches from 'pages/no-matches';
import LoginPage from 'pages/login';
import pages from './pages';
import routes from './routes';
import PreviewRoute from './preview-route';
import CheckinRoute from './checkin-route';

const routesWithElements = Object.values(merge({}, routes, pages));

const appRoutes = {
  previewable: routesWithElements.filter((route) => route.canBePreviewed),
  private: routesWithElements,
};

export default function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/404" />} />
      <Route
        path="/404"
        element={(
          <NoMatches
            isUnlogged
            title="Looks like you don't have a magic link"
            message={`
              You can request a new magic
              link in your service company.
            `}
          />
        )}
      />

      <Route path="/:portalId/login" element={<LoginPage />} />

      <Route path="/:portalId/preview" element={<PreviewRoute />}>
        <Route element={<Layout />}>
          {appRoutes.previewable.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.element}
            >
              {route?.nested && Object.values(route.nested).map((nestedRoute) => (
                <Route
                  key={nestedRoute.path}
                  path={nestedRoute.index ? null : nestedRoute.path}
                  index={nestedRoute.index}
                  element={nestedRoute.element}
                />
              ))}
            </Route>
          ))}
        </Route>
        <Route path="*" element={<Navigate to={routes.appointments.path} replace />} />
      </Route>

      <Route path="/:portalId" element={<CheckinRoute />}>
        <Route index element={<Navigate to={routes.appointments.path} replace />} />
        <Route element={<Layout />}>
          {appRoutes.private.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.element}
            >
              {route?.nested && Object.values(route.nested).map((nestedRoute) => (
                <Route
                  key={nestedRoute.path}
                  path={nestedRoute.index ? null : nestedRoute.path}
                  index={nestedRoute.index}
                  element={nestedRoute.element}
                />
              ))}
            </Route>
          ))}
        </Route>
        <Route path="*" element={<Navigate to={routes.appointments.path} replace />} />
      </Route>
    </Routes>
  );
}
