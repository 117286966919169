import ContentLoader from 'react-content-loader';

export default function ServicePlanSubscriptionInvoiceLoader() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="908px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="92px" />
      <rect x="0" y="108" rx="3" ry="3" width="100%" height="92px" />
      <rect x="0" y="216" rx="3" ry="3" width="100%" height="92px" />
    </ContentLoader>
  );
}
