import styled from 'styled-components';
import {
  defaultColor,
  font,
  fluidFont,
} from 'styles/theme';

export const Text = styled.p`
  font-family: ${font('semibold')};
  color: ${defaultColor('metallic')};
  ${fluidFont('11px', '13px')};
`;
