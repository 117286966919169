import styled from 'styled-components';
import { spacing } from 'styles/theme';

export const List = styled.ul`
  list-style: none;
  padding: none;
  /* margin: 2rem 0 0 0; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: ${spacing('default')};
`;

export const ListItem = styled.li`
  position: relative;
`;
