import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import AppointmentItem from 'components/appointments/appointment-item';
import * as S from './styles';

export default function ListOfDay({ appointments, isCompact }) {
  return (
    <S.ListOfDay>
      {appointments.map((item) => (
        <li key={item.id}>
          <AppointmentItem
            id={item.id}
            title={item.title}
            type={item.businessEntityType}
            address={item.serviceAddress}
            unitNumber={item.unitNumber}
            totalAmount={item.totalAmount}
            date={parseISO(item.start)}
            isCompact={isCompact}
            employees={item.employees}
            invoice={item.invoiceSent}
            variant="column"
            status={item.status}
          />
        </li>
      ))}
    </S.ListOfDay>
  );
}

ListOfDay.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appointments: PropTypes.arrayOf(PropTypes.object),
  isCompact: PropTypes.bool,
};

ListOfDay.defaultProps = {
  appointments: [],
  isCompact: false,
};
