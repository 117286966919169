import styled from 'styled-components';
import { defaultColor } from 'styles/theme';

export const Box = styled.a`
  display: flex;
  text-decoration: none;
  gap: 0.5rem;
  color: ${({ color }) => (defaultColor(color) || color)};
  &:hover {
    filter: brightness(80%);
  }
`;

export const Marker = styled.span`
  height: 22px;
  svg {
    height: 100%;
    fill: currentColor;
  }
`;
