import { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import SignatureCanvas from 'react-signature-canvas';
import * as S from './styles';
import Typography from '../typography';

export default function SignatureBox({ onChanged }) {
  const signatureRef = useRef(null);

  const clear = () => {
    if (!signatureRef.current) return;

    signatureRef.current.clear();
    onChanged(null);
  };

  const onEnd = () => {
    if (!signatureRef.current) return;
    const sigPad = signatureRef.current;

    const touches = sigPad.toData();
    let signature = null;

    if (touches && touches.length) {
      signature = {
        base64: sigPad.getTrimmedCanvas().toDataURL('image/png'),
      };

      // Remove color field from each point
      signature.touches = touches.map((touch) => {
        return {
          points: touch.map(({ x, y, time }) => ({ x, y, time })),
        };
      });
    }

    onChanged(signature);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      if (!signatureRef.current) {
        return;
      }
      const sigPad = signatureRef.current;

      const canvas = sigPad.getCanvas();
      if (!canvas) {
        return;
      }

      /* When zoomed out to less than 100%, for some very strange reason,
        some browsers report devicePixelRatio as less than 1
        and only part of the canvas is cleared then. */
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      const data = sigPad.toData();
      const context = canvas.getContext('2d');

      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      context.scale(ratio, ratio);

      sigPad.fromData(data);
    });
  });

  return (
    <S.SignatureBox>
      <SignatureCanvas
        minWidth={1}
        maxWidth={3}
        clearOnResize={false}
        ref={signatureRef}
        onEnd={onEnd}
        canvasProps={{ style: { width: '100%', height: '100%' } }}
      />
      <S.ClearButton
        onClick={clear}
      >
        <S.EraserIcon />
        <Typography fontWeight="bold">Clear</Typography>

      </S.ClearButton>
    </S.SignatureBox>
  );
}

SignatureBox.propTypes = {
  onChanged: PropTypes.func.isRequired,
};
