import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Typography } from 'components/ui-kit';

import AttachedForm from './attached-form/index';

export default function AttachedFormsList({ entityType, checkLists, eventId }) {
  if (!checkLists || !checkLists.length) return null;

  return (
    <Stack>
      <Typography color="metallic" fluidFont={{ min: 11, max: 13 }} lh="18px">Forms</Typography>

      <Stack gap={4}>
        {checkLists.map((checkList) => (
          <AttachedForm
            key={`${checkList.id}`}
            entityType={entityType}
            checkList={checkList}
            eventId={eventId}
          />
        ))}
      </Stack>
    </Stack>
  );
}

AttachedFormsList.propTypes = {
  entityType: PropTypes.number.isRequired,
  checkLists: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
  eventId: PropTypes.string.isRequired,
};
