import { useQuery } from 'react-query';
import { getServicePlanInvoices } from 'api';
import { useApp } from 'hooks';
import ListPlaceholder from 'components/ui-kit/list-placeholder';
import ServicePlanInvoiceItem from '../item';
import ServicePlanSubscriptionInvoiceLoader from '../item/loader';
import * as S from './styles';

export default function ServicePlanInvoiceListing() {
  const { portal } = useApp();
  const { data, isSuccess, isLoading } = useQuery(
    ['servcePlanInvoices', portal?.id],
    getServicePlanInvoices,
    {
      enabled: !!portal?.id,
    },
  );

  if (isLoading) {
    return (
      <S.Box>
        <ServicePlanSubscriptionInvoiceLoader />
      </S.Box>
    );
  }

  if (isSuccess && data?.data?.length === 0) {
    return <ListPlaceholder message="No subscription invoices found" />;
  }

  return (
    <S.Box>
      {data?.data?.map((item) => (
        <ServicePlanInvoiceItem
          key={item.id}
          id={item.id}
          portalId={portal?.id}
          servicePlanName={item.servicePlanName}
          paidDate={item.paidDate}
          paymentDate={item.paymentDate}
          status={item.status}
          totalAmount={item.totalAmount}
          paymentMethodType={item.paymentMethodType}
          cardInfo={item.cardInfo}
        />
      ))}
    </S.Box>
  );
}
