import styled from 'styled-components';
import {
  defaultColor,
  font,
  radius,
  fluidFont,
} from 'styles/theme';

export const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const Image = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: ${radius('full')};
  background-color: ${defaultColor('backgroundLight')};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    opacity: 0.2;
    width: 50%;
  }
`;

export const Message = styled.p`
  color: ${defaultColor('metallic')};
  font-family: ${font('semibold')};
  ${fluidFont('16px', '18px')};
`;
