import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { QuestionIcon } from 'assets/icons';

import { getDefaultColor, toPixels } from 'styles/theme';

import { TooltipContainer, TooltipText } from './styles';

const Tooltip = ({ text, maw, icon }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = () => {
    setIsVisible(false);
  };

  const Icon = icon || <QuestionIcon fill={getDefaultColor('metallic')} />;

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Icon}

      <TooltipText isVisible={isVisible} maw={toPixels(maw)}>
        {text}
      </TooltipText>
    </TooltipContainer>
  );
};

Tooltip.propTypes = {
  maw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string.isRequired,
  icon: PropTypes.element,
};

Tooltip.defaultProps = {
  maw: 300,
  icon: null,
};

export default Tooltip;
