import { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQueryClient } from 'react-query';

import { approveEstimate } from 'api';

import { SignatureBox } from 'components/ui-kit/signature';
import ButtonGroup from 'components/ui-kit/button-group';
import Button from 'components/ui-kit/buttons/button';
import Typography from 'components/ui-kit/typography';
import Modal from 'components/ui-kit/modal';

import { useApp, useModal, useToast } from 'hooks';

import * as S from './styles';

const ApproveEstimateModal = ({ id, estimateTitle, lineItems }) => {
  const { hideModal } = useModal();
  const { portal } = useApp();
  const portalId = portal?.id;
  const toast = useToast();
  const queryClient = useQueryClient();

  const [signature, setSignature] = useState(null);

  const approveMutation = useMutation(() => (approveEstimate(portalId, {
    id,
    signature,
    lineItems,
  })), {
    onSuccess: () => queryClient.invalidateQueries(['appointment', portal?.id, id]),
    onError: () => {
      toast.addErrorToast('Something went wrong during approval');
    },
  });

  const sign = async () => {
    await approveMutation.mutateAsync({ customerSignature: signature });
    hideModal();
  };

  return (
    <Modal size="4/10" title="Approve estimate">
      <Typography
        fluidFont={{
          min: 12, max: 16,
        }}
      >
        You are going to approve estimate
        {' '}
        <strong>{estimateTitle}</strong>
        . You won’t be able to undo this action.
        {' '}
        <br />
        You can add your signature.
      </Typography>
      <S.Box>
        <SignatureBox onChanged={(s) => setSignature(s)} />
      </S.Box>
      <Typography
        fluidFont={{
          min: 12, max: 16,
        }}
      >
        By signing, you accept this Estimate, associated costs, Privacy Policy and Terms and conditions.
      </Typography>
      <ButtonGroup justifyContent="flex-end" topGap="1rem">
        <Button onClick={hideModal}>Cancel</Button>
        <Button
          color="success"
          onClick={sign}
          isLoading={approveMutation.isLoading}
          disabled={!signature}
        >
          Approve
        </Button>
      </ButtonGroup>
    </Modal>
  );
};

ApproveEstimateModal.propTypes = {
  id: PropTypes.string.isRequired,
  estimateTitle: PropTypes.string.isRequired,
  lineItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    unitPrice: PropTypes.number.isRequired,
  })).isRequired,
};

export default ApproveEstimateModal;
