import { useQuery } from 'react-query';

import { getEstimatesRequests } from 'api';

import ListPlaceholder from 'components/ui-kit/list-placeholder';
import AppointmentLoader from 'components/appointments/appointment-loader';
import { Field, Select } from 'components/ui-kit/form';
import RequestItem from 'components/request-item';

import { useApp, useFields } from 'hooks';

import { estimateApprovalTypes } from 'helpers/enums';

import * as S from './styles';

const approvalOptions = [{ label: 'All', value: 'all' }, ...Object.values(estimateApprovalTypes)]
  .map((item) => ({ ...item, value: String(item.value) }));

const getFilterByStatus = (selected) => (
  (item) => selected === 'all' || item.approvalStatus === Number(selected)
);

const RequestsList = () => {
  const { portal } = useApp();
  const { fields, handleClickChange } = useFields({
    status: approvalOptions[0].value,
  });

  const { data, isSuccess, isLoading } = useQuery(
    ['estimatesRequests', portal?.id],
    getEstimatesRequests,
    {
      enabled: !!portal?.id,
    },
  );

  const list = data?.data?.filter(getFilterByStatus(fields.status));

  if (isLoading) {
    return (
      <AppointmentLoader />
    );
  }

  if (isSuccess && data?.data?.length === 0) {
    return <ListPlaceholder message="No work estimated yet" />;
  }

  return (
    <S.List>
      <S.Selector>
        <Field
          name="status"
          label="Status"
          value={fields.status}
          onChange={handleClickChange}
          options={approvalOptions}
          element={Select}
        />
      </S.Selector>

      {list?.map((item) => (
        <S.ListItem key={item.id}>
          <RequestItem
            id={item.id}
            title={item.title}
            sentDate={item.estimateSent}
            address={item.serviceAddress}
            unitNumber={item.unitNumber}
            totalAmount={item.totalAmount}
            approvalStatus={item.approvalStatus}
            estimateNumber={item.estimateNumber}
            optionNumber={item.optionNumber}
            hasOptions={item.hasOptions}
          />
        </S.ListItem>
      ))}
    </S.List>
  );
};

export default RequestsList;
