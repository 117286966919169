import React from 'react';
import PropTypes from 'prop-types';
import useCurrency from 'hooks/useCurrency';

function Money({
  amount,
  currency,
  fallback,
}) {
  const { getCurrency } = useCurrency();

  if (typeof amount === 'string') {
    return amount || fallback;
  }

  if (typeof amount !== 'number') {
    return fallback;
  }

  const currencyCode = currency || getCurrency();

  const formatted = new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount);

  return (
    <>
      {formatted}
      &nbsp;
      {currencyCode}
    </>
  );
}

Money.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  currency: PropTypes.string,
  fallback: PropTypes.string,
};

Money.defaultProps = {
  amount: '',
  currency: null,
  fallback: '',
};

export default Money;
