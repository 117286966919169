import PropTypes from 'prop-types';
import * as S from './styles';

export default function RequestTitle({
  title,
  number,
  option,
  hasOptions,
  baseFontSize,
}) {
  return (
    <S.TitleBox baseFontSize={baseFontSize}>
      <S.Title baseFontSize={baseFontSize}>
        {title || 'Estimate'}
      </S.Title>
      <S.Number baseFontSize={baseFontSize}>
        {number}
      </S.Number>
      {hasOptions && (
      <S.OptionNumber baseFontSize={baseFontSize}>
        #
        {option}
      </S.OptionNumber>
      )}
    </S.TitleBox>
  );
}

RequestTitle.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  option: PropTypes.number,
  hasOptions: PropTypes.bool,
  baseFontSize: PropTypes.number,
};

RequestTitle.defaultProps = {
  title: '',
  number: '',
  option: 0,
  hasOptions: false,
  baseFontSize: 14,
};
