import React from 'react';
import PropTypes from 'prop-types';

import AppointmentItemDetails from 'components/appointments/appointment-item-details';

const AppointmentDetails = ({
  breadcrumbs,
}) => {
  return (
    <AppointmentItemDetails breadcrumbs={breadcrumbs} />
  );
};

AppointmentDetails.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    to: PropTypes.string,
  })),
};

AppointmentDetails.defaultProps = {
  breadcrumbs: [{ text: 'Appointments', to: '../appointments' }],
};

export default AppointmentDetails;
