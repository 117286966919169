import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  defaultColor,
  companyColor,
  font,
  fluidFont,
} from 'styles/theme';

export const LinkBox = styled(Link)`
  text-decoration: none;
  color: ${companyColor('font')};
  font-family: ${font('semibold')};
  ${fluidFont('12px', '14px')};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 5px;
  z-index: 1;
  transition: color 210ms linear;

  &.active {
    color: ${defaultColor('white')};
  }
`;
