import styled from 'styled-components';
import { animated } from '@react-spring/web';
import Calendar from 'react-calendar';
import {
  radius,
  defaultColor,
  companyColor,
  font,
  device,
  fluidFont,
} from 'styles/theme';
import { FormControlStlyes } from '../styles';

export const PickerMenu = styled(animated.div)`
  background-color: ${defaultColor('white')};
  border-radius: ${radius('small')};
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 1rem;
  z-index: 10;
`;

export const Box = styled.div`
  position: relative;
`;

export const CalendarBox = styled(Calendar)`
  &.react-calendar {
    border-radius: ${radius('small')};
    border-color: ${companyColor('font')};
    width: 100%;

    @media only screen and (${device('tablet')}) {
      width: 350px;
    }
  }
  .react-calendar__month-view__days {
    height: 320px;

    @media only screen and (${device('tablet')}) {
      height: auto;
    }
  }
  .react-calendar__tile,
  .react-calendar__navigation__label {
    font-family: ${font('semibold')};
  }
  .react-calendar__month-view__weekdays {
    font-family: ${font('bold')};
    color: ${companyColor('font')};
  }

  .react-calendar__navigation {
    &__label {
      color: ${companyColor('font')};
    }
    &__prev2-button {
      border-top-left-radius: ${radius('small')};
    }
    &__next2-button {
      border-top-right-radius: ${radius('small')};
    }
  }

  .react-calendar__tile {
    &--now {
      background: transparent;
      color: ${companyColor('main')};
    }

    &--active,
    &--hasActive {
      background: ${companyColor('main')};
      color: ${defaultColor('white')};
      &:hover {
        background: ${companyColor('main', 0.75)};
      }
      &:enabled:focus {
        background: ${companyColor('main')};
      }
    }
  }
`;

export const Input = styled.input`
  ${FormControlStlyes};
`;

export const ErrorMessage = styled.div`
    display: block;
    position: absolute;
    font-family: ${font('regular')};
    ${fluidFont('11px', '12px')};
    color: ${defaultColor('red')};
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 4px;
`;
