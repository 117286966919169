import React from 'react';
import { useApp } from 'hooks';
import { useQuery } from 'react-query';
import { getCards } from 'api';
import CardItem from 'components/ui-kit/card-item';
import CardsLoader from './loader';
import * as S from './styles';

export default function CardsListing() {
  const { portal } = useApp();
  const {
    isError, isLoading, isFetching, data, isSuccess,
  } = useQuery(['cardsListingQuery', portal?.id], getCards, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!portal?.id,
  });

  if (isLoading || isFetching) {
    return (
      <CardsLoader />
    );
  }

  if (isError) {
    return (
      <S.TextInfo>Error occured while fetching cards</S.TextInfo>
    );
  }
  if (!data?.data.length && isSuccess) {
    return (
      <S.TextInfo>No cards connected</S.TextInfo>
    );
  }

  return (
    <S.Listing>
      {data?.data.map((item, index) => (
        <CardItem
          key={`${String(index)}`}
          last4={item.last4}
          expMonth={item.expMonth}
          expYear={item.expYear}
          brand={item.brand}
        />
      ))}
    </S.Listing>
  );
}
