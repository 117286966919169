import { createContext, useContext } from 'react';

const SecondaryModalContext = createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
  setLoaded: () => {},
  isLoaded: true,
});

export const SecondaryModalConsumer = SecondaryModalContext.Consumer;

export const useModal = () => (useContext(SecondaryModalContext));

export default SecondaryModalContext;
