import styled from 'styled-components';
import {
  defaultColor,
  radius,
  font,
  fluidFont,
  companyColor,
} from 'styles/theme';

export const ConfirmationBlock = styled.div`
  display: flex;
  flex-direction: column;
  height: 200px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 30%;
  text-align: center;
  height:auto;
`;

export const GotIt = styled.div`
  margin-top: 30px;
  width: 100%;
`;

export const ContanctsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`;

export const Response = styled.div`
  color: ${companyColor('font')};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const Image = styled.div`
  width: 10rem;
  height: 10rem;
  border-radius: ${radius('full')};
  background-color: ${defaultColor('backgroundLight')};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 40%;
  }
`;

export const Text = styled.span`
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
`;

export const TextSilver = styled(Text)`
  color: ${defaultColor('metallic')};
  margin-bottom: -25px;
  margin-top: 10px;
`;
