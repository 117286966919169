import { createContext } from 'react';

const ToastContext = createContext({
  addToast: () => {},
  removeToast: () => {},
  addSuccessToast: () => {},
  addErrorToast: () => {},
});

export default ToastContext;
