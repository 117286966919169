import styled from 'styled-components';
import {
  companyColor, device, fluidFont, font, spacing,
} from 'styles/theme';

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;

  align-self: center;
  font-family: ${font('semibold')};
  ${({ baseFontSize }) => fluidFont(`${baseFontSize}px`, `${baseFontSize + 2}px`)};
  color: ${companyColor('font')};
  grid-area: title;

  @media only screen and (${device('laptop')}) {
    align-self: start;
  }
`;

export const Title = styled.div`
`;

export const NumberGroup = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing('default')};
`;

export const Number = styled.div`
  ${({ baseFontSize }) => fluidFont(`${baseFontSize - 2}px`, `${baseFontSize}px`)};
  color: ${companyColor('font', 0.3)};
`;

export const OptionNumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${font('regular')};
  ${({ baseFontSize }) => fluidFont(`${baseFontSize - 2}px`, `${baseFontSize}px`)};
  background-color: ${() => {
    return companyColor('background', 0.2);
  }};
  padding: 2px;
  border-radius: 2px;
`;
