import PropTypes from 'prop-types';
import { useApp } from 'hooks';
import DateLine from 'components/ui-kit/date-line';
import PaidSection from 'components/paid-section';
import Money from 'components/ui-kit/money';
import * as S from './styles';
import InvoicePayButton from '../invoice-pay-button';

export default function InvoiceItem({
  id,
  title,
  sentDate,
  paidDate,
  totalAmount,
  portalId,
}) {
  const { isBankAccountConnected } = useApp();

  return (
    <S.Box to={id} state={{ title }}>
      <S.TypeIcon />
      <S.Title>{title}</S.Title>
      <S.Dates>
        <DateLine date={sentDate} label="Sent Date" />
        <DateLine date={paidDate} dateColor={paidDate ? 'default' : 'blue'} label="Paid Date" />
      </S.Dates>
      <S.Amount>
        <Money amount={totalAmount} />
      </S.Amount>
      <S.Status isPaid={!!paidDate}>
        {paidDate ? (
          <PaidSection />
        ) : (
          isBankAccountConnected
          && (
          <InvoicePayButton
            color="primary"
            invoiceId={id}
            portalId={portalId}
          />
          )
        )}
      </S.Status>
    </S.Box>
  );
}

InvoiceItem.propTypes = {
  id: PropTypes.string.isRequired,
  portalId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sentDate: PropTypes.string.isRequired,
  paidDate: PropTypes.string,
  totalAmount: PropTypes.number.isRequired,
};

InvoiceItem.defaultProps = {
  paidDate: null,
};
