import { capitalizeFirst } from 'helpers/text.helper';
import { estimateApprovalTypes } from 'helpers/enums';

import * as S from './styles';

export const APPROVAL_STATUSES = Object.values(estimateApprovalTypes);

export const getModalContent = (estimateNumber, actionName) => (
  <S.ModalText>
    By pressing
    {' '}
    <b>{capitalizeFirst(actionName)}</b>
    {' '}
    you confirm you would like to
    {' '}
    {actionName}
    {' '}
    estimate
    {' '}
    {estimateNumber}
    .
    {actionName === 'decline' && ' We will contact you to arrange for the service.'}
  </S.ModalText>
);
