import styled from 'styled-components';

import { ReactComponent as IconCamera } from 'assets/icons/camera.svg';
import { DeleteIcon, EyeIcon } from 'assets/icons';

import { getDefaultColor } from 'styles/theme';

export const Container = styled.div`
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  border-radius: 8px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
  }
`;

export const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  border: 1px dashed ${getDefaultColor('neutral500')};
  border-radius: 8px;
  background: ${getDefaultColor('neutral200')};
  transition: background 0.3s ease;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${getDefaultColor('neutral300')};
  }
`;

export const UploadPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: ${getDefaultColor('neutral500')};
`;

export const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: rgba(31, 45, 92, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  color: white;
  margin: 0 5px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const TrashIcon = styled(DeleteIcon)`
  width: 24px;
  height: 24px;

  stroke: ${getDefaultColor('neutral300')};
  fill: ${getDefaultColor('neutral100')};
`;

export const ShowIcon = styled(EyeIcon)`
  width: 24px;
  height: 24px;

  stroke: ${getDefaultColor('neutral300')};
  fill: ${getDefaultColor('neutral100')};
`;

export const UploadIcon = styled(IconCamera)`
  width: 24px;
  height: 24px;

  stroke: ${getDefaultColor('neutral500')};
`;

export const TextWrapper = styled.span`
  margin-top: 4px;
  font-size: 12px;
  color: ${getDefaultColor('primaryDark')};
`;
