import PropTypes from 'prop-types';
import Indicator from 'components/ui-kit/indicator';
import SwitcherLink from './switcher-link';
import * as S from './styles';

export default function NavSwitcher({ items }) {
  return (
    <S.NavSwitcher>
      <S.List>
        {items.map(({ path, label, indicator }) => (
          <SwitcherLink key={path} to={path}>
            {label}
            {indicator && (
              <Indicator
                value={indicator.value}
                color={indicator.color}
                size={indicator.size}
              />
            )}
          </SwitcherLink>
        ))}
        <S.Highlighter area-hidden="true" />
      </S.List>
    </S.NavSwitcher>
  );
}

NavSwitcher.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    path: PropTypes.string,
    label: PropTypes.string.isRequired,
    indicator: PropTypes.shape({
      color: PropTypes.oneOf(['default', 'red']),
      value: PropTypes.number.isRequired,
    }),
  })).isRequired,
};
