import { parsePhoneNumber } from 'libphonenumber-js/min';

const getPhoneNumber = (number, format = 'INTERNATIONAL') => {
  try {
    const parsed = parsePhoneNumber(number);
    return parsed.format(format);
  } catch (e) {
    return '';
  }
};

export default getPhoneNumber;
