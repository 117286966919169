import React from 'react';
import PropTypes from 'prop-types';

import Fieldbox from 'components/ui-kit/form/field-box';

import * as S from '../styles';

function MainInput({ childRef, ...restProps }) {
  const { onBlur, onFocus } = restProps;
  const handleAutoFill = (event) => {
    const { animationName } = event;
    if (animationName === S.onAutoFillStartEventName) {
      onFocus();
    } else if (animationName === S.onAutoFillEndEventName) {
      onBlur();
    }
  };

  return (
    <S.Input ref={childRef} onAnimationStart={handleAutoFill} {...restProps} />
  );
}

function Input(props) {
  return (
    <Fieldbox {...props}>
      <MainInput />
    </Fieldbox>
  );
}

MainInput.propTypes = {
  childRef: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node]),
  size: PropTypes.oneOf(['default', 'large', 'responsive']),
};

MainInput.defaultProps = {
  childRef: null,
  size: 'default',
};

export default React.memo(Input);
