const routes = {
  appointments: {
    id: 'appointments',
    path: 'appointments',
    title: 'Appointments',
    canBePreviewed: true,
    nested: {
      upcoming: {
        id: 'appointments/upcoming',
        path: 'upcoming',
        index: true,
        title: 'Upcoming',
      },
      past: {
        id: 'appointments/past',
        path: 'past',
        index: false,
        title: 'Upcoming',
      },
    },
  },
  appointment: {
    id: 'appointments/:id',
    path: 'appointments/:id',
    title: 'Appointment',
  },
  requests: {
    id: 'requests',
    path: 'requests',
    title: 'Estimate\'s requests',
  },
  request: {
    id: 'requests/:id',
    path: 'requests/:id',
    title: 'Estimate request',
  },
  invoices: {
    id: 'invoices',
    path: 'invoices',
    title: 'Invoices',
  },
  invoice: {
    id: 'invoices/:id',
    path: 'invoices/:id',
    title: 'Invoice',
  },
  servicePlans: {
    id: 'service-plans',
    path: 'service-plans',
    title: 'Service plans',
    nested: {
      active: {
        id: 'service-plans/',
        path: '',
        index: true,
        title: 'Active',
      },
      pending: {
        id: 'service-plans/pending',
        path: 'pending',
        index: false,
        title: 'Pending',
      },
    },
  },
  servicePlan: {
    id: 'service-plans/:id',
    path: 'service-plans/:id',
    title: 'Service plan',
  },
  servicePlansPayments: {
    id: 'service-plans/payments',
    path: 'service-plans/payments',
    title: 'Service plans payments',
  },
  payments: {
    id: 'payments',
    path: 'payments',
    title: 'Payments',
  },
  profile: {
    id: 'profile',
    path: 'profile',
    title: 'Profile',
  },
  company: {
    id: 'company',
    path: 'company',
    title: 'Company',
    nested: {
      details: {
        id: 'company/',
        path: '',
        index: true,
        title: 'Details',
      },
      helpCenter: {
        id: 'company/help-center',
        path: 'help-center',
        index: false,
        title: 'Help Center',
      },
    },
  },
};

export default routes;
