import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { useTransition, animated } from 'react-spring';
import { sendMagikLink } from 'api';
import Button from 'components/ui-kit/buttons/button';
import { Input } from 'components/ui-kit/form';
import { Field as FormikField, Formik, Form as FormikForm } from 'formik';
import * as yup from 'yup';
import SuccessView from './success-view';
import * as S from './styles';

const schema = yup.object().shape({
  email: yup.string()
    .email('Should be a valid email')
    .required('Required'),
});

export default function SendEmailForm({ logoUrl, portalId }) {
  const loginMutation = useMutation(({ id, email }) => {
    return sendMagikLink(id, email.toLowerCase());
  });

  const formikRef = useRef(null);

  const transition = useTransition(loginMutation.isSuccess, {
    from: { opacity: 0, scale: 0, position: 'absolute' },
    enter: { opacity: 1, scale: 1, position: 'static' },
    leave: {
      opacity: 0,
      scale: 0,
      position: 'absolute',
      width: '356.25px',
    },
    reverse: loginMutation.isSuccess,
  });

  const onSubmit = async (values) => {
    await loginMutation.mutateAsync({
      id: portalId,
      email: values.email,
    });
  };

  const hasLogo = Boolean(logoUrl);
  return (
    <S.ContentWrapper hasLogo={hasLogo}>
      {hasLogo && (
        <S.Logo src={logoUrl} alt="" />
      )}
      {transition(({
        opacity, scale, position, width,
      }, item) => (item ? (
        <animated.div
          style={{
            opacity: opacity.to({
              range: [0.0, 1.0],
              output: [0, 1],
            }),
            scale: scale.to({ range: [0.0, 1.0], output: [0, 1] }),
          }}
        >
          <SuccessView
            email={formikRef?.current?.values?.email}
          />
        </animated.div>
      ) : (
        <animated.div
          style={{
            opacity: opacity.to({
              range: [0.0, 1.0],
              output: [0, 1],
            }),
            scale: scale.to({ range: [0.0, 1.0], output: [0, 1] }),
            position,
            width,
            transformOrigin: 'center center',
          }}
        >
          <Formik
            initialValues={{ email: '' }}
            onSubmit={onSubmit}
            validateOnBlur
            validationSchema={schema}
            innerRef={formikRef}
          >
            <FormikForm>
              <S.FormWrapper>
                <S.Text>
                  Enter your email below and we&apos;ll send you
                  a link that will securely log you
                  into our client hub
                </S.Text>
                <FormikField
                  component={Input}
                  name="email"
                  label="Email"
                  isGap={false}
                />
                <Button
                  type="submit"
                  color="primary"
                  isLoading={loginMutation.isLoading}
                  stretched
                >
                  Get me a link
                </Button>
              </S.FormWrapper>
            </FormikForm>
          </Formik>
        </animated.div>
      )))}
    </S.ContentWrapper>
  );
}

SendEmailForm.propTypes = {
  logoUrl: PropTypes.string,
  portalId: PropTypes.string,
};

SendEmailForm.defaultProps = {
  logoUrl: null,
  portalId: null,
};
