import { createGlobalStyle } from 'styled-components';
import poppinsRegWoff from 'assets/fonts/poppins-regular.woff';
import poppinsRegWoff2 from 'assets/fonts/poppins-regular.woff2';
import poppinsRegTtf from 'assets/fonts/poppins-regular.ttf';
import poppinsRegSvg from 'assets/fonts/poppins-regular.svg';

import poppinsBoldWoff from 'assets/fonts/poppins-bold.woff';
import poppinsBoldWoff2 from 'assets/fonts/poppins-bold.woff2';
import poppinsBoldTtf from 'assets/fonts/poppins-bold.ttf';
import poppinsBoldSvg from 'assets/fonts/poppins-bold.svg';

import poppinsSemiBoldWoff from 'assets/fonts/poppins-semibold.woff';
import poppinsSemiBoldWoff2 from 'assets/fonts/poppins-semibold.woff2';
import poppinsSemiBoldTtf from 'assets/fonts/poppins-semibold.ttf';
import poppinsSemiBoldSvg from 'assets/fonts/poppins-semibold.svg';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Poppins';
    src: url(${poppinsRegWoff2}) format('woff2'), /* Super Modern Browsers */
      url(${poppinsRegWoff}) format('woff'), /* Pretty Modern Browsers */
      url(${poppinsRegTtf})  format('truetype'), /* Safari, Android, iOS */
      url('${poppinsRegSvg}#poppinsregular') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins-bold';
    src: url(${poppinsBoldWoff2}) format('woff2'), /* Super Modern Browsers */
      url(${poppinsBoldWoff}) format('woff'), /* Pretty Modern Browsers */
      url(${poppinsBoldTtf})  format('truetype'), /* Safari, Android, iOS */
      url('${poppinsBoldSvg}#poppinsbold') format('svg'); /* Legacy iOS */
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'Poppins-semibold';
    src: url(${poppinsSemiBoldWoff2}) format('woff2'), /* Super Modern Browsers */
      url(${poppinsSemiBoldWoff}) format('woff'), /* Pretty Modern Browsers */
      url(${poppinsSemiBoldTtf})  format('truetype'), /* Safari, Android, iOS */
      url('${poppinsSemiBoldSvg}#poppinssemibold') format('svg'); /* Legacy iOS */
    font-weight: 600;
    font-style: normal;

  }

  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }

  html, body {
    height: 100%;
  }

  body, button {
    font-family: "Poppins", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
  }
`;

export default GlobalStyles;
