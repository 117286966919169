import styled from 'styled-components';

import {
  defaultColor,
  fluidFont,
  font,
} from 'styles/theme';

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipText = styled.div`
  width: max-content;
  max-width: ${({ maw }) => maw || '300px'};
  position: absolute;
  background-color:${defaultColor('richBlack')};
  color: ${defaultColor('white')};
  padding: 8px;
  border-radius: 4px;
  top: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  transition: opacity 0.3s ease-in-out;

  font-family: ${font('regular')};
  ${fluidFont('10px', '14px')};

  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px;
    border-color: transparent transparent #000 transparent;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
