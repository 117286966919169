import styled from 'styled-components';
import { defaultColor } from 'styles/theme';

export const Content = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  color: ${defaultColor('dark')};
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  margin-bottom: 20px;
`;

export const FieldContainer = styled.div`
  flex-direction: column;
  position: relative;
`;

export const CheckboxRadioWrapper = styled.div`
  margin-bottom: 26px;
`;

export const Label = styled.div`
  color: ${defaultColor('dark')};
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 10px;
`;

export const Divider = styled.div`
  margin-bottom: 15px;
  height: 1px;
  background-color: ${defaultColor('backgroundLight')};
`;

export const FieldWrapper = styled.div`
  margin-bottom: 10px;
`;

export const FieldText = styled.div`
  color: ${defaultColor('dark')};
  font-size: 13px;
  line-height: 1.385;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  position: absolute;
`;

export const Error = styled.div`
  font-size: 12px;
  color: ${defaultColor('red')};
`;
