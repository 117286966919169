import styled from 'styled-components';
import { ReactComponent as MoneyIcon } from 'assets/icons/my-money.svg';
import { ReactComponent as InvoiceIcon } from 'assets/icons/icon-invoice.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-responsive.svg';
import {
  companyColor,
  defaultColor,
  fluidFont,
  font,
  radius,
} from 'styles/theme';

export const Box = styled.div`
  border: 1px solid ${defaultColor('background')};
  border-radius: ${radius('small')};
  padding: 20px;
  padding-right: 0px;

  display: grid;
  grid-template-columns: 8fr 3fr;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  color: ${companyColor('font')};
`;

export const DownloadInvoiceLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
`;

export const SubscriptionInvoiceIcon = styled(InvoiceIcon)`
  flex-shrink: 0;
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
    width: 1px;
    height: 85%;
    background-color: ${defaultColor('background')};
  }

`;

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;

  ${fluidFont('12px', '14px')};
`;

export const PaidIcon = styled(SuccessIcon)`
  fill: ${companyColor('font')};
  width: 24px;
  height: 24px;
`;

export const Paid = styled.div`
  display: flex;
  align-items: center;
  font-family: ${font('semibold')};
  ${fluidFont('18px', '20px')};
  gap: 4px;
`;

export const InfoLines = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const PaymentMethodIcon = styled(MoneyIcon)`
  fill: ${defaultColor('metallic')};
  height: 16px;
`;

export const PaidBy = styled.span`
  color: ${defaultColor('metallic')};
`;

export const InfoItem = styled.span`
  ${fluidFont('12px', '14px')};
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Amount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  color: ${companyColor('font')};
  ${fluidFont('18px', '22px')};
  font-family: ${font('semibold')};
  flex-grow: 2;
`;

export const Title = styled.div`
  display: flex;
  align-self: start;
  align-items: center;
  gap: 10px;
  font-family: ${font('semibold')};
  ${fluidFont('14px', '16px')};
  color: ${companyColor('font')};
  flex-grow: 2;
`;

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  flex-grow: 1;
`;

export const Status = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const NotPaidText = styled.div`
  color: ${defaultColor('metallic')};
`;
