import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import * as S from './styles';

function Radio(props) {
  const {
    field: {
      name,
      value,
    },
    field,
    form,
    disabled,
    gap,
    children,
    id,
    textValue,
    handleChange,
    showIcon,
  } = props;

  const error = form.errors[name];
  const touched = form.touched[name];

  const normalizeBoolean = (val) => {
    if (val === 'true') { return true; }
    if (val === 'false') { return false; }

    return val;
  };

  const onChange = (e) => {
    handleChange(e);
    form.setFieldValue(name, normalizeBoolean(e.target.value));
  };

  const htmlFor = id || name;
  const iconValue = (textValue || id) === String(value);

  return (
    <S.Label htmlFor={htmlFor} gap={gap} disabled={disabled}>
      <S.Input
        {...field}
        type="radio"
        id={id}
        value={textValue || id}
        checked={(textValue || id) === String(value)}
        disabled={disabled}
        onChange={onChange}
      />
      {showIcon && (<S.CheckIcon error={touched && error} value={iconValue} />)}
      {children || startCase(name)}
    </S.Label>
  );
}

Radio.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  gap: PropTypes.bool,
  id: PropTypes.string.isRequired,
  textValue: PropTypes.string,
  handleChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  showIcon: PropTypes.bool,
};

Radio.defaultProps = {
  children: '',
  disabled: false,
  gap: true,
  handleChange: () => {},
  showIcon: true,
  textValue: null,
};

export default Radio;
