import styled from 'styled-components';

export const SBox = styled.div`
  height: ${({ h }) => (h ? `${h};` : 'auto')};
  max-height: ${({ mah }) => (mah ? `${mah};` : 'none')};
  min-height: ${({ mih }) => (mih ? `${mih};` : 'none')};

  width: ${({ w }) => (w ? `${w};` : 'auto')};
  max-width: ${({ maw }) => (maw ? `${maw};` : 'none')};
  min-width: ${({ miw }) => (miw ? `${miw};` : 'auto')};

  margin-top: ${({ mt, my, m }) => {
    if (mt !== undefined) return `${mt};`;
    if (my !== undefined) return `${my};`;
    if (m !== undefined) return `${m};`;
    return '0';
  }};

  margin-right: ${({ mr, mx, m }) => {
    if (mr !== undefined) return `${mr};`;
    if (mx !== undefined) return `${mx};`;
    if (m !== undefined) return `${m};`;
    return '';
  }};

  margin-bottom: ${({ mb, my, m }) => {
    if (mb !== undefined) return `${mb};`;
    if (my !== undefined) return `${my};`;
    if (m !== undefined) return `${m};`;
    return '';
  }};

  margin-left: ${({ ml, mx, m }) => {
    if (ml !== undefined) return `${ml};`;
    if (mx !== undefined) return `${mx};`;
    if (m !== undefined) return `${m};`;
    return '';
  }};

  padding-top: ${({ pt, py, p }) => {
    if (pt !== undefined) return `${pt};`;
    if (py !== undefined) return `${py};`;
    if (p !== undefined) return `${p};`;
    return '';
  }};

  padding-right: ${({ pr, px, p }) => {
    if (pr !== undefined) return `${pr};`;
    if (px !== undefined) return `${px};`;
    if (p !== undefined) return `${p};`;
    return '';
  }};

  padding-bottom: ${({ pb, py, p }) => {
    if (pb !== undefined) return `${pb};`;
    if (py !== undefined) return `${py};`;
    if (p !== undefined) return `${p};`;
    return '';
  }};

  padding-left: ${({ pl, px, p }) => {
    if (pl !== undefined) return `${pl};`;
    if (px !== undefined) return `${px};`;
    if (p !== undefined) return `${p};`;
    return '';
  }};

  border-radius: ${({ r }) => (r ? `${r};` : '')};
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
`;
