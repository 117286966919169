import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';

import SecondaryModalContext from './secondaryModalContext';

const SecondaryModalProvider = ({ children }) => {
  const [state, setState] = useState({
    component: null,
    props: {},
    onHide: noop,
    onLoad: noop,
    isReady: true,
  });

  const showModal = useCallback((modalComponent, modalProps = {}, options = {}) => {
    setState({
      component: modalComponent,
      props: modalProps,
      onHide: options.onHide || noop,
      onReady: options.onReady || noop,
      isReady: options.isReady !== false,
    });
  }, []);

  const hideModal = useCallback(() => {
    state.onHide();
    setState({
      component: null,
      props: {},
      onHide: noop,
      onReady: noop,
      isReady: true,
    });
  }, [state]);

  const setReady = useCallback(() => {
    setState((prev) => {
      return {
        ...prev,
        isReady: true,
      };
    });
    state.onReady();
  }, [state]);

  const values = useMemo(() => ({
    component: state.component,
    props: state.props,
    showModal,
    hideModal,
    setReady,
    isReady: state.isReady,
  }), [state, hideModal, setReady, showModal]);

  return (
    <SecondaryModalContext.Provider value={values}>
      {children}
    </SecondaryModalContext.Provider>
  );
};

SecondaryModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]).isRequired,
};

export default SecondaryModalProvider;
