import styled from 'styled-components';
import {
  defaultColor,
  companyColor,
  radius,
  device,
} from 'styles/theme';
import { ReactComponent as ViewToggleIcon } from 'assets/icons/calendar-view.svg';

export const FiltersGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  align-items: center;
  margin: 2rem 0;

  @media(${device('tablet')}) {
    grid-template-columns: repeat(3, 1fr) 50px;
  }
`;

export const ViewToggler = styled.button.attrs({
  type: 'button',
  title: 'Compact mode',
})`
  display: none;
  cursor: pointer;
  border: 1px solid ${defaultColor('backgroundLight')};
  border-radius: ${radius('small')};
  background-color: transparent;
  outline: none;
  height: 50px;
  grid-column: 4 / -1;
  transition: background-color 190ms linear, border-color 190ms ease-in-out;

  &:hover {
    background-color: ${defaultColor('backgroundLight')};
  }

  &.active {
    border-color: ${companyColor('font')};
  }

  @media(${device('tablet')}) {
    display: block;
  }
`;

export const ToggleIcon = styled(ViewToggleIcon)`
  fill: ${companyColor('font')};
`;
