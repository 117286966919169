import {
  parseISO,
  endOfDay,
  add,
  startOfDay,
} from 'date-fns';
import { useQuery } from 'react-query';
import { useApp } from 'hooks';
import { getAllAppointements } from 'api';
import Accordion from 'components/ui-kit/accordion';
import { getNextDayTitle } from 'constants/appointments';
import { getFakeAppointments } from 'api/fakedata';
import ItemTitle from './item-title';
import ListOfDay from './list-of-day';
import NextAppointmentsLoader from './loader';
import * as S from './styles';

export default function NextAppointments() {
  const { portal, appointment, isPreviewMode } = useApp();
  const { data, isLoading } = useQuery(
    [
      'nextAppointments',
      portal?.id,
      {
        from: startOfDay(new Date()).toISOString(),
        to: add(endOfDay(new Date()), { days: 6 }).toISOString(),
        type: null,
      },
    ],
    getAllAppointements,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!portal?.id && !isPreviewMode,
    },
  );

  const source = isPreviewMode ? getFakeAppointments() : data?.data;

  const days = source?.reduce((acc, curr) => {
    const date = startOfDay(parseISO(curr.start)).toISOString();
    acc[date] = acc[date] || [];
    acc[date].push(curr);

    return acc;
  }, {});

  const list = days && Object.entries(days).map(([key, value]) => ({
    date: key,
    appointments: value,
  }));

  if (list?.length === 0) {
    return (
      <>
        <S.ListTitle>Next Appointments</S.ListTitle>
        <S.Placeholder>No appointments scheduled for the week</S.Placeholder>
      </>
    );
  }

  return (
    <>
      <S.ListTitle>Next Appointments</S.ListTitle>
      {isLoading ? <NextAppointmentsLoader /> : (
        <S.List>
          {list?.map(({ date, appointments }, index) => (
            <S.ListItem key={date}>
              <Accordion
                toggleContent={(
                  <ItemTitle
                    title={getNextDayTitle(date)}
                    date={parseISO(date)}
                    count={appointments.length}
                  />
                )}
                isOpen={index === 0}
              >
                <ListOfDay
                  appointments={appointments}
                  isCompact={appointment.isCompact}
                />
              </Accordion>
            </S.ListItem>
          ))}
        </S.List>
      )}
    </>
  );
}
