import ContentLoader from 'react-content-loader';

function SingleLoader() {
  return (
    <ContentLoader
      speed={2}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      height="102px"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="102px" />
    </ContentLoader>
  );
}

export default function InvoicesLoader() {
  return (
    <div style={{ display: 'flex', gap: '1rem', flexDirection: 'column' }}>
      <SingleLoader />
      <SingleLoader />
    </div>
  );
}
