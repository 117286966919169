import styled, { keyframes } from 'styled-components';
import { getDefaultColor } from 'styles/theme';

const showContent = keyframes`
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
`;

export const Tabs = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: ${({ noGap }) => (noGap ? '0' : '24px')};
  background-color: ${getDefaultColor('light')};
  border-radius: 24px;
  height: 46px;
  padding: 0px 4px;
  gap: 4px;
`;

export const Body = styled.div`
  animation: ${showContent} 0.17s ease-out;
  width:100%;
`;
