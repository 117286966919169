import styled from 'styled-components';
import {
  companyColor,
  spacing,
  device,
  sidebarSize,
} from 'styles/theme';

export const Box = styled.div.attrs({
  role: 'sidebar',
})`
  display: ${({ isShow }) => (isShow ? 'grid' : 'none')};
  width: ${sidebarSize};
  grid-template-rows: max-content 1fr 1fr;
  background-color: ${companyColor('background')};
  position: absolute;
  top: 0;
  padding: ${spacing('layoutBox')};
  gap: 40px;
  grid-area: sidebar;
  z-index: 2;

  @media only screen and (${device('mobile')}) and (orientation: landscape) {
    bottom: unset;
  }

  @media only screen
    and (min-width: 414px)
    and (max-height: 736px)
    and (orientation: portrait) {
    bottom: unset;
  }

  @media only screen
    and (min-width: 414px)
    and (min-height: 737px)
    and (orientation: portrait) {
    bottom: 0;
  }

  @media only screen and (device-max-height: 653px) and (orientation: portrait) {
    bottom: unset;
  }

  @media only screen and (${device('laptop')}) {
    display: grid;
    position: fixed;
    bottom: 0;
  }

`;
