import styled from 'styled-components';
import {
  companyColor,
  spacing,
  fluidFont,
  font,
} from 'styles/theme';

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  margin-bottom: ${spacing('medium')};
`;

export const Title = styled.span`
  font-family: ${font('semibold')};
  ${fluidFont('24px', '27px')};
  color: ${companyColor('font')};
`;

export const Children = styled.div`
  display: flex;
  gap: ${spacing('default')};
`;

export const ActionItems = styled.div`
  justify-self: end;
  display: flex;
  gap: ${spacing('default')};
`;
