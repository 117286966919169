import PropTypes from 'prop-types';
import { useMutation } from 'react-query';
import { Navigate } from 'react-router-dom';
import { useApp } from 'hooks';
import { logout } from 'api';
import { ReactComponent as LogOut } from 'assets/icons/log-out.svg';
import * as S from './styles';

export default function LogoutButton({ isMobile }) {
  const { portal, isPreviewMode } = useApp();
  const mutation = useMutation((portalId) => logout(portalId));

  if (mutation.isSuccess) {
    return (
      <Navigate
        to="login"
        state={{
          portalId: portal.id,
          logoUrl: portal.logoUrl,
        }}
      />
    );
  }

  return (
    <S.LogoutBtn
      isMobile={isMobile}
      onClick={isPreviewMode ? () => {} : () => mutation.mutate(portal.id)}
    >
      <LogOut />
      {isMobile && <S.Text>Logout</S.Text>}
    </S.LogoutBtn>
  );
}

LogoutButton.propTypes = {
  isMobile: PropTypes.bool,
};

LogoutButton.defaultProps = {
  isMobile: false,
};
