import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  defaultColor,
  companyColor,
  fluidFont,
  font,
  radius,
  spacing,
} from 'styles/theme';

const ICON_BTN_SIZE = '21%';

export const Wrapper = styled.div`
  display: flex;
  flex: 0 1 auto;
  width: 100%;
  height: 50px;
`;

export const Btn = css`
  border: 1px solid ${defaultColor('backgroundLight')};
  outline: none;
  cursor: pointer;
  background: none;
  color: ${companyColor('font')};
  font-family: ${font('semibold')};
  font-weight: 500;
  ${fluidFont('10px', '13px')};
  transition: background-color 190ms linear;

  &:hover {
    background-color: ${companyColor('main', 0.15)};
  }
`;

export const ProfileBtn = styled(Link)`
  ${Btn};
  border-top-left-radius: ${radius('small')};
  border-bottom-left-radius: ${radius('small')};
  border-right: none;
  display: flex;
  align-items: center;
  padding: 0 ${spacing('medium')};
  gap: 12px;
  flex-basis: calc(100% - ${ICON_BTN_SIZE});
  text-decoration: none;
`;

export const Image = styled.img`
  max-width: 100%;
  border-radius: ${radius('full')};
  object-fit: cover;
  object-position: top;
`;

export const LogoutBtn = styled.button.attrs({
  type: 'button',
})`
  ${Btn};
  border-top-right-radius: ${radius('small')};
  border-bottom-right-radius: ${radius('small')};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: ${ICON_BTN_SIZE};

  & svg {
    stroke: ${companyColor('font')};
  }
`;
