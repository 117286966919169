import { NavLink as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

import {
  companyColor,
  defaultColor,
  spacing,
  radius,
  fluidFont,
  font,
  device,
} from 'styles/theme';

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: ${spacing('medium')};
  position: relative;
`;

export const Icon = styled.span`
  width: 24px;
  display: flex;
  align-items: center;
  transform: translateY(-1px);
  position: relative;
  z-index: 1;

  svg {
    width: 100%;
    fill: ${companyColor('font')};
    transition: fill 345ms linear;
  }
`;

export const Highlighter = styled.li`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 48px;
  border-radius: ${radius('small')};
  background-color: ${companyColor('main')};
  transition: transform 345ms cubic-bezier(.42,0,.47,1.22);

  @media only screen and (${device('laptop')}) {
    display: ${({ isHidden }) => (isHidden ? 'none' : 'block')};
  }
`;

export const NavItem = styled.li`
  --step-size: calc(48px + 20px);
  width: 100%;
  height: 48px;
  display: flex;
  pointer-event: none;
  &.active:nth-child(${({ index }) => index + 1}) ~ ${Highlighter} {
    display: block;
    transform: translateY(${({ index }) => `${68 * index}px`});
  }

  @media(${device('laptop')}) {
    display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
    &.active:nth-child(${({ index }) => index + 1}) ~ ${Highlighter} {
      display: ${({ isMobile }) => (isMobile ? 'none' : 'block')};
    }
  }
`;

export const NavLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  gap: ${spacing('medium')};
  width: 100%;
  padding: 0 ${spacing('medium')};
  color: ${companyColor('font')};
  border-radius: ${radius('small')};
  text-decoration: none;
  transition: background-color 190ms linear;
  will-change: background-color;
  position: relative;
  transition: color 345ms linear;

  &:hover:not(.active) {
    background-color: ${companyColor('main', 0.15)};
  }
  &:active {
    color: ${companyColor('font')};
  }

  &.active {
    color: ${defaultColor('white')};

    ${Icon} {
      svg {
        fill: ${defaultColor('white')};
      }
    }
  }
`;

export const Text = styled.span`
  display: flex;
  align-items: center;
  font-family: ${font('semibold')};
  ${fluidFont('11px', '14px')};
  line-height: 20px;
  position: relative;
  z-index: 1;
`;

export const IndicatorWrapper = styled.div`
  z-index: 2;
`;
