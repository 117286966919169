import React, { useMemo, memo } from 'react';
import PropTypes from 'prop-types';

import Money from 'components/ui-kit/money';

import { getItemAmount } from 'helpers/calculations.helper';

const LineItemAmount = ({ lineItem }) => {
  const isOptional = !!lineItem?.optionalState;
  const isAccepted = lineItem?.optionalState?.isAccepted;

  const amount = useMemo(() => {
    if (isOptional && !isAccepted) return 0;

    return getItemAmount(lineItem);
  }, [isOptional, isAccepted, lineItem]);

  return <Money amount={amount} />;
};

LineItemAmount.propTypes = {
  lineItem: PropTypes.shape({
    optionalState: PropTypes.shape({
      isAccepted: PropTypes.bool,
    }),
    unitPrice: PropTypes.number,
    quantity: PropTypes.number,
  }).isRequired,
};

export default memo(LineItemAmount);
