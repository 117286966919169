import styled from 'styled-components';
import {
  font,
  defaultColor as color,
} from 'styles/theme';

export const Label = styled.label`
  display: flex;
  align-items: center;
  font: ${font('regular.langind')};
  color: ${color('dark')};
  position: relative;
  cursor: pointer;
  margin-bottom: ${({ gap }) => (gap || 0)}px;
  &[disabled] {
    color: ${color('grey')};
  }
`;

export const CheckIcon = styled.span`
    color: ${({ error }) => (error ? color('red') : color('dark'))};
    display: block;
    border: 1px solid ${color('dark')};
    width: 18px;
    min-width: 18px;
    height: 18px;
    border-radius: 25px;
    margin-right: 12px;
    position: relative;
    box-sizing: border-box;

    &:after {
        content: '';
        display: block;
        width: 10px;
        height: 10px;
        position: absolute;
        background: ${color('dark')};
        border-radius: 25px;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%) scale(${({ value }) => (value ? '1' : '0')});
        transition: transform .19s ease-in;
        box-sizing: border-box;
    }

    ${Label}[disabled] & {
        border-color: ${color('grey')};
    }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;
