import Appointments from 'pages/appointments';
import AppointmentDetails from 'pages/appointment-details';
import Invoices from 'pages/invoices';
import InvoiceDetails from 'pages/invoice-details';
import Payments from 'pages/payments';
import ServicePlans from 'pages/service-plans';
import ServicePlanDetails from 'pages/service-plan-details';
import UpcomingList from 'components/appointments/upcoming-list';
import EstimatesRequests from 'pages/estimates-requests';
import PastList from 'components/appointments/past-list';
import ServicePlansPayments from 'pages/service-plans-payments';
import Profile from 'pages/profile';
import Company from 'pages/company';
import HelpCenter from 'components/help-center';
import ServicePlansListing from 'components/service-plan-subscriptions/service-plan-subscriptions-listing';
import CompanyDetails from 'components/company-details';
import RequestItemDetails from 'components/request-item-details';

const pages = {
  appointments: {
    element: <Appointments />,
    nested: {
      upcoming: {
        element: <UpcomingList />,
      },
      past: {
        element: <PastList />,
      },
    },
  },
  appointment: {
    element: <AppointmentDetails />,
  },
  requests: {
    element: <EstimatesRequests />,
  },
  request: {
    element: <RequestItemDetails />,
  },
  invoices: {
    element: <Invoices />,
  },
  invoice: {
    element: <InvoiceDetails />,
  },
  servicePlans: {
    element: <ServicePlans />,
    nested: {
      active: {
        element: <ServicePlansListing show="active" />,
      },
      pending: {
        element: <ServicePlansListing show="pending" />,
      },
    },
  },
  servicePlan: {
    element: <ServicePlanDetails />,
  },
  servicePlansPayments: {
    element: <ServicePlansPayments />,
  },
  payments: {
    element: <Payments />,
  },
  profile: {
    element: <Profile />,
  },
  company: {
    element: <Company />,
    nested: {
      details: {
        element: <CompanyDetails />,
      },
      helpCenter: {
        element: <HelpCenter />,
      },
    },
  },
};

export default pages;
