import { useEffect, useState } from 'react';
import { useOutsideClick } from 'hooks';
import PropTypes from 'prop-types';
import SelectMenu from './menu';
import MultiItem from './item';
import * as S from './styles';

function Select({
  onChange,
  onRemove,
  value,
  name,
  options,
  label,
  fullWidth,
  isMulti,
  disabled,
  placeholder,
  isError,
  errorMessage,
  closeMenuOnSelect,
}) {
  const [isBlur, setBlur] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const setFocused = (v) => {
    setIsFocused(v);
    if (!v) {
      setBlur(true);
    }
  };
  const selectRef = useOutsideClick(() => {
    if (isFocused) { setFocused(false); }
  });

  useEffect(() => {
    const remainingValues = options.filter((option) => !value.includes(option.value));
    if (!remainingValues.length || closeMenuOnSelect) {
      setFocused(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const toggleValue = isMulti
    ? options.filter((option) => (value.includes(option.value)))
    : options.find((option) => (option.value === value));

  const getDisplayValue = () => {
    if (value.length > 0) {
      return isMulti
        ? (
          <S.MultiItemsContainer>
            {toggleValue
              .map((val, index) => (
                <MultiItem
                  key={`${val.id}${String(index)}`}
                  name={name}
                  onRemove={(removeValue, removeName) => {
                    onRemove(removeValue, removeName);
                    setFocused(false);
                  }}
                  value={val.label}
                />
              ))}
          </S.MultiItemsContainer>
        )
        : toggleValue?.label || <S.PlaceHolder>{placeholder}</S.PlaceHolder>;
    }
    return <S.PlaceHolder>{placeholder}</S.PlaceHolder>;
  };
  return (
    <S.Select ref={selectRef}>
      <S.Toggle
        type="button"
        name={name}
        onClick={() => setFocused(!isFocused)}
        className={isFocused ? 'focused' : null}
        label={label}
        disabled={disabled}
        isError={isError && isBlur}
      >
        {getDisplayValue()}
        <S.Icon disabled={disabled} className={isFocused ? 'opened' : null} />
      </S.Toggle>
      {isError && isBlur && <S.ErrorMessage>{errorMessage}</S.ErrorMessage>}
      <SelectMenu
        onChange={onChange}
        value={value}
        name={name}
        isFocused={isFocused}
        options={options}
        fullWidth={fullWidth}
        isMulti={isMulti}
      />
    </S.Select>
  );
}

Select.propTypes = {
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })).isRequired,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  isError: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  errorMessage: PropTypes.string,
};

Select.defaultProps = {
  onChange: () => {},
  onRemove: () => {},
  fullWidth: false,
  label: null,
  isMulti: false,
  disabled: false,
  placeholder: '',
  isError: false,
  closeMenuOnSelect: true,
  errorMessage: '',
};

export default Select;
