import Button from 'components/ui-kit/buttons/button';
import { sendHelpRequest } from 'api';
import { useApp, useToast } from 'hooks';
import { useState } from 'react';
import { Textarea } from 'components/ui-kit/form';
import { InfoBox } from 'components/ui-kit/info-box';
import SentRequestConfirmationBlock from 'components/sent-request-confirmation-block';
import { Form as FormikForm, Field as FormikField, Formik } from 'formik';
import * as yup from 'yup';
import * as S from './styles';
import HelpCenterLoader from './loader';

const HELP_REQUEST_MAX_LENGHT = 2000;

const schema = yup.object().shape({
  message: yup.string().max(HELP_REQUEST_MAX_LENGHT, 'Message must be no more than 2000 characters')
    .required('Message is a required field'),
});

export default function HelpCenter() {
  const { portal, user } = useApp();
  const { addErrorToast } = useToast();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    const result = await sendHelpRequest(portal.id, user.id, values.message);
    if (result.status === 200) {
      setShowConfirmation(true);
    } else {
      addErrorToast(
        'Something went wrong during sending the help request. You can try again later.',
      );
    }
    setIsSubmitting(false);
  };

  if (!portal || !user) {
    return (
      <S.Content>
        <HelpCenterLoader />
      </S.Content>
    );
  }

  return (
    portal
      ? (
        <S.Content>
          <InfoBox title="Contact support">
            {!showConfirmation && (
            <>
              <S.SubTitle>
                How can we help you?
              </S.SubTitle>
              <S.Text>
                Send us a help request below
                {portal.company.businessPhoneNumber && portal.company.businessPhoneNumber != null
                  ? (
                    <>
                      {' '}
                      or call our customer support at&nbsp;
                      <S.PhoneLink href={`tel:${portal.company.businessPhoneNumber}`}>
                        {portal.company.businessPhoneNumber}
                      </S.PhoneLink>
                    </>
                  )
                  : ''}
                .
              </S.Text>
              <S.TextSilver>
                Please describe your issue. Online form max characters =&nbsp;
                {HELP_REQUEST_MAX_LENGHT}
                .
              </S.TextSilver>
              <Formik
                initialValues={{
                  message: '',
                }}
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                <FormikForm>
                  <S.AreaBlock>
                    <FormikField
                      name="message"
                      label="Message"
                      component={Textarea}
                    />
                  </S.AreaBlock>
                  <S.SubmitButton>
                    <Button stretched type="submit" color="primary" isLoading={isSubmitting}>Send</Button>
                  </S.SubmitButton>
                </FormikForm>
              </Formik>
            </>
            )}
            {showConfirmation
                        && <SentRequestConfirmationBlock onSubmit={() => { setShowConfirmation(false); }} />}
          </InfoBox>
        </S.Content>
      )
      : <HelpCenterLoader />);
}
