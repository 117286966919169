import styled from 'styled-components';

import {
  defaultColor,
  companyColor,
  font,
  fluidFont,
} from 'styles/theme';

export const Indicator = styled.div`
  --size: ${({ size }) => (size === 'normal' ? '24px' : '20px')};
  width: var(--size);
  height: var(--size);
  color: ${defaultColor('white')};
  background-color: ${({ color }) => (
    color === 'red' ? defaultColor('red') : companyColor('font')
  )};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${font('semibold')};
  ${fluidFont('12px', '14px')};
  padding-top: 2px;
  font-weight: 600;
  border-radius: 100%;
  line-height: normal;
  margin-left: ${({ ml }) => (ml || 'calc((20px - 0.5rem) + 0.5rem)')};
`;
