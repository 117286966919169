import { useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/ui-kit/modal';
import SecondaryModal from 'components/secondary-modal';
import { useModal as useSecondaryModal } from 'components/secondary-modal/context/secondaryModalContext';
import Group from 'components/ui-kit/group';

import { useModal as usePrimaryModal } from 'hooks';

import Button from '../button';

import * as S from './styles';

export default function ConfirmationModal({
  onConfirm,
  afterConfirm,
  onCancel,
  modalContent,
  buttonTitle,
  modalTitle,
  buttonColor,
  isSecondary,
}) {
  const secondaryModal = useSecondaryModal();
  const primaryModal = usePrimaryModal();
  const { hideModal } = isSecondary ? secondaryModal : primaryModal;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const ModalContainer = isSecondary ? SecondaryModal : Modal;

  const handleConfirm = async () => {
    try {
      setIsSubmitting(true);
      await onConfirm();
      setIsSubmitting(false);
      hideModal();
      await afterConfirm();
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancel = async () => {
    try {
      setIsSubmitting(true);
      await onCancel();
      setIsSubmitting(false);
      hideModal();
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalContainer title={modalTitle}>
      <Group>
        {modalContent}
      </Group>

      <S.ButtonGroup>
        <Button
          onClick={handleCancel}
          stretched
          isLoading={isSubmitting}
        >
          Cancel
        </Button>

        <Button
          onClick={handleConfirm}
          stretched
          color={buttonColor}
          isLoading={isSubmitting}
        >
          {buttonTitle}
        </Button>
      </S.ButtonGroup>
    </ModalContainer>
  );
}

ConfirmationModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  afterConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  modalContent: PropTypes.node.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  buttonColor: PropTypes.string,
  isSecondary: PropTypes.bool,
};

ConfirmationModal.defaultProps = {
  isSecondary: false,
  buttonColor: 'primary',
  afterConfirm: () => {},
  onCancel: () => {},
};
