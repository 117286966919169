import React from 'react';
import PropTypes from 'prop-types';

import theme, { toPixels } from 'styles/theme';

import * as S from './styles';

const companyColorNames = Object.keys(theme.companyColors);
const regularColorNames = Object.keys(theme.defaultColors);
const colorNames = new Set([...companyColorNames, ...regularColorNames]);

export default function Typography({
  children,
  as,
  w,
  h,
  maw,
  mah,
  miw,
  mih,
  color,
  fontSize,
  fluidFont,
  font,
  fontFamily,
  fontWeight,
  withNewlines,
  bold,
  capitalize,
  truncate,
  lh,
  ...rest
}) {
  return (
    <S.TextComponent
      {...rest}
      as={as}
      w={toPixels(w)}
      h={toPixels(h)}
      maw={toPixels(maw)}
      mah={toPixels(mah)}
      miw={toPixels(miw)}
      mih={toPixels(mih)}
      color={color}
      fontSize={fontSize}
      fluidFont={fluidFont}
      font={font}
      fontFamily={fontFamily}
      fontWeight={fontWeight}
      capitalize={capitalize}
      bold={bold}
      truncate={truncate}
      lh={lh}
    >
      {children}
    </S.TextComponent>
  );
}

Typography.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node,
  ]).isRequired,
  as: PropTypes.oneOf(['div', 'span', 'p']),
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mah: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  miw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mih: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf([...colorNames, null]),
  fontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fluidFont: PropTypes.shape({
    min: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
    max: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
  }),
  fontFamily: PropTypes.string,
  withNewlines: PropTypes.bool,
  bold: PropTypes.bool,
  capitalize: PropTypes.bool,
  font: PropTypes.string,
  fontWeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  truncate: PropTypes.bool,
  lh: PropTypes.string,
};

Typography.defaultProps = {
  as: 'span',
  w: 'auto',
  h: 'auto',
  maw: 'none',
  mah: 'none',
  miw: 'auto',
  mih: 'auto',
  color: 'font',
  fontSize: 16,
  fluidFont: null,
  fontFamily: null,
  font: null,
  fontWeight: 'normal',
  withNewlines: false,
  bold: false,
  capitalize: false,
  truncate: false,
  lh: 'normal',
};
