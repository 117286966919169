import PropTypes from 'prop-types';
import { useApp } from 'hooks';
import * as S from './styles';

export default function Content404({
  isUnlogged,
  title,
  message,
}) {
  const { portal } = useApp();

  return (
    <S.Content>
      {isUnlogged ? <S.ImageSmall /> : <S.ImageLarge />}
      {!isUnlogged && <S.ErrorLabel>Error</S.ErrorLabel>}
      <S.Title>
        {title}
      </S.Title>
      <S.Message>
        {message}
        <br />
        {(!isUnlogged && portal?.id) && (
          <S.Link to={portal?.id}>home page</S.Link>
        )}
      </S.Message>
    </S.Content>
  );
}

Content404.propTypes = {
  isUnlogged: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};

Content404.defaultProps = {
  isUnlogged: false,
  title: 'Looks like the page is lost',
  message: `This page does not exist,
            but you can return to `,
};
