import { useQuery } from 'react-query';
import {
  add,
  parseISO,
  startOfDay,
} from 'date-fns';
import { useApp, useFields } from 'hooks';
import { getAllAppointements as getAll } from 'api';
import { getAllAppointements as getAllFake } from 'api/fake';
import Filters from 'components/filters';
import { appointmentBusinessTypes } from 'constants/appointments';
import AppointmentItem from 'components/appointments/appointment-item';
import AppointmentLoader from 'components/appointments/appointment-loader';
import ListPlaceholder from 'components/ui-kit/list-placeholder';
import * as S from './styles';

const startOfToday = startOfDay(new Date());
const daterange = {
  from: startOfToday,
  to: add(startOfToday, { days: 30 }),
};

export default function UpcomingList() {
  const { portal, appointment, isPreviewMode } = useApp();
  const { fields, handleClickChange, handleChange } = useFields({
    type: 'all',
    daterange: [daterange.from, daterange.to],
  });

  const getAllAppointements = isPreviewMode ? getAllFake : getAll;

  const { data, isLoading, isFetching } = useQuery(
    [
      'appointments',
      portal?.id,
      {
        from: fields.daterange[0].toISOString(),
        to: fields.daterange[1].toISOString(),
        type: appointmentBusinessTypes[fields.type],
      },
    ],
    getAllAppointements,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!portal?.id,
    },
  );

  const renderContent = () => {
    if (isLoading || isFetching) {
      return <AppointmentLoader />;
    }

    if (!data?.data || !data?.data.length) {
      return <ListPlaceholder message="No upcoming appointments. Request a service" />;
    }

    return (
      <S.List>
        {data?.data && data?.data.map((item) => (
          <S.ListItem key={item.id}>
            <AppointmentItem
              id={item.id}
              title={item.title}
              type={item.businessEntityType}
              address={item.serviceAddress}
              unitNumber={item.unitNumber}
              totalAmount={item.totalAmount}
              date={parseISO(item.start)}
              isCompact={appointment.isCompact}
              employees={item.employees}
              invoice={item.invoiceSent}
              invoiceId={item.invoiceId}
              status={item.status}
              variant="row"
            />
          </S.ListItem>
        ))}
      </S.List>
    );
  };

  return (
    <>
      <Filters
        fields={fields}
        handleClickChange={handleClickChange}
        handleChange={handleChange}
        daterange={{
          minDate: new Date(),
        }}
        select={{
          options: [
            { label: 'All Types', value: 'all' },
            { label: 'Job', value: 'job' },
            { label: 'Estimate', value: 'estimate' },
            { label: 'Visit', value: 'visit' },
          ],
          name: 'type',
          label: 'Type',
        }}
        showViewToggle
      />
      {renderContent()}
    </>
  );
}
