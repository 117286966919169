import Button from 'components/ui-kit/buttons/button';
import { useApp, useOutsideClick } from 'hooks';
import * as S from './styles';

export default function MobileHeader() {
  const { sidebar } = useApp();
  const btnRef = useOutsideClick(() => sidebar.hide());

  return (
    <S.Header>
      <Button variant="text" onClick={sidebar.toggle} forwardRef={btnRef}>
        <S.Icon />
      </Button>
    </S.Header>
  );
}
