import styled from 'styled-components';
import {
  companyColor,
  font,
  defaultColor,
  fluidFont,
} from 'styles/theme';

export const Table = styled.table`
  border: 1px solid ${defaultColor('background')};
  border-radius: 12px;
  width: 100%;
  border-spacing: 0px;
  overflow: hidden;
`;

export const TableHeader = styled.th`
  text-align: right;
  &:first-child {
    text-align: left;
  }
  background-color: ${defaultColor('background')};
  font-family: ${font('semibold')};
  ${fluidFont('12px', '14px')}
  color: ${companyColor('font')};
  padding: 20px 30px;
`;

export const Cell = styled.td`
  text-align: right;
  &:first-child {
    text-align: left;
  }
  padding: 15px 20px;
  border-bottom: 1px solid ${defaultColor('background')};
  color: ${({ isBold }) => (isBold ? companyColor('font') : defaultColor('font'))};
  font-family: ${({ isBold }) => (
    isBold ? font('semibold') : font('regular')
  )};
  ${fluidFont('11px', '13px')}
`;
