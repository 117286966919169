import styled, { css } from 'styled-components';
import {
  font,
  companyColor,
  defaultColor,
  fluidFont,
} from 'styles/theme';

export const Logo = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

export const LogoPlaceholder = styled.div`
  font-family: ${font('primary')};
  color: ${companyColor('font')};
  ${fluidFont('12px', '18px')};
`;

export const CompanyName = styled.h3`
  font-family: ${font('semibold')};
  color: ${companyColor('font', 0.5)};
  ${fluidFont('12px', '14px')};
`;

export const InfoItem = css`
  font-family: ${font('regular')};
  color: ${companyColor('font', 0.5)};
  ${fluidFont('10px', '12px')};
`;

export const PhoneNumber = styled.a`
  ${InfoItem};
  &:hover {
    text-decoration: none;
  }
`;

export const Email = styled.a`
  ${InfoItem};
  text-decoration: none;
  word-break: break-word;
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 0.5rem;
`;

export const WebsiteLink = styled.a`
  font-family: ${font('regular')};
  ${fluidFont('10px', '12px')};
  text-decoration: none;
  color: ${defaultColor('blue')};
  &:hover {
    text-decoration: underline;
  }
`;
