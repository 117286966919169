export const COMMON_COLUMNS = [
  { name: 'name', label: 'Name', size: { width: '60%', minWidth: '120px' } },
  { name: 'quantity', label: 'Qty', size: { width: '10%', minWidth: '50px' } },
  {
    name: 'unitPrice', label: 'Price per', size: { width: '15%', minWidth: '60px' }, isMoney: true,
  },
  {
    name: 'amount', label: 'Amount', size: { width: '15%', minWidth: '60px' }, isMoney: true,
  },
];
