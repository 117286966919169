import styled from 'styled-components';

import { defaultColor } from 'styles/theme';

const getCheckboxColor = (checked, disabled) => {
  if (checked && disabled) return defaultColor('metallic');

  if (checked) return defaultColor('dark');

  return defaultColor('white');
};

export const OriginalCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
`;

export const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: ${({ checked, disabled }) => getCheckboxColor(checked, disabled)};
  border-radius: 4px;
  border: 1px solid ${({ disabled }) => (disabled ? defaultColor('metallic') : defaultColor('dark'))};
  transition: background-color 0.3s ease;
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
  
  svg {
    display: block;
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    stroke: ${defaultColor('white')};
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 22;
    stroke-dashoffset: ${({ checked }) => (checked ? 0 : -22)};
    transition: stroke-dashoffset 0.3s ease;
  }
`;
