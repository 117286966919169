import React, { useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';

import SecondaryModalContext from './context/secondaryModalContext';

import * as S from './styles';

const SecondaryModal = ({
  children,
  title,
  isClosable,
  showCloseButton,
  size,
  marginBottom,
  toolbar,
  headerGridSettings,
}) => {
  const { hideModal, isReady } = useContext(SecondaryModalContext);
  const backdropRef = useRef();

  function outsideClick(e) {
    if (e.target === backdropRef.current && isClosable) {
      hideModal();
    }
  }

  function keyboardCloseListener(e) {
    if (e.key === 'Escape') {
      e.stopPropagation();
      hideModal();
    }
  }

  useEffect(() => {
    document.addEventListener('click', outsideClick);
    document.addEventListener('keydown', keyboardCloseListener);

    return () => {
      document.removeEventListener('click', outsideClick);
      document.removeEventListener('keydown', keyboardCloseListener);
    };
  });

  return (
    <S.Backdrop isVisible={isReady} ref={backdropRef}>
      <S.Modal size={size}>
        <S.Header marginBottom={marginBottom} gridSettings={headerGridSettings}>
          <S.Title>{title}</S.Title>

          {toolbar}

          {showCloseButton && (
          <S.CloseButton onClick={hideModal}>
            <S.Close />
          </S.CloseButton>
          )}
        </S.Header>

        <S.Body>
          {children}
        </S.Body>
      </S.Modal>
    </S.Backdrop>
  );
};

SecondaryModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  title: PropTypes.string,
  isClosable: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  size: PropTypes.string,
  marginBottom: PropTypes.oneOf([
    'none',
    'base',
    'medium',
    'large',
    'extraLarge',
    'small',
    'extraSmall',
  ]),
  toolbar: PropTypes.node,
  headerGridSettings: PropTypes.shape({
    templateColumns: PropTypes.string,
    columnGap: PropTypes.string,
  }),
};

SecondaryModal.defaultProps = {
  title: '',
  size: '4/10',
  isClosable: true,
  showCloseButton: true,
  marginBottom: 'extraSmall',
  toolbar: null,
  headerGridSettings: null,
};

export default SecondaryModal;
