import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

function Loader({ color, size, borderWidth }) {
  return <S.Spinner color={color} size={size} borderWidth={borderWidth} />;
}

Loader.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  borderWidth: PropTypes.number,
};

Loader.defaultProps = {
  color: 'white',
  size: 20,
  borderWidth: 2,
};

export default Loader;
