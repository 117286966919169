import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  paymentMethodTypes,
  paymentStatuses,
} from 'constants/service-plans';
import { getDate } from 'helpers/datetime.helper';
import GenericTable from 'components/ui-kit/generic-table';
import { getServicePlanReceiptPdfAsync } from 'api';
import format from 'date-fns/format';
import Money from 'components/ui-kit/money';
import * as S from '../../styles';

export default function PaymentsBlock({ payments }) {
  const { portalId } = useParams();

  const amountCell = (value, index) => (
    <S.Cell key={index}>
      <Money amount={value} />
    </S.Cell>
  );

  const dateCell = (value, index) => (
    <S.Cell key={index}>
      {format(new Date(value), 'MMM dd, yyyy')}
    </S.Cell>
  );

  const methodCell = (value, index) => (
    <S.Cell key={index}>
      {paymentMethodTypes[value]}
    </S.Cell>
  );

  const statusCell = (value, index) => (
    <S.Cell key={index}>
      {paymentStatuses[value]}
    </S.Cell>
  );

  const receiptCell = (value, index, item) => (
    <S.CellWide key={index}>
      {item.paymentStatus === 1
        ? (
          <S.DownloadLink href={getServicePlanReceiptPdfAsync(portalId, value)}>
            <S.DownloadIcon />
            {' '}
            Download
          </S.DownloadLink>
        )
        : <span />}
    </S.CellWide>
  );

  const paymentColumns = [
    { name: 'paymentDate', label: 'Billing Date', component: dateCell },
    { name: 'amount', label: 'Amount', component: amountCell },
    { name: 'paymentMethodType', label: 'Method', component: methodCell },
    { name: 'paymentStatus', label: 'Status', component: statusCell },
  ];

  const receiptColumn = { name: 'id', label: 'Receipt', component: receiptCell };

  const currentDate = getDate();
  const upcomingPayments = payments.filter((item) => getDate(item.paymentDate) >= currentDate);
  const pastPayments = payments.filter((item) => getDate(item.paymentDate) < currentDate);

  return (
    <>
      {upcomingPayments !== null && upcomingPayments.length > 0
        && (
        <>
          <S.Separator />
          <S.SubTitleSmall>Upcoming payments:</S.SubTitleSmall>
          <S.Table>
            <GenericTable items={upcomingPayments} columns={paymentColumns} />
          </S.Table>
        </>
        )}

      {pastPayments !== null && pastPayments.length > 0
        && (
        <>
          <S.Separator />
          <S.SubTitleSmall>Payment history:</S.SubTitleSmall>
          <S.Table>
            <GenericTable items={pastPayments} columns={[...paymentColumns, receiptColumn]} />
          </S.Table>
        </>
        )}
    </>
  );
}

PaymentsBlock.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  payments: PropTypes.arrayOf(PropTypes.object).isRequired,
};
