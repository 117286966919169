import {
  useState,
  memo,
  cloneElement,
} from 'react';
import PropTypes from 'prop-types';
import {
  useSpring,
  useChain,
  useSpringRef,
  config,
} from 'react-spring';
import { useMeasure, usePrevious } from 'hooks';
import * as S from './styles';

const Accordion = memo(({
  children,
  isOpen,
  toggleContent,
}) => {
  const [isOpened, setIsOpened] = useState(isOpen);
  const prevState = usePrevious(isOpened);
  const [bind, { height: viewHeight }] = useMeasure();

  const wrapperRef = useSpringRef();
  const { height } = useSpring({
    ref: wrapperRef,
    from: {
      height: 0,
    },
    to: {
      height: isOpened ? viewHeight : 0,
    },
  });

  const contentRef = useSpringRef();
  const { opacity, transform } = useSpring({
    ref: contentRef,
    config: config.gentle,
    from: {
      opacity: 0,
      transform: 'translate3d(0, -20%, 0)',
    },
    to: {
      opacity: isOpened ? 1 : 0,
      transform: `translate3d(0, -${isOpened ? 0 : 20}%, 0)`,
    },
  });

  useChain(
    isOpened
      ? [wrapperRef, contentRef]
      : [contentRef, wrapperRef],
    [0, 0.21],
  );

  const customToggle = typeof toggleContent === 'string'
    ? toggleContent
    : cloneElement(toggleContent, { isOpened });

  return (
    <S.Box>
      <S.Toggle onClick={() => setIsOpened(!isOpened)}>
        {customToggle}
        <S.Icon className={isOpened ? 'opened' : null} />
      </S.Toggle>
      <S.AnimatedWrapper
        style={{
          height: isOpened && prevState === isOpened ? 'auto' : height,
        }}
      >
        <S.Content style={{ opacity, transform }} {...bind}>
          <S.ContentBody>
            {children}
          </S.ContentBody>
        </S.Content>
      </S.AnimatedWrapper>
    </S.Box>
  );
});

Accordion.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node.isRequired,
  toggleContent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  indicator: PropTypes.shape({
    color: PropTypes.oneOf(['default', 'red']),
    value: PropTypes.number.isRequired,
  }),
};

Accordion.defaultProps = {
  isOpen: false,
  indicator: null,
};

export default Accordion;
