import styled, { css } from 'styled-components';
import {
  companyColor, COMPANY_COLOR_KEYS, defaultColor, fluidFont as getThemeFluidFont, font as getThemeFontFamily,
} from 'styles/theme';

const colorOptions = new Set(Object.values(COMPANY_COLOR_KEYS));

const getColor = () => ({ color }) => {
  if (!color) {
    return defaultColor('dark');
  }
  if (colorOptions.has(color)) {
    return companyColor(color);
  }
  return defaultColor(color);
};

const getFontSize = () => (props) => {
  const {
    fluidFont,
    fontSize,
  } = props;

  if (fluidFont) {
    const min = typeof fluidFont.min === 'string' ? fluidFont.min : `${fluidFont.min}px`;
    const max = typeof fluidFont.max === 'string' ? fluidFont.max : `${fluidFont.max}px`;
    return getThemeFluidFont(min, max);
  }

  const size = typeof fontSize === 'string' ? fontSize : `${fontSize}px`;
  return css`
    font-size: ${size};
  `;
};

const getWhiteSpace = () => (props) => {
  const {
    withNewlines,
  } = props;

  if (!withNewlines) return '';

  return css`
    white-space: pre-line;
  `;
};

const getBold = () => (props) => {
  const {
    bold,
  } = props;

  if (!bold) return '';

  return css`
    font-weight: bold;
  `;
};

const getCapitalize = () => (props) => {
  const {
    capitalize,
  } = props;

  if (!capitalize) return '';

  return css`
    text-transform: capitalize;
  `;
};

const getFontFamily = () => (props) => {
  const {
    fontFamily,
  } = props;

  if (!fontFamily) return '';

  return css`
    font-family: ${getThemeFontFamily(fontFamily)};
  `;
};

const getFontWeight = () => ({ fontWeight }) => {
  return css`
    font-weight: ${fontWeight};
  `;
};

export const TextComponent = styled.p`
  height: ${({ h }) => (h ? `${h};` : 'auto')};
  max-height: ${({ mah }) => (mah ? `${mah};` : 'none')};
  min-height: ${({ mih }) => (mih ? `${mih};` : 'auto')};

  width: ${({ w }) => (w ? `${w};` : 'auto')};
  max-width: ${({ maw }) => (maw ? `${maw};` : 'none')};
  min-width: ${({ miw }) => (miw ? `${miw};` : 'auto')};

  color: ${getColor()};
  ${getFontSize()};
  ${getFontFamily()};
  ${getFontWeight()};
  ${getWhiteSpace()};
  ${getBold()};
  ${getCapitalize()};

  line-height: ${({ lh }) => (lh ? `${lh};` : 'normal')};

  ${({ truncate }) => truncate && `
  overflow: hidden;
  text-overflow: ellipsis;
  `};
`;
