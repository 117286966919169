import styled from 'styled-components';

import Button from 'components/ui-kit/buttons/button';
import { ReactComponent as Download } from 'assets/icons/download.svg';

import {
  companyColor,
  defaultColor,
  font,
} from 'styles/theme';

export const Box = styled.div``;

export const ContentBox = styled.div`
  position: relative;
  border: 1px solid ${defaultColor('backgroundLight')};
  border-radius: 8px;
  padding: 25px 20px;
  color: ${companyColor('font')};

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 4px 0.75rem rgba(0, 0, 0, 0.05);
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const SectionTitle = styled.div`
  font-family: ${font('semibold')};
`;

export const SectionItems = styled.div`
  display: flex;
  gap: 2rem;
`;

export const DownloadIcon = styled(Download)`
  fill: ${companyColor('font')};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${defaultColor('background')};
`;

export const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: end;
`;

export const ModalText = styled.div`
  color: ${companyColor('font')};
  line-height: 1.5rem;
`;

// Feedback modal

export const FeedbackBox = styled.div`
  color: ${companyColor('font')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;

  & > p {
    text-align: center;
  }
`;

export const IconBackground = styled.div`
  min-width: 100px;
  max-width: 100px;
  min-height: 100px;
  max-height: 100px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${companyColor('background')};
  border-radius: 100%;
`;

// Request changes modal

export const RequestChangesBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RequestChangesButton = styled(Button).attrs({
  variant: 'text',
})`
  color: ${defaultColor('blue')};
`;
