import PropTypes from 'prop-types';
import getPhoneNumber from 'helpers/getPhoneNumber.helper';
import * as S from './styles';

export default function EmployeeBadge({
  imageUrl,
  name,
  phoneNumber,
  isSmall,
}) {
  const initials = name.split(' ');
  const acronym = `${initials[0].charAt(0)}${initials[1].charAt(0)}`;

  return (
    <S.Box className={isSmall ? 'small' : null}>
      <S.Name>{name}</S.Name>
      {!isSmall && (
        <>
          {(imageUrl) ? <S.Image src={imageUrl} /> : (
            <S.ImagePlaceholder>{acronym}</S.ImagePlaceholder>
          )}
          <S.PhoneNumber>{getPhoneNumber(phoneNumber)}</S.PhoneNumber>
        </>
      )}
    </S.Box>
  );
}

EmployeeBadge.propTypes = {
  imageUrl: PropTypes.string,
  isSmall: PropTypes.bool,
  name: PropTypes.string.isRequired,
  phoneNumber: PropTypes.string.isRequired,
};

EmployeeBadge.defaultProps = {
  imageUrl: null,
  isSmall: false,
};
