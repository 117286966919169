import ButtonLink from 'components/ui-kit/buttons/button-link';
import PageHeader from 'components/ui-kit/page-header';

import * as S from './styles';

export default function ServicePlansHeader() {
  const paymentsButton = (
    <ButtonLink icon={<S.PaymentsIcon />} to="./payments">
      Payments
    </ButtonLink>
  );
  return <PageHeader title="Service Plans" actionItems={paymentsButton} />;
}
