import styled, { css } from 'styled-components';
import {
  defaultColor,
  companyColor,
  radius,
  font,
  fluidFont,
} from 'styles/theme';

const imageSize = '33px';

const imageStyles = css`
  width: ${imageSize};
  height: ${imageSize};
  border-radius: ${radius('full')};
  grid-area: image;
  object-fit: cover;
`;

export const Box = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: ${imageSize} 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-template-areas: 'image name' 'image phoneNumber';
  column-gap: 0.5rem;
  padding: 0.5rem 0.75rem; 
  border-radius: ${radius('small')};
  background-color: ${defaultColor('backgroundLight')};

  &.small {
    grid-template-areas: 'name name' 'name name';
  }
`;

export const Image = styled.img`
  ${imageStyles};
`;

export const Name = styled.div`
  grid-area: name;
  font-family: ${font('semibold')};
  ${fluidFont('10px', '12px')};
  color: ${companyColor('font')};
  line-height: 12px;
  align-self: end;
`;

export const PhoneNumber = styled.div`
  grid-area: phoneNumber;
  font-family: ${font('regular')};
  font-size: 11px;
  color: ${companyColor('font', 0.5)};
  line-height: 11px;
`;

export const ImagePlaceholder = styled.div`
  ${imageStyles};
  background-color: ${defaultColor('metallic')};
  font-family: ${font('semibold')};
  font-size: 0.75rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
`;
