import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ReactComponent as EstimateIcon } from 'assets/icons/icon-estimate.svg';

import {
  companyColor,
  defaultColor,
  fluidFont,
  font,
  radius,
  device,
  statusColor,
} from 'styles/theme';

export const Box = styled(Link)`
  border: 1px solid ${defaultColor('background')};
  border-radius: ${radius('small')};
  padding: 1.25rem;
  text-decoration: none;
  display: grid;
  grid-template-columns: 32px 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'icon title title'
    '. date date'
    'status status amount';
  align-items: center;
  justify-content: space-between;
  color: ${companyColor('font')};
  position: relative;
  column-gap: 1rem;
  row-gap: 1rem;

  @media only screen and (${device('laptop')}) {
    grid-template-columns: 42px 2fr 1fr 1.25fr;
    grid-template-rows: repeat(2, max-content);
    grid-template-areas:
      'icon title amount status'
      '. date amount status';
    row-gap: 0;
  }
`;

export const TypeIcon = styled(EstimateIcon)`
  align-self: start;
  grid-area: icon;
  width: 100%;
`;

export const Dates = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  ${fluidFont('12px', '14px')};
  grid-area: date;
  margin-top: -1rem;
  margin-bottom: 1rem;

  @media only screen and (${device('laptop')}) {
    margin: 0;
  }
`;

export const Amount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  color: ${companyColor('font')};
  ${fluidFont('18px', '22px')};
  font-family: ${font('semibold')};
  margin-right: 0;
  grid-area: amount;

  @media only screen and (${device('laptop')}) {
    margin-right: 2.25rem;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.7rem;

  align-self: center;
  font-family: ${font('semibold')};
  ${fluidFont('14px', '16px')};
  color: ${companyColor('font')};
  grid-area: title;

  @media only screen and (${device('laptop')}) {
    align-self: start;
  }
`;

export const Title = styled.div`
`;

export const Number = styled.div`
  ${fluidFont('12px', '14px')};
  color: ${companyColor('font', 0.3)};
`;

export const OptionNumber = styled.div`
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  background-color: ${() => {
    return companyColor('background', 0.2);
  }};
  padding: 2px;
  border-radius: 2px;
`;

export const Status = styled.div`
  --separator-space: ${({ isPaid }) => (isPaid ? '-0.25rem' : '-1.15rem')};
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  grid-area: status;

  &:before {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(var(--separator-space));
    right: 100%;
    width: 1px;
    height: 85%;
    background-color: ${defaultColor('background')};
  }

  @media only screen and (${device('laptop')}) {
    justify-content: center;

    &:before {
      display: block;
    }
  }
`;

export const StatusText = styled.div`
  color: ${({ $statusColor }) => (statusColor($statusColor) || defaultColor('metallic'))}
`;
