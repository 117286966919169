import { useMutation } from 'react-query';
import { Form, Field, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import { requestEstimateChanges } from 'api';

import Modal from 'components/ui-kit/modal';
import Button from 'components/ui-kit/buttons/button';
import Typography from 'components/ui-kit/typography';
import { Textarea } from 'components/ui-kit/form';

import { useApp, useModal, useToast } from 'hooks';

import * as S from './styles';

const REQUEST_ESTIMATE_CHANGES_MAX_LENGTH = 500;

const schema = yup.object().shape({
  content: yup
    .string()
    .min(1)
    .max(
      REQUEST_ESTIMATE_CHANGES_MAX_LENGTH,
      `Message must be no more than ${REQUEST_ESTIMATE_CHANGES_MAX_LENGTH} characters`,
    )
    .required('Field is required'),
});

const RequestChangesModal = ({ estimateId }) => {
  const { hideModal } = useModal();
  const toast = useToast();
  const { portal } = useApp();

  const mutation = useMutation((content) => requestEstimateChanges({ portalId: portal?.id, estimateId, content }), {
    onSuccess: () => {
      hideModal();
      toast.addSuccessToast('Request was successfully send');
    },
  });

  const handleSubmit = ({ content }) => mutation.mutateAsync(content);

  return (
    <Modal title="Request changes" showCloseButton>
      <S.RequestChangesBox>
        <Typography>Please describe your request below</Typography>

        <Formik
          initialValues={{
            content: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          <Form>
            <Field
              name="content"
              label="Request"
              component={Textarea}
            />

            <S.ButtonGroup>
              <Button onClick={hideModal}>Cancel</Button>

              <Button type="submit" color="primary" isLoading={mutation.isLoading}>Request changes</Button>
            </S.ButtonGroup>
          </Form>
        </Formik>
      </S.RequestChangesBox>
    </Modal>
  );
};

RequestChangesModal.propTypes = {
  estimateId: PropTypes.string.isRequired,
};

export default RequestChangesModal;
