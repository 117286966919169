import { useApp } from 'hooks';
import getPhoneNumber from 'helpers/getPhoneNumber.helper';
import LogoutButton from 'components/logout-button';
import CompanyInfoLoader from './loader';
import * as S from './styles';

export default function CompanyInfo() {
  const { portal } = useApp();

  if (!portal) {
    return (
      <S.InfoBox>
        <LogoutButton isMobile />
        <CompanyInfoLoader />
      </S.InfoBox>
    );
  }

  const {
    phoneNumber,
    website,
    email,
  } = portal;

  return (
    <S.InfoBox>
      <LogoutButton isMobile />
      <S.CompanyName>{portal?.company?.businessName}</S.CompanyName>
      <S.Email href={`mailto:${email}`}>{email}</S.Email>
      <S.PhoneNumber href={`to:${phoneNumber}`}>
        {getPhoneNumber(phoneNumber)}
      </S.PhoneNumber>
      <S.WebsiteLink href={website}>{website}</S.WebsiteLink>
    </S.InfoBox>
  );
}
