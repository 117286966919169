import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  font,
  getDefaultColor,
} from 'styles/theme';

const commonStyles = css`
  font: ${font('fontFamily.SansPro')};
  color: ${getDefaultColor('dark')};
  border: none; 
  height: 34px;
  border-radius: 6px;
  padding: 6px 12px;
`;

const activeStyles = css`
  color: ${({ isActive }) => (isActive ? getDefaultColor('white') : getDefaultColor('dark'))};
  background-color: ${({ isActive }) => (isActive ? getDefaultColor('dark') : 'transparent')};
  font-weight: ${({ isActive }) => (isActive ? 700 : 600)};

  & svg {
    fill: ${({ isActive }) => (isActive ? getDefaultColor('white') : getDefaultColor('dark'))};
  }
`;

const fullWidthStyles = css`
  flex-basis: 1;
  flex-grow: 1;
`;

export const Wrapper = styled.div`
  ${commonStyles}
  ${activeStyles}
  display: flex;
  align-items: center;
  gap: 8px;
  ${({ fullWidth }) => fullWidth && fullWidthStyles};
  border: ${({ bordered }) => (bordered ? `1px solid ${getDefaultColor('secondary100')}` : 'none')};
`;

export const TabActionItems = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TabButton = styled.button.attrs({ type: 'button' })`
  outline: none;
  cursor: pointer;

  font: inherit;
  border: none;
  color: inherit;
  background-color: inherit;
  white-space: nowrap;

  ${({ fullWidth }) => fullWidth && 'width: 100%'};
`;

export const TabLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;
