import styled, { css, keyframes } from 'styled-components';

import { viewports } from 'styles/utils';
import {
  font,
  defaultColor,
  radius,
  companyColor,
  fluidFont,
  device,
  toPixels,
} from 'styles/theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;
const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const slideOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-20%);
  }
`;

export const Backdrop = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 6;
  align-items: center;
  overflow-y: auto;
  background: rgba(31, 45, 92, 0.23);
  padding: 1rem;
  animation: ${fadeIn} 270ms ease-in-out;
  &.exit {
    animation: ${fadeOut} 270ms ease-in-out;
  }

  @media only screen and (${device('laptop')}) {
    padding: 0;
  }
`;

export const Modal = styled.div`
  position: relative;
  background: ${defaultColor('white')};
  padding: 30px;
  border-radius: ${radius('small')};
  margin: 5rem 0;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2.0570156574249268px 5.262077808380127px 0px rgba(0, 0, 0, 0.0186),
              0px 4.046952724456787px 13.272042274475098px 0px rgba(0, 0, 0, 0.0266),
              0px 6.366841793060303px 30.239845275878906px 0px rgba(0, 0, 0, 0.0334),
              0px 10.136334419250488px 70.34402465820312px 0px rgba(0, 0, 0, 0.0414),
              0px 22px 153px 0px rgba(0, 0, 0, 0.06);
  animation: ${slideIn} 270ms ease-in-out;
  &.exit {
    animation: ${slideOut} 270ms ease-in-out;
  }

  @media (max-width: ${viewports.mobileSmall}) {
    grid-column: ${({ size }) => size || '1 / -1'};
  }

  @media (min-width: ${viewports.mobile}) and (max-width: ${viewports.tablet}) {
    grid-column: ${({ size }) => size || '2 / 12'};
  }

  @media (min-width: ${viewports.tablet}) and (max-width: ${viewports.laptop}) {
    grid-column: ${({ size }) => size || '3 / 11'};
  }

  @media (min-width: ${viewports.laptop}) and (max-width: ${viewports.laptopHidpi}) {
    grid-column: ${({ size }) => size || '5 / 9'};
  }

  @media (min-width: ${viewports.laptopHidpi}) and (max-width: ${viewports.desktop}) {
    grid-column: ${({ size }) => size || '4 / 10'};
    max-width: 900px;
  }

  @media (min-width: ${viewports.desktop}) {
    grid-column: ${({ size }) => size || '3 / 11'};
    max-width: 900px;
  }
`;

const flexBoxStyles = css`
  display: flex;
  justify-content: space-between;
`;

const gridStyles = css`
  display: grid;
  grid-template-columns: ${({ gridSettings: { templateColumns } }) => (templateColumns || 'auto')};
  grid-column-gap: ${({ gridSettings: { columnGap } }) => (columnGap || 'initial')};
`;

export const Header = styled.div`
margin-bottom: ${({ mb }) => (mb ? `${toPixels(mb)}` : '0')};
  ${({ gridSettings }) => (gridSettings ? gridStyles : flexBoxStyles)}
  align-items: center;
`;

export const Title = styled.h3`
  font: ${font('semibold')};
  color: ${companyColor('font')};
  ${fluidFont('16px', '18px')};
`;

export const Body = styled.div`
  padding: 0;
  margin: 0;
`;

export const Close = styled.button.attrs({
  type: 'button',
})`
  cursor: pointer;
  background: none;
  border: none;
  ${({ absolute }) => {
    if (!absolute) return () => {};
    return css`
      position: absolute;
      right: 30px;
      top: 30px;
    `;
  }}
  fill=${defaultColor('dark')};
`;
