import { Outlet } from 'react-router-dom';

import Sidebar from 'components/sidebar';
import Aside from 'components/aside';
import ModalProvider from 'components/ui-kit/modal/context/modalProvider';
import ModalRoot from 'components/ui-kit/modal/context/modalRoot';
import ToastProvider from 'components/ui-kit/toast/context/toastProvider';
import MobileHeader from 'components/ui-kit/mobile-header';
import { ViewAttachmentModalProvider } from 'components/view-attachment-modal/context';
import ViewAttachmentModal from 'components/view-attachment-modal';
import SecondaryModalProvider from 'components/secondary-modal/context/secondaryModalProvider';
import SecondaryModalRoot from 'components/secondary-modal/context/SecondaryModalRoot';

import { useApp } from 'hooks';
import * as S from './styles';

function Layout() {
  const { sidebar } = useApp();

  return (
    <ToastProvider>
      <ModalProvider>
        <SecondaryModalProvider>
          <ViewAttachmentModalProvider>
            <S.GridLayout>
              <MobileHeader />

              <Sidebar isShownMobile={sidebar.isShow} />

              <Aside />

              <S.Main isBlur={sidebar.isShow}>
                <Outlet />
              </S.Main>
            </S.GridLayout>

            <ModalRoot />

            <SecondaryModalRoot />

            <ViewAttachmentModal />
          </ViewAttachmentModalProvider>
        </SecondaryModalProvider>
      </ModalProvider>
    </ToastProvider>
  );
}

export default Layout;
