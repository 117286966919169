import { useQuery } from 'react-query';
import { useApp, useFields } from 'hooks';
import { add } from 'date-fns';
import { invoicePaymentFilter } from 'constants/invoices';
import { getAllInvoices } from 'api';
import ListPlaceholder from 'components/ui-kit/list-placeholder';
import Filters from 'components/filters';
import InvoicesLoader from './loader';
import * as S from './styles';
import InvoiceItem from '../invoice-item';

const daterange = {
  from: add(new Date(), { days: -30 }),
  to: add(new Date(), { days: 7 }),
};

export default function InvoicesListing() {
  const { portal } = useApp();

  const { fields, handleClickChange, handleChange } = useFields({
    paymentStatus: 'all',
    daterange: [daterange.from, daterange.to],
  });

  const { data, isLoading, isFetching } = useQuery([
    'invoicesListingQuery',
    portal?.id,
    {
      from: fields.daterange[0].toISOString(),
      to: fields.daterange[1].toISOString(),
      paymentStatus: invoicePaymentFilter[fields.paymentStatus],
    }], getAllInvoices, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!portal?.id,
  });

  const renderContent = () => {
    if (isLoading || isFetching) {
      return <InvoicesLoader />;
    }

    if (!data?.data || !data?.data.length) {
      return <ListPlaceholder message="No invoices found" />;
    }

    return (
      <S.List>
        {data?.data && data?.data.map((item) => (
          <S.ListItem key={item.id}>
            <InvoiceItem
              id={item.id}
              portalId={portal?.id}
              title={item.name}
              sentDate={item.sentDate}
              paidDate={item.paidDate}
              totalAmount={item.totalAmount}
            />
          </S.ListItem>
        ))}
      </S.List>
    );
  };

  return (
    <>
      <Filters
        fields={fields}
        handleClickChange={handleClickChange}
        handleChange={handleChange}
        select={{
          options: [
            { label: 'All', value: 'all' },
            { label: 'Due', value: 'due' },
            { label: 'Paid', value: 'paid' },
          ],
          name: 'paymentStatus',
          label: 'Payment Statuses',
        }}
      />
      {renderContent()}
    </>
  );
}
