import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { declineEstimate, getAppointment, getEstimatePdfAsync } from 'api';

import {
  ApproveEstimateModal,
  Breadcrumbs,
  Button,
  ButtonLink,
  ConfirmationButton,
  InfoBoxItem,
  PageHeader,
  PaymentSummary,
  RequestTitle,
  Group,
} from 'components/ui-kit';
import AttachedFormsList from 'components/attached-forms-list/index';

import { useApp, useModal } from 'hooks';
import routes from 'router/routes';

import { getFullName } from 'helpers/business.helper';
import { defaultDateFormat } from 'helpers/datetime.helper';
import { estimateApprovalTypes } from 'helpers/enums';

import RequestFeedbackModal from './feedback-modal';
import RequestChangesModal from './request-changes-modal';

import { APPROVAL_STATUSES, getModalContent } from './utils';

import * as S from './styles';

const RequestItemDetails = () => {
  const { id } = useParams();
  const { portal, user } = useApp();
  const { showModal } = useModal();
  const [lineItems, setLineItems] = useState([]);

  const {
    isError, isLoading, isFetching, data: appointmentData, refetch,
  } = useQuery(
    ['appointment', portal?.id, id],
    getAppointment,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!portal?.id,
    },
  );

  const declineMutation = useMutation(({ portalId, estimateId }) => (declineEstimate(portalId, estimateId)));

  useEffect(() => {
    if (appointmentData?.data?.lineItems?.length) {
      const { lineItems: initialLineItems } = appointmentData.data;

      setLineItems(initialLineItems.map((item) => {
        const { optionalState } = item;

        let updatedOptionalState = null;

        if (optionalState) {
          updatedOptionalState = {
            isRecommended: optionalState.isRecommended,
            isAccepted: optionalState.isAccepted === null ? optionalState.isRecommended : optionalState.isAccepted,
          };
        }

        return {
          ...item,
          optionalState: updatedOptionalState,
          key: uuidv4(),
        };
      }));
    }
  }, [appointmentData, appointmentData?.data?.lineItems]);

  if (isError) {
    return 'Error occurred while fetching appointment';
  }

  if (isLoading || isFetching) {
    return null;
  }

  const { data: appointment } = appointmentData;

  const {
    id: appointmentId,
    title,
    estimateNumber,
    hasOptions,
    optionNumber,
    checkLists,
    approvalStatus,
    estimateSent,
    serviceAddress,
    unitNumber,
    businessEntityType,
    taxRate,
    discount,
    appointmentNotes,
    preCalculatedSummary,
  } = appointment || {};

  const isReadOnly = approvalStatus !== estimateApprovalTypes.notYetApproved.value;

  const handleDecline = async () => {
    await declineMutation.mutateAsync({ estimateId: appointmentId, portalId: portal?.id });
    await refetch();
  };

  const afterDecline = () => {
    setTimeout(() => {
      showModal(RequestFeedbackModal, { companyName: portal?.company?.businessName });
    }, 300);
  };

  return (
    (!!appointment
      && (
      <S.Box>
        <Breadcrumbs
          items={[
            { text: routes.requests.title, to: `../${routes.requests.path}` },
            { text: title, to: '#' },
          ]}
          gap="1rem"
        />

        <S.ContentBox>
          <PageHeader
            title={(
              <RequestTitle
                baseFontSize={26}
                title={title}
                number={estimateNumber}
                hasOptions={hasOptions}
                option={optionNumber}
              />
            )}
            actionItems={(
              <ButtonLink
                icon={<S.DownloadIcon />}
                newTab
                href={getEstimatePdfAsync(portal?.id, id, lineItems)}
              >
                Download Estimate
              </ButtonLink>
            )}
          />

          <S.Section>
            <S.SectionTitle>
              Recipient details
            </S.SectionTitle>

            <S.SectionItems>
              <InfoBoxItem title="Name" value={getFullName(user)} showColon={false} />

              <InfoBoxItem title="Company" value={user?.ownCompanyName} showColon={false} />
            </S.SectionItems>
          </S.Section>

          <S.Divider />

          <S.Section>
            <S.SectionTitle>
              Estimate details
            </S.SectionTitle>

            <S.SectionItems>
              <InfoBoxItem
                title="Sent date"
                value={
                  estimateSent
                    ? format(parseISO(estimateSent), defaultDateFormat)
                    : null
                }
                placeholder="n/a"
                showColon={false}
              />

              <InfoBoxItem
                title="Status"
                value={APPROVAL_STATUSES[approvalStatus].label}
                showColon={false}
                statusColor={APPROVAL_STATUSES[approvalStatus].color}
              />

              <InfoBoxItem
                title="Service property"
                value={serviceAddress}
                description={unitNumber ? `Unit #: ${unitNumber}` : ''}
                showColon={false}
              />
            </S.SectionItems>
          </S.Section>

          {!!checkLists?.length && (
            <AttachedFormsList
              entityType={businessEntityType}
              checkLists={checkLists}
              eventId={appointmentId}
            />
          )}

          <S.Divider />

          <PaymentSummary
            lineItems={lineItems}
            taxRate={taxRate}
            discount={discount}
            withOptional={!!lineItems.filter((lineItem) => !!lineItem.optionalState).length}
            setLineItems={setLineItems}
            disabled={isReadOnly}
            preCalculatedSummary={preCalculatedSummary || {}}
          />

          {!isReadOnly && (
            <S.ButtonGroupWrapper>
              <S.RequestChangesButton
                onClick={() => {
                  showModal(RequestChangesModal, { estimateId: id });
                }}
              >
                Request changes
              </S.RequestChangesButton>

              <S.ButtonGroup>
                <ConfirmationButton
                  buttonTitle="Decline"
                  onConfirm={() => handleDecline()}
                  afterConfirm={() => afterDecline()}
                  modalContent={getModalContent(appointment?.estimateNumber, 'decline')}
                >
                  Decline
                </ConfirmationButton>

                <Button
                  onClick={() => showModal(ApproveEstimateModal, { id, estimateTitle: title, lineItems })}
                  color="success"
                >
                  Approve
                </Button>
              </S.ButtonGroup>
            </S.ButtonGroupWrapper>
          )}

          {!!appointmentNotes && (
            <Group mt={16}>
              <InfoBoxItem
                title="Estimate Notes"
                value={appointmentNotes}
                showColon={false}
              />
            </Group>
          )}
        </S.ContentBox>
      </S.Box>
      )
    )
  );
};

export default RequestItemDetails;
