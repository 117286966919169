import styled from 'styled-components';
import {
  font,
  companyColor,
  defaultColor,
  fluidFont,
} from 'styles/theme';

export const List = styled.ul`
  list-style: none;
  padding: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
`;

export const ListTitle = styled.div`
  font-family: ${font('semibold')};
  ${fluidFont('18px', '20px')};
  margin: 2.25rem 0 1.25rem 0;
  color: ${companyColor('font')};
`;

export const ListItem = styled.li`
  position: relative;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 0.75rem;
  color: ${companyColor('font')};
  line-height: 10px;
  ${fluidFont('12px', '14px')};
  width: 100%;
`;

export const Date = styled.div`
  display: flex;
  flex: 0 1 auto;
  gap: 0.35rem;
  align-items: baseline;
`;

export const DayName = styled.div`
  font-family: ${font('semibold')};
  font-size: inherit;
  color: inherit;
`;

export const DayDate = styled.div`
  font-family: ${font('regular')};
  font-size: inherit;
  color: inherit;
`;

export const ListOfDay = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Placeholder = styled.p`
  color: ${defaultColor('metallic')};
  ${fluidFont('11px', '13px')};
`;
