import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

export default function Tab({
  content,
  isActive,
  onClicked,
  actionItems,
  to,
  fullWidth,
  bordered,
}) {
  const hasActionItems = actionItems || actionItems?.length;

  const MainItem = to ? (
    <S.TabLink to={to} fullWidth={fullWidth}>
      {content}
    </S.TabLink>
  ) : (
    <S.TabButton onClick={onClicked} fullWidth={fullWidth}>
      {content}
    </S.TabButton>
  );

  return (
    <S.Wrapper isActive={isActive} fullWidth={fullWidth} bordered={bordered}>
      {MainItem}

      {hasActionItems && (
        <S.TabActionItems>
          {actionItems}
        </S.TabActionItems>
      )}
    </S.Wrapper>
  );
}

Tab.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isActive: PropTypes.bool,
  onClicked: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  actionItems: PropTypes.node,
  fullWidth: PropTypes.bool,
  bordered: PropTypes.bool,
};

Tab.defaultProps = {
  isActive: false,
  onClicked: () => {},
  to: null,
  actionItems: null,
  fullWidth: false,
  bordered: false,
};
