/* eslint-disable no-plusplus */
import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ToastContext from './toastContext';
import { ToastContainer } from '../toastContainer';

let id = 0;

export default function ToastProvider({ children }) {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((content, duration, isErrorMessage) => {
    setToasts((oldToasts) => [
      ...oldToasts,
      {
        id: String(id++), content, isErrorMessage, duration,
      },
    ]);
  }, [setToasts]);

  const addSuccessToast = useCallback((content, duration) => {
    setToasts((oldToasts) => [
      ...oldToasts,
      {
        id: String(id++), content, isErrorMessage: false, duration,
      },
    ]);
  }, [setToasts]);

  const addErrorToast = useCallback((content, duration) => {
    setToasts((oldToasts) => [
      ...oldToasts,
      {
        id: String(id++), content, isErrorMessage: true, duration,
      },
    ]);
  }, [setToasts]);

  const removeToast = useCallback((toastId) => {
    setToasts((oldToasts) => oldToasts.filter((toast) => toast.id !== toastId));
  }, [setToasts]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    addToast,
    removeToast,
    addSuccessToast,
    addErrorToast,
  };

  return (
    <ToastContext.Provider value={values}>
      <ToastContainer toasts={toasts} />
      {children}
    </ToastContext.Provider>
  );
}

ToastProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]).isRequired,
};
