import { ReactComponent as RocketIcon } from 'assets/rocket.svg';
import styled from 'styled-components';
import {
  radius,
  defaultColor,
  companyColor,
  font,
  fluidFont,
} from 'styles/theme';

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  ${({ hasLogo }) => {
    if (hasLogo) return 'margin-bottom: calc(200px + 40px);';
    return '';
  }};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  padding: 1rem;
  border-radius: ${radius('small')};
  background-color: ${defaultColor('white')};
`;

export const Text = styled.p`
  font-family: ${font('regular')};
  ${fluidFont('11px', '13px')};
  color: ${companyColor('font')};
`;

export const Logo = styled.img`
  max-width: 200px;
  max-height: 200px;
  border-radius: 8px;
  display: block;
`;

export const SuccessView = styled.div`
  padding: 1.5rem;
  border-radius: ${radius('small')};
  background-color: ${defaultColor('white')};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const Rocket = styled(RocketIcon)`
  width: 250px;
  height: 100px;
  margin: 0 auto;
`;

export const SuccessTitle = styled.h3`
  font-family: ${font('semibold')};
  ${fluidFont('18px', '20px')};
  color: ${companyColor('font')};
`;

export const SuccessMessage = styled.p`
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  color: ${companyColor('font')};
  text-align: center;
`;

export const CustomerEmail = styled.span`
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  color: ${companyColor('main')};
`;
