import SendEmailForm from 'components/send-email-form';
import SimpleLayout from 'layouts/simple-layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPortal } from 'api';
import Spinner from 'components/ui-kit/spinner';
import { useApp } from 'hooks';
import { useEffect } from 'react';

export default function LoginPage() {
  const { state } = useLocation();
  const { setColorTheme } = useApp();
  const fromState = state && state.portalId && state.logoUrl;
  const navigate = useNavigate();
  const portalId = state?.portalId || window.location.pathname.split('/')[1];

  const { isLoading, data, isError } = useQuery(
    ['currentPortal', portalId],
    getPortal,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !fromState,
    },
  );

  useEffect(() => {
    if (data) {
      setColorTheme(data?.data?.colors);
    }
  }, [data, setColorTheme]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    navigate('/404');
  }

  return (
    <SimpleLayout>
      <SendEmailForm
        portalId={portalId}
        logoUrl={fromState ? state.logoUrl : data?.data?.logoUrl}
      />
    </SimpleLayout>
  );
}
