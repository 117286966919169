import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import last from 'lodash/last';
import * as S from './styles';

export default function Label({
  isLabelUp,
  isError,
  label,
  fieldName,
  placeholder,
  disabled,
  hideLabel,
}) {
  function getNaturalName(nameOfField) {
    // e.g. personalInformation.firstName => firstName
    const shortName = last(nameOfField.split('.'));

    return startCase(shortName);
  }
  const naturalName = getNaturalName(fieldName);
  const labelContent = label || naturalName;

  const getContent = () => {
    if (placeholder) {
      return isLabelUp ? labelContent : placeholder;
    }

    return labelContent;
  };

  const getClassName = () => isLabelUp && 'label_up';
  const hideClassName = () => isLabelUp && hideLabel && 'hide_label';

  const resultClassName = `${getClassName()} ${hideClassName()}`;
  return (
    <S.Label
      className={resultClassName}
      isError={isError}
      isDisabled={disabled}
    >
      <S.LabelContent
        className={resultClassName}
      >
        {getContent()}
      </S.LabelContent>
    </S.Label>
  );
}

Label.propTypes = {
  isError: PropTypes.bool.isRequired,
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  isLabelUp: PropTypes.bool,
  disabled: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

Label.defaultProps = {
  label: '',
  placeholder: '',
  isLabelUp: false,
  disabled: false,
  hideLabel: false,
};
