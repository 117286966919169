import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  companyColor,
  radius,
  fluidFont,
  font,
  defaultColor,
  spacing,
} from 'styles/theme';

export const Box = styled(Link)`
  border: 1px solid ${defaultColor('background')};
  border-radius: ${radius('small')};
  text-decoration: none;

  ${fluidFont('11px', '13px')};
  padding: ${spacing('default')};

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${spacing('default')};
  overflow: hidden;
`;

export const Name = styled.div`
  ${fluidFont('13px', '16px')};
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-basis: 1;
  flex-shrink: 2;  
  overflow: hidden;
`;

export const Status = styled.div`
  color: ${({ color }) => (defaultColor(color))};
`;

export const VisitsPerYear = styled.span`
  display: flex;
  align-items: center;
  color: ${companyColor('font')};
  gap: 0.5em;
  margin-top: auto;
`;

export const VisitsIcon = styled.span`
  width: 22px;
  display: flex;
  align-items: center;

  svg {
    fill: currentColor;
    width: 100%;
  };
`;
