import { appointmentBusinessTypes } from 'constants/appointments';
import crypto from 'crypto';
import config from 'config';
import {
  addDays, addHours, setHours, setMinutes,
} from 'date-fns';

const { portalUrl } = config;

const fakeEmployees = [{
  firstName: 'Gordon',
  lastName: 'Norman',
  id: 'gordon_norman',
  phoneNumber: '+12025550157',
  logoUrl: `${portalUrl}/avatars/gordon_norman.png`,
}, {
  firstName: 'Hanson',
  lastName: 'Deck',
  id: 'hanson_deck',
  phoneNumber: '+12027560155',
  logoUrl: `${portalUrl}/avatars/hanson_deck.png`,
}, {
  firstName: 'Sue',
  lastName: 'Shei',
  id: 'sue_shei',
  phoneNumber: '+12021234567',
  logoUrl: `${portalUrl}/avatars/sue_shei.png`,
}, {
  firstName: 'Eleanor',
  lastName: 'Fant',
  id: 'eleanor_fant',
  phoneNumber: '+12026789012',
  logoUrl: `${portalUrl}/avatars/eleanor_fant.png`,
},
];

const getFakeAppointment = ({
  title, entityType, amount, employees, status, start,
}) => {
  const id = crypto.randomBytes(20).toString('hex');
  const businessType = appointmentBusinessTypes[entityType];
  return {
    id: `appointment_${id}`,
    start,
    end: addHours(start, 2),
    title,
    serviceAddress: '100 N 15th Ave, Phoenix, AZ 85007, United States',
    employees,
    entityType: businessType,
    businessEntityType: businessType,
    status,
    totalAmount: amount,
    invoiceId: `invoice_${id}`,
    invoiceNumber: null,
    invoiceSent: '2022-02-18T09:21:15.027Z',
    invoiceStatus: 0,
    wasEstimateSent: false,
  };
};

export const getFakeAppointments = () => {
  const tomorrow = addDays(new Date(), 1);
  const evening = setMinutes(setHours(tomorrow, 18), 0);

  return [
    getFakeAppointment({
      title: 'Move In cleaning',
      entityType: 'job',
      amount: 180,
      employees: [fakeEmployees[0], fakeEmployees[1], fakeEmployees[2]],
      status: 'Scheduled',
      start: evening.toISOString(),
    }),
    getFakeAppointment({
      title: 'Sofa steam cleaning',
      entityType: 'job',
      amount: 100,
      employees: [fakeEmployees[0], fakeEmployees[3]],
      status: 'Scheduled',
      start: addDays(evening, 1).toISOString(),
    }),
    getFakeAppointment({
      title: 'Deep cleaning service',
      entityType: 'estimate',
      amount: 180,
      employees: [fakeEmployees[1]],
      status: 'Scheduled',
      start: addDays(evening, 3).toISOString(),
    }),
    getFakeAppointment({
      title: 'Weekly basic cleaning',
      entityType: 'visit',
      amount: 180,
      employees: [fakeEmployees[0], fakeEmployees[2]],
      status: 'Scheduled',
      start: addDays(evening, 4).toISOString(),
    }),
  ];
};
