import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useSpring, animated } from 'react-spring';
import Indicator from 'components/ui-kit/indicator';
import * as S from './styles';

export default function ItemTitle({
  date,
  title,
  count,
  isOpened,
}) {
  const { scale } = useSpring({
    from: { scale: 0 },
    scale: isOpened ? 0 : 1,
  });

  return (
    <S.Title>
      <S.Date>
        <S.DayName>
          {title || format(date, 'EEEE')}
        </S.DayName>
        |
        <S.DayDate>{format(date, 'MMM d')}</S.DayDate>
      </S.Date>
      <animated.div
        style={{
          scale: scale.to({ range: [0, 1], output: [0, 1] }),
          opacity: scale.to({ range: [0, 1], output: [0, 1] }),
        }}
      >
        <Indicator value={count} />
      </animated.div>
    </S.Title>
  );
}

ItemTitle.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  title: PropTypes.string,
  isOpened: PropTypes.bool,
  count: PropTypes.number.isRequired,
};

ItemTitle.defaultProps = {
  title: null,
  isOpened: false,
};
