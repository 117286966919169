import PropTypes from 'prop-types';
import { useResolvedPath, useMatch } from 'react-router-dom';
import * as S from './styles';

export default function SwitcherLink({
  children,
  to,
  ...restProps
}) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className={match ? 'active' : null}>
      <S.LinkBox
        to={to}
        className={match ? 'active' : null}
        {...restProps}
      >
        {children}
      </S.LinkBox>
    </li>
  );
}

SwitcherLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]),
  to: PropTypes.string.isRequired,
};

SwitcherLink.defaultProps = {
  children: null,
};
