import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useTransition, config } from 'react-spring';
import * as S from './styles';
import Toast from './toast';

export function ToastContainer({ toasts }) {
  const transitions = useTransition(toasts, {
    config: config.gentle,
    from: { right: '-100%' },
    enter: { right: '0%' },
    leave: { right: '-100%' },
  });

  return createPortal(
    <S.ToastContainerWrapper>
      {transitions((style, item) => {
        return (
          <Toast
            style={style}
            id={item.id}
            key={item.id}
            isErrorMessage={item.isErrorMessage}
            duration={item.duration}
          >
            {item.content}
          </Toast>
        );
      })}
    </S.ToastContainerWrapper>,
    document.getElementById('toast-root'),
  );
}

ToastContainer.propTypes = {
  toasts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      isErrorMessage: PropTypes.bool.isRequired,
      content: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.array]).isRequired,
    }),
  ),
};

ToastContainer.defaultProps = {
  toasts: null,
};
