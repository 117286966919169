import PropTypes from 'prop-types';

import * as S from './styles';

const mapBreadItems = (items) => items.map((item, index) => ({
  ...item,
  key: `${String(index)}`,
  text: index === items.length - 1 ? item.text : `${item.text} /`,
}));

export default function Breadcrumbs({ items, gap }) {
  const breadItems = mapBreadItems(items);

  return (
    <S.Box gap={gap}>
      {breadItems.map((item) => (item.to ? (
        <S.RouteItem key={item.key} to={item.to}>
          {item.text}
        </S.RouteItem>
      ) : (
        <S.TextItem key={item.key}>{item.text}</S.TextItem>
      )))}
    </S.Box>
  );
}

const breadrubmsItem = PropTypes.shape({
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
}).isRequired;

breadrubmsItem.defaultProps = {
  to: null,
};

Breadcrumbs.propTypes = {
  items: PropTypes.arrayOf(breadrubmsItem).isRequired,
  gap: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  gap: null,
};
