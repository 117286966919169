import styled, { keyframes, css } from 'styled-components';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';

import {
  font,
  getDefaultColor,
  spacing,
  easing,
  radius,
  device,
} from 'styles/theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
`;

export const Backdrop = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'grid' : 'none')};
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: auto;
  grid-column-gap: ${spacing('base')};

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.15);
  z-index: 100;
  animation: ${fadeIn} .19s ease-out forwards;
  align-items: center;
  overflow-y: auto;
`;

export const Modal = styled.div`
  background: ${getDefaultColor('white')};
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.06);
  animation: ${slideIn} .33s ${easing('in')};
  padding: ${spacing('large')};
  border-radius: ${radius('large')};
  margin: 5rem 0;
  grid-column: 1 / -1;

  @media(${device('tablet')}) {
    grid-column: ${({ size }) => (size || '4 / 10')};
  }
`;

export const CloseButton = styled.button.attrs({ type: 'button' })`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;

  color: ${getDefaultColor('gray')};

  &:hover {
    color: ${getDefaultColor('primary')};
  }
`;

export const Close = styled(CloseIcon)`
  fill: ${getDefaultColor('gray')};
  stroke: ${getDefaultColor('gray')};

  &:hover {
    fill: ${getDefaultColor('primary')};
    stroke: ${getDefaultColor('primary')};
  }
`;

const flexBoxStyles = css`
  display: flex;
  justify-content: space-between;
`;

const gridStyles = css`
  display: grid;
  grid-template-columns: ${({ gridSettings: { templateColumns } }) => (templateColumns || 'auto')};
  grid-column-gap: ${({ gridSettings: { columnGap } }) => (columnGap || 'initial')};
`;

export const Header = styled.div`
  ${({ gridSettings }) => (gridSettings ? gridStyles : flexBoxStyles)}
  align-items: center;
  margin-bottom: ${({ marginBottom }) => (spacing(marginBottom) || spacing('extraSmall'))}; 
`;

export const Title = styled.h3`
  font: ${font('blockTitle')};
  color: ${getDefaultColor('primary')};
  line-height: 45px;
`;

export const Body = styled.div`
  padding: 0;
  margin: 0;
`;
