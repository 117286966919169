import PropTypes from 'prop-types';
import * as S from './styles';

export default function InfoPlaceholder({ text }) {
  return (
    <S.Text>
      {text}
    </S.Text>
  );
}

InfoPlaceholder.propTypes = {
  text: PropTypes.string.isRequired,
};
