import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import * as S from './styles';

function Checkbox(props) {
  const {
    field,
    form,
    disabled,
    gap,
    children,
    size,
    color,
    withoutFormik,
    textValue,
  } = props;

  const { name, value } = withoutFormik ? props : field;
  const error = withoutFormik ? null : form.errors[name];
  const touched = withoutFormik ? null : form.touched[name];
  const onChange = (e) => {
    const { onChange: onChangeProp } = props;
    if (onChangeProp) {
      onChangeProp(e);
    }
    if (!withoutFormik) {
      form.setFieldValue(name, value ? !value : textValue || true);
    }
  };

  return (
    <S.Label htmlFor={name} gap={gap} disabled={disabled}>
      <S.Input type="checkbox" checked={Boolean(value)} id={name} disabled={disabled} onChange={onChange} />

      <S.CheckIcon size={size} error={touched && error} value={value} iconcolor={color} />

      {children || startCase(name)}
    </S.Label>
  );
}

Checkbox.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.bool, PropTypes.string]),
  }),
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    touched: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  }),
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  gap: PropTypes.bool,
  size: PropTypes.number,
  color: PropTypes.string,
  withoutFormik: PropTypes.bool,
  textValue: PropTypes.string,
};

Checkbox.defaultProps = {
  children: '',
  disabled: false,
  onChange: undefined,
  gap: true,
  size: 24,
  color: 'dark',
  form: null,
  field: null,
  withoutFormik: false,
  textValue: null,
};

export default Checkbox;
