import ContentLoader from 'react-content-loader';

const ITEMS_LENGTH = [1, 2];

export default function AppointmentLoader(props) {
  return ITEMS_LENGTH.map((item) => (
    <ContentLoader
      key={String(item)}
      height={160}
      viewBox="0 0 693 160"
      backgroundColor="#f5f5f5"
      foregroundColor="#dbdbdb"
      {...props}
    >
      <rect x="0" y="8" rx="6" ry="6" width="8" height="154" />
      <rect x="7" y="154" rx="6" ry="6" style={{ width: '100%' }} height="8" />
      <rect x="686" y="9" rx="6" ry="6" width="7" height="154" />
      <rect x="0" y="8" rx="6" ry="6" style={{ width: '100%' }} height="7" />
      <rect x="20" y="30" rx="6" ry="6" width="35" height="35" />
      <rect x="70" y="33" rx="6" ry="6" width="250" height="15" />
      <rect x="70" y="53" rx="6" ry="6" width="200" height="10" />
      <rect x="20" y="100" rx="6" ry="6" width="200" height="40" />
      <rect x="230" y="100" rx="6" ry="6" width="200" height="40" />
      <rect x="570" y="30" rx="6" ry="6" width="100" height="10" />
      <rect x="570" y="50" rx="6" ry="6" width="100" height="10" />
      <rect x="570" y="100" rx="6" ry="6" width="100" height="40" />
    </ContentLoader>
  ));
}
