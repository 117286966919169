import styled, { css } from 'styled-components';
import {
  defaultColor,
  statusColor,
  companyColor,
  radius,
  font,
  fluidFont,
} from 'styles/theme';

const setFont = (style, min, max) => css`
  font-family: ${font(style)};
  ${fluidFont(min, max)};
`;

const flexCol = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Box = styled.div`
  ${flexCol};
  ${setFont('regular', '11px', '13px')};
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  border-radius: ${radius('small')};
  border: 1px solid ${defaultColor('background')};
  padding: 1rem;
  ${flexCol};
`;

export const BoxTitle = styled.div`
  ${setFont('semibold', '14px', '16px')};
  color: ${companyColor('font')};
`;

export const Item = styled.li`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const ItemTitle = styled.div`
  ${setFont('regular', '11px', '13px')};
  color: ${defaultColor('dark')};
  font-weight: bold;
`;

export const ItemValue = styled.div`
  white-space: pre-line;
  ${setFont('regular', '11px', '13px')};
  color: ${({ $valueColor }) => (companyColor($valueColor) || defaultColor($valueColor) || companyColor('font'))};
  ${({ $statusColor }) => {
    if ($statusColor) {
      return css`
        color: ${statusColor($statusColor)};
      `;
    }
    return null;
  }};
`;

export const ItemLink = styled.a`
  ${setFont('regular', '11px', '13px')};
  color: ${({ color }) => (color ? defaultColor(color) : companyColor('main'))};
  cursor: pointer;
  margin-bottom: 0px;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;
