import PropTypes from 'prop-types';
import * as S from './styles';

export default function LabelMark({ text, color }) {
  return (
    <S.Box $color={color}>
      {text}
    </S.Box>
  );
}

LabelMark.propTypes = {
  text: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'green',
    'blue',
    'yellow',
    'metallic',
    'font',
  ]).isRequired,
};
