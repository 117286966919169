import PropTypes from 'prop-types';
import { getServicePlanReceiptPdfAsync } from 'api';
import { invoicePaymentMethodTypes, invoicePaymentStatuses } from 'helpers/enums';
import { buildCardSelectEntry } from 'helpers/business.helper';
import DateLine from 'components/ui-kit/date-line';
import LabelMark from 'components/ui-kit/label-mark';
import PaidSection from 'components/paid-section';
import Money from 'components/ui-kit/money';
import * as S from './styles';

const dateFormat = 'MMM do, yyyy';

const paymentMethods = Object.values(invoicePaymentMethodTypes);

export default function ServicePlanInvoiceItem({
  id,
  portalId,
  servicePlanName,
  paidDate,
  paymentDate,
  status,
  totalAmount,
  paymentMethodType,
  cardInfo,
}) {
  const isPaid = status === invoicePaymentStatuses.paid.value;
  const paidByCard = isPaid && paymentMethodType === invoicePaymentMethodTypes.card.value;

  return (
    <S.Box>
      <S.Main>
        <S.Content>
          <S.SubscriptionInvoiceIcon />
          <S.Info>
            <S.Title>{servicePlanName}</S.Title>
            <S.InfoLines>
              <DateLine
                date={paymentDate}
                label="Payment Date"
                format={dateFormat}
              />
              <DateLine
                date={isPaid ? paidDate : null}
                label="Paid Date"
                dateColor={isPaid ? 'green' : 'blue'}
                format={dateFormat}
              />
              {isPaid
                ? (
                  <S.InfoItem>
                    <S.PaymentMethodIcon />
                    <S.PaidBy>Paid by:</S.PaidBy>
                    <span>
                      {paidByCard && cardInfo
                        ? buildCardSelectEntry(cardInfo)
                        : paymentMethods[paymentMethodType].label}
                    </span>
                    <S.DownloadInvoiceLink href={getServicePlanReceiptPdfAsync(portalId, id)}>
                      <LabelMark text="receipt" color="font" />
                    </S.DownloadInvoiceLink>
                  </S.InfoItem>
                ) : null}
            </S.InfoLines>
          </S.Info>
        </S.Content>
        <S.Amount>
          <Money amount={totalAmount} />
        </S.Amount>
      </S.Main>
      <S.Status>
        {isPaid
          ? <PaidSection />
          : <S.NotPaidText>Not paid</S.NotPaidText>}
      </S.Status>
    </S.Box>
  );
}

ServicePlanInvoiceItem.propTypes = {
  id: PropTypes.string.isRequired,
  portalId: PropTypes.string.isRequired,
  servicePlanName: PropTypes.string,
  paidDate: PropTypes.string,
  paymentDate: PropTypes.string,
  status: PropTypes.number.isRequired,
  paymentMethodType: PropTypes.number.isRequired,
  totalAmount: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  cardInfo: PropTypes.object,
};

ServicePlanInvoiceItem.defaultProps = {
  servicePlanName: 'Service Plan Subscription',
  paidDate: null,
  paymentDate: null,
  cardInfo: null,
};
