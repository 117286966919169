import { memo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import routes from 'router/routes';
import PropTypes from 'prop-types';
import format from 'date-fns/format';
import EmployeeBadge from 'components/ui-kit/employee-badge';
import LabelMark from 'components/ui-kit/label-mark';
import {
  getIcon,
  getColor,
  appointmentTypes,
  appointmentBusinessTypes,
} from 'constants/appointments.js';
import { useApp } from 'hooks';
import Money from 'components/ui-kit/money';
import * as S from './styles';

function AppointmentItem({
  title,
  type,
  date,
  address,
  unitNumber,
  employees,
  totalAmount,
  variant,
  isCompact,
  invoice,
  invoiceId,
  status,
  id,
  to,
}) {
  const { portalId } = useParams();
  const { isPreviewMode } = useApp();
  const navigate = useNavigate();
  const redirectToInvoice = () => navigate(`/${portalId}/${routes.invoices.path}/${invoiceId}`);
  const isRow = variant === 'row';
  const isCompactMode = isCompact && isRow;
  const path = `/${portalId}/${routes.appointments.path}/${id}`;

  const redirectTo = isPreviewMode ? '#' : (to || path);

  return (
    <S.Box
      to={redirectTo}
      $isRow={isRow}
      className={isCompactMode && 'compact'}
      type={type}
      title={title}
    >
      <S.TypeIcon isRow={isRow} isCompact={isCompactMode}>
        {getIcon(type)}
      </S.TypeIcon>
      <S.Header isCompact={isCompactMode} isRow={isRow}>
        <S.Title isRow={isRow}>{title}</S.Title>
        {isRow && (
          <>
            <LabelMark text={appointmentTypes[type]} color={getColor(type)} />
            {!!status && (
              <S.StatusMark>
                <LabelMark
                  text={status || ''}
                  color="metallic"
                />
              </S.StatusMark>
            )}
          </>
        )}
      </S.Header>
      <S.Address>
        {!isRow && (<S.Label>Property:</S.Label>)}
        <div>{address}</div>
        {unitNumber && (
        <div>
          Unit #:&nbsp;
          {unitNumber}
        </div>
        )}
      </S.Address>
      {!!status && (
        <S.Status isRow={isRow}>
          <S.Label>Status:</S.Label>
          {status}
        </S.Status>
      )}
      <S.Employees isRow={isRow} isCompact={isCompactMode}>
        {employees.map((item) => (
          <EmployeeBadge
            key={item.id}
            name={`${item.firstName} ${item.lastName}`}
            phoneNumber={item.phoneNumber}
            isSmall={isCompactMode}
            imageUrl={item.logoUrl}
          />
        ))}
      </S.Employees>
      {isRow && (
        <S.DateBox isCompact={isCompactMode}>
          <S.Date>
            <S.DateIcon />
            {format(date, 'MMMM d, yyyy')}
          </S.Date>
          <div>{format(date, 'h:mm aaa')}</div>
        </S.DateBox>
      )}

      {invoice !== null && invoiceId !== null && type === appointmentBusinessTypes.job && (
        <S.InvoiceRedirectButton
          onClick={redirectToInvoice}
        >
          <S.InvoiceIcon />
          {isRow && 'Invoice Details'}
        </S.InvoiceRedirectButton>
      )}

      {type !== appointmentBusinessTypes.visit && (
        <S.Total isRow={isRow} isCompact={isCompactMode}>
          <S.Label isRow={isRow} isBold>Total:</S.Label>
          <Money amount={totalAmount} />
        </S.Total>
      )}
    </S.Box>
  );
}

AppointmentItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  unitNumber: PropTypes.string,
  date: PropTypes.instanceOf(Date),
  invoice: PropTypes.string,
  invoiceId: PropTypes.string,
  isCompact: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  employees: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalAmount: PropTypes.number.isRequired,
  variant: PropTypes.string,
  status: PropTypes.string,
  to: PropTypes.string,
};

AppointmentItem.defaultProps = {
  unitNumber: '',
  variant: 'row',
  date: null,
  invoice: null,
  invoiceId: null,
  status: null,
  to: null,
  isCompact: false,
};

export default memo(AppointmentItem);
