import { InfoBox, InfoBoxItem, InfoBoxGroup } from 'components/ui-kit/info-box';
import { weekdays } from 'helpers/datetime.helper';
import getPhoneNumber from 'helpers/getPhoneNumber.helper';
import { useApp } from 'hooks';
import * as S from './styles';

const daysOfWeek = Object.values(weekdays);

export default function CompanyDetails() {
  const { portal } = useApp();

  if (!portal) {
    return null;
  }

  const websiteLink = portal.website
    ? {
      href: portal.website,
      text: portal.website,
      color: 'blue',
    }
    : null;

  const phoneLink = {
    href: `tel:${portal.phoneNumber}`,
    text: getPhoneNumber(portal.phoneNumber),
    color: 'blue',
  };

  const workingSchedule = portal
    .workingSchedule
    ?.filter((item) => item.isWorkingDay)
    .map((item) => `${daysOfWeek[item.weekDay].label} ${item.start} - ${item.end}`);
  const companyLocation = portal?.company?.location;
  const location = companyLocation?.location;
  const unitNumber = companyLocation?.unitNumber ? `Unit #: ${companyLocation?.unitNumber}` : '';
  return (
    <S.Box>
      <InfoBoxGroup>
        <InfoBox title="Company Information">
          <InfoBoxItem title="Name" value={portal?.company?.businessName} />
          <InfoBoxItem
            title="Property"
            value={location}
            description={unitNumber}
          />
          <InfoBoxItem title="Email" value={portal?.email} />
          <InfoBoxItem title="Phone number" link={phoneLink} />
          <InfoBoxItem title="Website" link={websiteLink} />
        </InfoBox>
        {!!portal?.workingSchedule?.length && (
          <InfoBox title="Working days &amp; hours">
            {workingSchedule?.map((item, index) => (
              <div key={`day-${String(index)}`}>{item}</div>
            ))}
          </InfoBox>
        )}
      </InfoBoxGroup>
    </S.Box>
  );
}
