export const getDateTimeWithOffset = (date, offset) => {
  return new Date(new Date(date).getTime() + offset * 60000);
};

export const getTimezoneOffset = () => (new Date()).getTimezoneOffset();

export const defaultDateFormat = 'MMMM d, yyyy';
export const defaultDateWithTimeFormat = 'MMMM d, yyyy, h:mm aaa';

export const weekdays = {
  sunday: {
    value: 0,
    label: 'SUN',
  },
  monday: {
    value: 1,
    label: 'MON',
  },
  tuesday: {
    value: 2,
    label: 'TUE',
  },
  wednesday: {
    value: 3,
    label: 'WED',
  },
  thursday: {
    value: 4,
    label: 'THU',
  },
  friday: {
    value: 5,
    label: 'FRI',
  },
  saturday: {
    value: 6,
    label: 'SAT',
  },
};

function parseTimespan(timeSpan) {
  const parsed = timeSpan.split(':').map(Number);
  return {
    hours: parsed[0],
    minutes: parsed[1],
  };
}

export const getTimeIntervals = (start, end, intervalInMinutes) => {
  const results = [];
  let startDate = new Date();
  const endDate = new Date(startDate);

  const parsedStart = parseTimespan(start);
  const parsedEnd = parseTimespan(end);

  startDate.setHours(parsedStart.hours);
  startDate.setMinutes(parsedStart.minutes);
  endDate.setHours(parsedEnd.hours);
  endDate.setMinutes(parsedEnd.minutes);

  while (startDate < endDate) {
    const interval = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      startDate.getHours(),
      startDate.getMinutes() + intervalInMinutes,
      startDate.getSeconds(),
      startDate.getMilliseconds(),
    );
    if (interval === endDate) {
      break;
    }
    const newEnd = interval > endDate ? endDate : interval;
    let startString;
    let endString;
    const startMinutes = startDate.getMinutes();
    const endMinutes = newEnd.getMinutes();
    const startHours = startDate.getHours();
    const endHours = newEnd.getHours();
    if (startHours >= 12) {
      startString = `${startHours - (startHours === 12 ? 0 : 12)}${startMinutes > 0 ? `:${startMinutes}` : ''} pm`;
    } else {
      startString = `${startHours}${startMinutes > 0 ? `:${startMinutes}` : ''} am`;
    }
    if (endHours >= 12) {
      endString = `${endHours - (endHours === 12 ? 0 : 12)}${endMinutes > 0 ? `:${endMinutes}` : ''} pm`;
    } else {
      endString = `${endHours}${endMinutes > 0 ? `:${endMinutes}` : ''} am`;
    }
    results.push(`${startString} - ${endString}`);
    startDate = interval;
  }
  return results;
};

export const getDate = (value) => {
  const date = value ? new Date(value) : new Date();
  return new Date(date.setHours(0, 0, 0, 0));
};
