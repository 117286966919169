import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { companyColor, defaultColor, fluidFont } from 'styles/theme';

const textStyles = css`
  ${fluidFont('12px', '14px')};
  color: ${companyColor('font')};

  &:last-child {
    color: ${defaultColor('metallic')};
  }
`;
export const Box = styled.span`
  display: flex;
  align-items: center;
  gap: calc(1rem / 5);

  ${({ gap }) => (gap ? `margin-bottom: ${gap};` : '')}
`;

export const RouteItem = styled(Link)`
  ${textStyles}
  text-decoration: none;
`;

export const TextItem = styled.span`
  ${textStyles}
`;
