import styled, { css } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as NoMatchImage } from 'assets/404-image.svg';
import { ReactComponent as Rocket } from 'assets/rocket.svg';
import {
  font,
  fluidFont,
  companyColor,
} from 'styles/theme';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
  color: ${companyColor('font')};
  text-align: center;
`;

export const ErrorLabel = styled.div`
  text-transform: uppercase;
  font-family: ${font('regular')};
  ${fluidFont('22px', '24px')};
  color: inherit;
`;

export const Title = styled.h2`
  font-family: ${font('semibold')};
  ${fluidFont('30px', '32px')};
  color: inherit;
`;

export const Message = styled.p`
  font-family: ${font('regular')};
  ${fluidFont('16px', '18px')};
  color: inherit;
`;

const imageStyles = css`
  display: block;
  max-width: 100%;
`;

export const ImageLarge = styled(NoMatchImage)`
  ${imageStyles};
  margin-bottom: 4rem;
`;

export const ImageSmall = styled(Rocket)`
  ${imageStyles};
  margin-bottom: 2rem;
  width: 350px;
  height: 200px;
`;

export const Link = styled(RouterLink)`
  color: ${companyColor('main')};
`;
