import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Indicator } from 'components/ui-kit';

import { useApp } from 'hooks';
import routes from 'router/routes';

import navItems from './nav-items';

import * as S from './styles';

const Navigation = () => {
  const {
    isServicePlansAvailable,
    isBankAccountConnected,
    isPreviewMode,
    pendingEstimates,
  } = useApp();
  const location = useLocation();
  const initialPath = location.pathname.split('/')[isPreviewMode ? 3 : 2];
  const [activeRoutePath, setActiveRoutePath] = useState(initialPath);

  useEffect(() => {
    setActiveRoutePath(initialPath);
  }, [setActiveRoutePath, initialPath]);

  let availableNavItems = isServicePlansAvailable
    ? navItems
    : navItems.filter((item) => item.path !== routes.servicePlans.path);

  if (!isBankAccountConnected) {
    availableNavItems = availableNavItems.filter((item) => item.path !== routes.payments.path);
  }

  return (
    <nav>
      <S.List>
        {availableNavItems.map(({
          path,
          title,
          icon: Icon,
          isMobile,
        }, index) => {
          return (
            <S.NavItem
              key={path}
              index={index}
              className={activeRoutePath === `${path}` ? 'active' : null}
              isMobile={isMobile}
            >
              <S.NavLink to={path} onClick={isPreviewMode ? () => {} : () => setActiveRoutePath(`${path}`)}>
                <S.Icon>
                  <Icon />
                </S.Icon>

                <S.Text>
                  {title}
                </S.Text>

                {path === routes.requests.path && pendingEstimates > 0 && (
                  <S.IndicatorWrapper>
                    <Indicator value={pendingEstimates} color="red" ml={0} />
                  </S.IndicatorWrapper>
                )}
              </S.NavLink>
            </S.NavItem>
          );
        })}

        <S.Highlighter isHidden={initialPath === 'profile'} />
      </S.List>
    </nav>
  );
};

export default Navigation;
