import { bookingFormFieldType } from 'constants/appointments';

const buildFormikValueEntry = (field, answers, image) => {
  const { name, fieldType } = field;
  const isCheckbox = fieldType === bookingFormFieldType.checkbox;
  const isImage = fieldType === bookingFormFieldType.image;

  let value;

  if (isCheckbox) {
    value = answers || [];
  } else if (isImage) {
    value = image?.publicImageUrl || '';
  } else {
    value = (answers && answers[0]) || '';
  }

  return { [name]: value };
};

export const buildFormikValuesEntries = (questionsWithAnswers) => {
  const entries = questionsWithAnswers.map(({
    question, answer, image,
  }) => buildFormikValueEntry(question, answer, image));

  return entries.reduce((prev, curr) => ({ ...prev, ...curr }), {});
};
