import PropTypes from 'prop-types';
import Modal from 'components/ui-kit/modal';
import Button from 'components/ui-kit/buttons/button';
import { useNavigate } from 'react-router-dom';
import { payInvoiceWithSavedCard } from 'api';
import {
  useFields, useModal, useApp, useToast,
} from 'hooks';
import { Field, Select } from 'components/ui-kit/form';
import { buildCardSelectEntry } from 'helpers/business.helper';
import routes from 'router/routes';
import { useState } from 'react';
import * as S from './styles';

export default function InvoicePaymentOptionModal({
  cards,
  invoiceId,
}) {
  const navigate = useNavigate();
  const { portal } = useApp();
  const { fields, handleClickChange } = useFields({
    paymentMethodId: cards[0].methodId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const { addErrorToast } = useToast();

  const redirectToInvoice = () => navigate(`/${portal?.id}/${routes.invoices.path}/${invoiceId}?stripe_result=success`);

  const { hideModal } = useModal();

  const cardOptions = cards.map((card) => ({ value: card.methodId, label: buildCardSelectEntry(card) }));

  const handlePayment = async () => {
    try {
      setIsLoading(true);
      const { paymentMethodId } = fields;

      const { data: { errorMessage, isSuccess, skippedPayment } } = await payInvoiceWithSavedCard(portal?.id, {
        paymentMethodId,
        invoiceId,
      });

      if (skippedPayment) {
        navigate(`/${portal?.id}/${routes.invoices.path}/${invoiceId}`);
        return;
      }

      if (isSuccess) {
        redirectToInvoice();
      } else {
        addErrorToast(errorMessage || 'Something went wrong');
      }
    } finally {
      setIsLoading(false);
      hideModal();
    }
  };

  return (
    <Modal title="Select payment option" isClosable={false}>
      <S.Box>

        <Field
          name="paymentMethodId"
          label="Card"
          value={fields.paymentMethodId}
          onChange={handleClickChange}
          options={cardOptions}
          element={Select}
          fullWidth
        />

        <S.Text>This card will be charged</S.Text>

        <S.ButtonGroup>
          <Button
            isLoading={isLoading}
            onClick={hideModal}
            stretched
          >
            Cancel
          </Button>
          <Button
            isLoading={isLoading}
            color="primary"
            onClick={handlePayment}
            stretched
          >
            Proceed
          </Button>
        </S.ButtonGroup>
      </S.Box>
    </Modal>
  );
}

InvoicePaymentOptionModal.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  cards: PropTypes.arrayOf(PropTypes.shape({
    methodId: PropTypes.string.isRequired,
  })),
};

InvoicePaymentOptionModal.defaultProps = {
  cards: [],
};
