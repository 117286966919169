import React, { memo } from 'react';
import PropTypes from 'prop-types';

import PaidSection from 'components/paid-section';
import Typography from 'components/ui-kit/typography';
import Money from 'components/ui-kit/money';
import Stack from 'components/ui-kit/stack';
import Group from 'components/ui-kit//group';

import { calculateEntitySummary } from 'helpers/calculations.helper';

import SubtotalSection from './components/subtotal-section';
import LineItemsSection from './components/line-items-section';

import { COMMON_COLUMNS } from './utils';

import * as S from './styles';

const PaymentSummary = (props) => {
  const {
    title,
    lineItems,
    taxRate,
    discount,
    isPaid,
    setLineItems,
    withOptional,
    disabled,
    preCalculatedSummary,
  } = props;

  const {
    requiredLineItems,
    optionalLineItems,
    requiredSubtotal,
    optionalSubtotal,
    subtotalAmount,
    discountAmount,
    taxAmount,
    totalAmount,
  } = calculateEntitySummary({
    lineItems,
    taxRate,
    discount,
    preCalculatedSummary,
  });

  return (
    <Stack gap={24}>
      <Group justifyContent="space-between">
        <Typography as="div" fontFamily="semibold">{title}</Typography>

        {isPaid && <PaidSection justifyContent="end" />}
      </Group>

      {!!requiredLineItems.length && (
        <Stack>
          <LineItemsSection
            lineItems={lineItems}
            sectionLineItems={requiredLineItems}
            subtitle="Service / Material"
            columns={COMMON_COLUMNS}
          />

          {!!optionalLineItems.length && <SubtotalSection title="Total:" amount={requiredSubtotal} />}
        </Stack>
      )}

      {withOptional && !!optionalLineItems.length && (
        <Stack>
          <LineItemsSection
            lineItems={lineItems}
            sectionLineItems={optionalLineItems}
            subtitle="Options"
            columns={COMMON_COLUMNS}
            setLineItems={setLineItems}
            isOptional
            disabled={disabled}
          />

          {!!requiredLineItems.length && <SubtotalSection title="Total:" amount={optionalSubtotal} />}
        </Stack>
      )}

      <Stack alignItems="flex-end" gap={8}>
        <SubtotalSection title="Subtotal:" amount={subtotalAmount} />
        {!!discount && <SubtotalSection title={`Discount (${discount.name}):`} amount={-discountAmount} />}

        {!!taxRate && <SubtotalSection title={`Tax (${taxRate.name}):`} amount={taxAmount} />}
      </Stack>

      <S.Divider dark />

      <Group px={20} justifyContent="space-between">
        <Typography fontFamily="semibold" fontWeight={700}>TOTAL:</Typography>

        <Typography fontFamily="semibold" fontWeight={700}><Money amount={totalAmount} /></Typography>
      </Group>
    </Stack>
  );
};

PaymentSummary.propTypes = {
  title: PropTypes.string,
  lineItems: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  taxRate: PropTypes.shape({
    name: PropTypes.string,
  }),
  discount: PropTypes.shape({
    name: PropTypes.string,
  }),
  isPaid: PropTypes.bool,
  setLineItems: PropTypes.func,
  withOptional: PropTypes.bool,
  disabled: PropTypes.bool,
  preCalculatedSummary: PropTypes.shape({
    subtotalAmount: PropTypes.number,
    discountAmount: PropTypes.number,
    taxAmount: PropTypes.number,
    totalAmount: PropTypes.number,
  }),
};

PaymentSummary.defaultProps = {
  title: 'Payment Summary',
  taxRate: null,
  discount: null,
  isPaid: false,
  setLineItems: () => {},
  withOptional: false,
  disabled: false,
  preCalculatedSummary: {},
};

export default memo(PaymentSummary);
