import styled from 'styled-components';
import {
  companyColor,
  defaultColor,
  font,
  fluidFont,
} from 'styles/theme';

export const SubTitle = styled.div`
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  ${fluidFont('13px', '15px')};
`;

export const PhoneLink = styled.a`
  gap: 10px;
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  text-decoration: none;
  color: ${defaultColor('blue')};
  &:hover {
    text-decoration: underline;
  }`;

export const AreaBlock = styled.div`
  min-height: 140px;
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const Text = styled.span`
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
`;

export const TextSilver = styled(Text)`
  color: ${defaultColor('metallic')};
`;

export const SubmitButton = styled.div`
  width: 150px;
  margin-top: 20px;
`;

export const Content = styled.div`
  margin-top: 20px;
  color: ${companyColor('font')};
`;
