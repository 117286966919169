import styled from 'styled-components';

import Box from '../box';

export const FlexBox = styled(Box)`
  display: flex;
  flex-direction: ${({ direction }) => (direction ? `${direction};` : 'row')};
  justify-content: ${({ justifyContent }) => justifyContent || 'initial'};
  justify-items: ${({ justifyItems }) => justifyItems || 'initial'};
  align-items: ${({ alignItems }) => alignItems || 'initial'};
  gap: ${({ gap }) => (gap ? `${gap};` : '0')};
`;
