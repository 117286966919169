import PropTypes from 'prop-types';
import Indicator from 'components/ui-kit/indicator';
import * as S from '../styles';

export default function ButtonLink({
  href,
  to,
  variant,
  size,
  children,
  color,
  icon,
  indicator,
  stretched,
  disabled,
  newTab,
}) {
  const withIcon = !!icon;
  const WrapperComponent = href ? S.NativeLink : S.RouterLink;
  const linkProps = href ? { href, newTab } : { to };

  return (
    <WrapperComponent
      {...linkProps}
      disabled={disabled}
      variant={variant}
      size={size}
      color={color}
      $stretched={stretched}
      $withIcon={withIcon}
    >
      {withIcon && icon}
      {children}
      {!!indicator && (
        <Indicator value={indicator.value} color={indicator.color} />
      )}
    </WrapperComponent>
  );
}

ButtonLink.defaultProps = {
  href: null,
  to: '/',
  disabled: false,
  variant: 'contained',
  size: 'normal',
  color: 'secondary',
  icon: null,
  indicator: null,
  stretched: false,
  newTab: false,
  onClick: () => {},
};

ButtonLink.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  newTab: PropTypes.bool,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['contained', 'text']),
  color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'success']),
  size: PropTypes.string,
  disabled: PropTypes.bool,
  stretched: PropTypes.bool,
  icon: PropTypes.node,
  indicator: PropTypes.shape({
    color: PropTypes.oneOf(['red', 'default']),
    value: PropTypes.number,
  }),
};
