import NextAppointments from 'components/appointments/next-appointments';
import ProfileButton from '../profile-button';
import * as S from './styles';

export default function Aside() {
  return (
    <S.Box>
      <ProfileButton />
      <NextAppointments />
    </S.Box>
  );
}
