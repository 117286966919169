import styled from 'styled-components';

import { spacing } from 'styles/theme';

export const SubtotalLineBox = styled.div`
  align-self: end;
  display: flex;
  gap: ${spacing('small')};
  padding-right: calc(1.25rem + 1px);
`;
