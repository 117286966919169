import styled, { keyframes } from 'styled-components';
import { defaultColor as getColor } from 'styles/theme';

const rotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border-radius: 50%;
  border-width: ${({ borderWidth }) => `${borderWidth}px`};
  border-style: solid;
  border-color: ${({ color }) => getColor(color)};
  border-top-color: ${({ color }) => getColor(color, 0.2)};
  border-right-color: ${({ color }) => getColor(color, 0.2)};
  height: ${({ size }) => `${size}px`};
  width: ${({ size }) => `${size}px`};
  animation: ${rotation} 1s linear infinite;
`;
