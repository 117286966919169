import PropTypes from 'prop-types';
import * as S from './styles';

export default function InfoBoxItem({
  title,
  value,
  description,
  link,
  showColon,
  valueColor,
  statusColor,
}) {
  if (!(!!value || !!link)) {
    return null;
  }
  const itemTitle = showColon ? `${title}:` : title;

  return (
    <S.Item>
      <S.ItemTitle>{itemTitle}</S.ItemTitle>
      <S.ItemValue
        $valueColor={valueColor}
        $statusColor={statusColor}
      >
        {value}
      </S.ItemValue>
      {description && <S.ItemValue>{description}</S.ItemValue>}
      {link && <S.ItemLink href={link.href} color={link.color}>{link.text}</S.ItemLink>}
    </S.Item>
  );
}

InfoBoxItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  valueColor: PropTypes.string,
  statusColor: PropTypes.string,
  showColon: PropTypes.bool,
  description: PropTypes.string,
  link: PropTypes.shape({
    href: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    color: PropTypes.string,
  }),
};

InfoBoxItem.defaultProps = {
  value: null,
  description: null,
  link: null,
  showColon: true,
  valueColor: null,
  statusColor: null,
};
