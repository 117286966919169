import ContentLoader from 'react-content-loader';

export default function InvoiceItemLoader() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="900px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* Breadcrumbs */}
      <rect x="0" y="0" rx="3" ry="3" width="30%" height="15px" />

      {/* Page Header */}
      <rect x="0" y="32" rx="3" ry="3" width="40%" height="40px" />
      <rect x="75%" y="32" rx="3" ry="3" width="25%" height="40px" />

      {/* Invoice Number and amount */}
      <rect x="16" y="120" rx="3" ry="3" width="40%" height="30px" />
      <rect x="16" y="170" rx="3" ry="3" width="20%" height="60px" />

      {/* Company Info */}
      <rect x="70%" y="120" rx="3" ry="3" width="28%" height="100px" />

      {/* Divider */}
      <rect x="16" y="255" rx="3" ry="3" width="96%" height="1px" />

      {/* Billed to */}
      <rect x="16" y="280" rx="3" ry="3" width="40%" height="100px" />

      {/* Dates */}
      <rect x="60%" y="280" rx="3" ry="3" width="38%" height="18px" />
      <rect x="60%" y="304" rx="3" ry="3" width="38%" height="18px" />

      {/* Number */}
      <rect x="16" y="400" rx="3" ry="3" width="30%" height="60px" />

      {/* Invoice message */}
      <rect x="32" y="484" rx="3" ry="3" width="90%" height="120px" />

      {/* Summary */}
      <rect x="16" y="626" rx="3" ry="3" width="35%" height="30px" />

      {/* Line Item */}
      <rect x="16" y="676" rx="3" ry="3" width="20%" height="30px" />
      <rect x="16" y="716" rx="3" ry="3" width="96%" height="30px" />
      <rect x="16" y="756" rx="3" ry="3" width="96%" height="30px" />
    </ContentLoader>
  );
}
