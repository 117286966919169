import PropTypes from 'prop-types';
import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule.svg';
import { servicePlanSubscriptionStatuses } from 'helpers/enums';
import routes from 'router/routes';
import { useParams } from 'react-router-dom';
import DateLine from 'components/ui-kit/date-line';
import { defaultDateFormat } from 'helpers/datetime.helper';
import * as S from './styles';

const statuses = Object.values(servicePlanSubscriptionStatuses);

const getColor = (status) => {
  switch (String(status)) {
    case servicePlanSubscriptionStatuses.active.value: return 'green';
    case servicePlanSubscriptionStatuses.pending.value: return 'blue';
    case servicePlanSubscriptionStatuses.cancelled.value: return 'red';
    default: return 'red';
  }
};

const getStatusName = (status) => (statuses[status].label);

function ItemHeader({ name, status, color }) {
  return (
    <S.Header>
      <S.Name>
        {name}
      </S.Name>
      <S.Status color={color}>
        {status}
      </S.Status>
    </S.Header>
  );
}

ItemHeader.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default function ServicePlanItem({
  id,
  name,
  status,
  visitsPerYear,
  startDate,
  endDate,
}) {
  const { portalId } = useParams();
  const path = `/${portalId}/${routes.servicePlans.path}/${id}`;

  return (
    <S.Box to={path} title={name}>
      <ItemHeader name={name} status={getStatusName(status)} color={getColor(status)} />
      <DateLine
        label="Start Date"
        date={startDate}
        format={defaultDateFormat}
      />
      <DateLine
        label="End Date"
        date={endDate}
        placeholder="Until canceled"
        format={defaultDateFormat}
      />
      <S.VisitsPerYear>
        <S.VisitsIcon>
          <ScheduleIcon />
        </S.VisitsIcon>
        <span>
          {visitsPerYear}
          {' '}
          visits per year
        </span>
      </S.VisitsPerYear>
    </S.Box>
  );
}

ServicePlanItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
  visitsPerYear: PropTypes.number.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string,
};

ServicePlanItem.defaultProps = {
  endDate: null,
};
