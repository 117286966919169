import styled, { css } from 'styled-components';

import ButtonComponent from 'components/ui-kit/buttons/button';
import { ReactComponent as LogoSVG } from 'assets/icons/image/logo.svg';

import {
  spacing,
  defaultColor,
  font,
  fluidFont,
  getDefaultColor,
} from 'styles/theme';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${spacing('default')};

  & .react-formik-wizard__step-tabs {
    width: 100%;
    left: 0;
    padding: 20px 0px;
  }

  & .react-formik-wizard__step-tabs__tab-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 12px;
    width: 100%;
  }

  & .react-formik-wizard__step-tabs__tab-list > li {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    align-items: start;
    font-family: ${font('regular')};
    font-size: 16px;
    color: ${defaultColor('dark')};
    font-weight: bold;
    text-align: left;

    display: flex;
    flex-direction: column-reverse;
  }

  & .react-formik-wizard__step-tabs__tab-list > li.is-active ~ li {
    color: #dbdbdb;
  }

  & .react-formik-wizard__step-tabs__tab-list::before {
    height: 0;
  }

  & .react-formik-wizard__step-tabs__tab-list > li::before {
    width: 100%;
    height: 8px;
    margin: 0;
    font-size: 0;
    border-radius: 4px;
    margin-bottom: 0px;
    background: ${defaultColor('dark')};
    border: none;
  }

  & .react-formik-wizard__step-tabs__tab-list > li::after {
    height: 0;
  }
`;

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${spacing('default')};
`;

export const Label = styled.label`
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  color: ${defaultColor('dark')};
  margin-bottom: -10px;
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  margin: 20px;
`;

export const Text = styled.span`
  font-family: ${font('regular')};
  font-size: 14px;
  color: ${defaultColor('dark')};
`;

export const LogoImage = styled(LogoSVG)`
  width: 161px;
  height: 23px;
  margin-bottom: 4px;
`;

export const Button = styled.div`
  min-width: 82.5px;
`;

export const StyledNext = styled(ButtonComponent)`
  ${({ disabled }) => {
    if (disabled) {
      return css`
        color: ${getDefaultColor('dark')};
        background-color: ${getDefaultColor('backgroundLight')};
        opacity: 0.5;
      `;
    }

    return '';
  }}
`;
