import styled from 'styled-components';
import { defaultColor, device } from 'styles/theme';

export const Box = styled.aside`
  border-left: 1px solid ${defaultColor('backgroundLight')};
  padding: 64px 40px;
  grid-area: aside;
  display: none;

  @media(${device('laptop')}) {
    display: block;
  }
`;
