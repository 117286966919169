import routes from 'router/routes';

import {
  ScheduleIcon,
  MyMoneyIcon,
  PayIcon,
  VehicleIcon,
  ServicePlanIcon,
  CustomersIcon,
  BoxIcon,
} from 'assets/icons';

const navItems = [
  {
    title: 'Appointments',
    path: routes.appointments.path,
    icon: ScheduleIcon,
  },
  {
    title: 'Estimates',
    path: routes.requests.path,
    icon: BoxIcon,
  },
  {
    title: 'Invoices',
    path: routes.invoices.path,
    icon: MyMoneyIcon,
  },
  {
    title: 'Payments',
    path: routes.payments.path,
    icon: PayIcon,
  },
  {
    title: 'Service Plans',
    path: routes.servicePlans.path,
    icon: ServicePlanIcon,
  },
  {
    title: 'Company',
    path: routes.company.path,
    icon: VehicleIcon,
  },
  {
    title: 'Profile',
    path: 'profile',
    icon: CustomersIcon,
    isMobile: true,
  },
];

export default navItems;
