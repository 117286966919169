import PropTypes from 'prop-types';

import Modal from 'components/ui-kit/modal';
import Button from 'components/ui-kit/buttons/button';
import { ReactComponent as CalendarIcon } from 'assets/icons/calendar.svg';

import { useModal } from 'hooks';

import * as S from './styles';

export default function RequestFeedbackModal({ companyName }) {
  const { hideModal } = useModal();
  return (
    <Modal showCloseButton>
      <S.FeedbackBox>
        <S.IconBackground>
          <CalendarIcon />
        </S.IconBackground>

        <b>Thanks a lot for your feedback</b>

        <p>Your answer has been received and will be reviewed by our team. We appreciate your assistance.</p>

        <p>
          Best Regards,&nbsp;
          <b>{companyName}</b>
        </p>

        <Button color="primary" stretched onClick={hideModal}>Got it</Button>
      </S.FeedbackBox>
    </Modal>
  );
}

RequestFeedbackModal.propTypes = {
  companyName: PropTypes.string.isRequired,
};
