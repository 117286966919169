import { Outlet } from 'react-router-dom';
import NavSwitcher from 'components/ui-kit/nav-switcher';
import PageHeader from 'components/ui-kit/page-header';

const switcherList = [
  {
    label: 'Company Details',
    path: '',
  },
  {
    label: 'Contact Support',
    path: 'help-center',
  },
];

export default function Company() {
  return (
    <>
      <PageHeader title="Company" />
      <NavSwitcher items={switcherList} />
      <main>
        <Outlet />
      </main>
    </>
  );
}
