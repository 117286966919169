import { useState } from 'react';
import { useSpring } from '@react-spring/web';
import PropTypes from 'prop-types';
import { useToast } from 'hooks';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/error.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import * as S from './styles';

const TOAST_SHOW_TIME_MS = 5000;

export default function Toast({
  children,
  id,
  style,
  isErrorMessage,
  duration,
}) {
  const [isHover, setIsHover] = useState(false);

  const { removeToast } = useToast();
  const progress = useSpring({
    value: duration,
    from: { value: duration },
    to: { value: 0 },
    config: {
      duration,
    },
    onRest: () => removeToast(id),
  });

  const pauseAllAnimations = () => {
    style.right.pause();
    progress.value.pause();
  };

  const resumeAllAnimations = () => {
    style.right.resume();
    progress.value.resume();
  };

  const onMouseOver = () => {
    if (!isHover) {
      setIsHover(true);
      pauseAllAnimations();
    }
  };

  const onMouseLeave = () => {
    resumeAllAnimations();
    setIsHover(false);
  };

  const closeToast = () => {
    removeToast(id);
    resumeAllAnimations();
  };

  return (
    <S.ToastBox
      style={style}
      className={isErrorMessage ? 'error' : null}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <S.CloseButton onClick={closeToast}>
        <PlusIcon />
      </S.CloseButton>
      <S.ToastIcon>
        {isErrorMessage ? <ErrorIcon /> : <SuccessIcon />}
      </S.ToastIcon>
      <div>
        {children}
      </div>
      <S.ProgressBar isErrorMessage={isErrorMessage} value={progress.value} max={duration} />
    </S.ToastBox>
  );
}

Toast.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]).isRequired,
  id: PropTypes.string.isRequired,
  isErrorMessage: PropTypes.bool,
  duration: PropTypes.number,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object.isRequired,
};

Toast.defaultProps = {
  isErrorMessage: false,
  duration: TOAST_SHOW_TIME_MS,
};
