import styled from 'styled-components';
import {
  font,
  defaultColor as color,
  radius,
} from 'styles/theme';
import { ReactComponent as Check } from 'assets/icons/check.svg';

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: ${(props) => (props.gap ? '16px' : '0')};
  font: ${font('regular.landing')};
  color: ${color('primary')};
  position: relative;
  cursor: pointer;
  
  &[disabled] {
    color: ${color('grey')};
  }
`;

export const CheckIcon = styled(Check)`
  color: ${color('white')};
  border: 1px solid ${({ error }) => (error ? color('red') : color('dark'))};
  border-radius: ${radius('small')};
  outline: none;
  stroke-dasharray: 35;
  stroke-dashoffset:  ${({ value }) => (value ? '0' : '-35')};
  transition: stroke-dashoffset 0.19s ease-out;
  margin-right: 12px;
  background: ${({ value }) => (value ? color('dark') : 'none')};
  width: ${({ size }) => (size)}px;
  ${Label}[disabled] & {
    opacity: 0.5;
  }
`;

export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;
