import * as yup from 'yup';
import { isValidNumberForRegion } from 'libphonenumber-js';

import config from 'config';

const names = {
  phoneNumber: 'phoneNumber',
};

yup.addMethod(yup.string, names.phoneNumber, function phoneNumber(customMessage) {
  const message = customMessage || 'Phone number must be valid';

  return this.test(names.phoneNumber, message, (number) => {
    if (!number || number === '+375' || number === '+1') {
      return true;
    }

    const { regions } = config.phoneNumberRegionSettings;

    return regions.some((region) => isValidNumberForRegion(number, region.toUpperCase()));
  });
});
