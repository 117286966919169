export const servicePlanSubscriptionStatuses = {
  pending: {
    label: 'Pending',
    value: '0',
  },
  active: {
    label: 'Active',
    value: '1',
  },
  expiringSoon: {
    label: 'Expiring soon',
    value: '2',
  },
  expired: {
    label: 'Expired',
    value: '3',
  },
  cancelled: {
    label: 'Cancelled',
    value: '4',
  },
};

export const invoicePaymentStatuses = {
  notPaid: {
    label: 'Not paid',
    value: 0,
  },
  paid: {
    label: 'Paid',
    value: 1,
  },
  pending: {
    label: 'Pending',
    value: 2,
  },
  failed: {
    label: 'Failed',
    value: 3,
  },
  uncollectible: {
    label: 'Uncollectible',
    value: 4,
  },
};

export const invoicePaymentMethodTypes = {
  cash: {
    label: 'Cash',
    value: 0,
  },
  check: {
    label: 'Check',
    value: 1,
  },
  card: {
    label: 'Card',
    value: 2,
  },
  other: {
    label: 'Other',
    value: 3,
  },
};

export const lineItemTypes = {
  material: {
    label: 'Material',
    value: 0,
  },
  service: {
    label: 'Service',
    value: 1,
  },
};

export const discountTypes = {
  fixed: '0',
  percent: '1',
};

export const estimateApprovalTypes = {
  notYetApproved: {
    label: 'Pending',
    value: 0,
    color: 'blue',
  },
  approved: {
    label: 'Approved',
    value: 1,
    color: 'green',
  },
  declined: {
    label: 'Declined',
    value: 2,
    color: 'red',
  },
};

export const appointmentInvoiceStatuses = {
  invoiceNotSent: 0,
  invoiceSent: 1,
  paid: 2,
};
