import styled from 'styled-components';
import { hollyAlbatros } from 'styles/theme';

const InfoBoxGroup = styled.div`
  display: grid;
  ${hollyAlbatros(2, '1.25rem')};
  gap: 1.25rem;
`;

export default InfoBoxGroup;
