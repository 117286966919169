import PropTypes from 'prop-types';

import { useModal } from 'hooks';

import Button from '../button';

import ConfirmationModal from './modal';

const ConfirmationButton = ({
  children,
  onClick,
  onConfirm,
  afterConfirm,
  onCancel,
  buttonTitle,
  modalTitle,
  modalContent,
  ...restProps
}) => {
  const { showModal } = useModal();

  return (
    <Button
      {...restProps}
      onClick={() => showModal(ConfirmationModal, {
        buttonTitle,
        modalTitle,
        modalContent,
        onConfirm,
        afterConfirm,
        onCancel,
      })}
    >
      {children}
    </Button>
  );
};

ConfirmationButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  onConfirm: PropTypes.func,
  afterConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  buttonTitle: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.node.isRequired,
};

ConfirmationButton.defaultProps = {
  onClick: () => {},
  onConfirm: () => {},
  afterConfirm: () => {},
  onCancel: () => {},
  buttonTitle: 'Confirm',
  modalTitle: 'Confirmation',
};

export default ConfirmationButton;
