import styled from 'styled-components';

import { ReactComponent as IconCamera } from 'assets/icons/camera.svg';

import { getDefaultColor } from 'styles/theme';

export const Card = styled.div`
  width: 100%;
  padding: 16px;
  border: 1px solid ${getDefaultColor('neutral300')};
  border-color: ${({ isSelected }) => (isSelected ? getDefaultColor('dark') : getDefaultColor('neutral300'))};
  border-radius: 8px;

  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 8px;

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.02);
  background-color: ${({ isSelected }) => (isSelected ? getDefaultColor('background') : getDefaultColor('neutral100'))};

  cursor: pointer;

  &:hover {
    background-color: ${getDefaultColor('background')};
  }
`;

export const ImageContainer = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 8px;
  flex-shrink: 0;
  overflow: hidden;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const EmptyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
`;

export const UploadIcon = styled(IconCamera)`
  width: 24px;
  height: 24px;
  stroke: ${getDefaultColor('neutral500')};
`;
