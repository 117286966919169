import { useState, memo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import { loadStripe } from '@stripe/stripe-js';
import { getCards, getInvoicePaymentSession } from 'api';
import Button from 'components/ui-kit/buttons/button';
import config from 'config';
import { useApp, useModal, useToast } from 'hooks';
import InvoicePaymentOptionModal from '../invoice-payment-options-modal';

const getCustomerCards = async (portalId) => {
  const result = await getCards({ queryKey: [0, portalId] });
  return result.data;
};

function InvoicePayButton({
  invoiceId, portalId, ...restProps
}) {
  const [isButtonLoading, setButtonLoading] = useState(false);
  const { portal } = useApp();
  const { addErrorToast } = useToast();
  const { showModal } = useModal();
  const queryClient = useQueryClient();

  const { refetch: getPaymentSession } = useQuery(
    [`invoice-${invoiceId}-session`, portalId, invoiceId],
    getInvoicePaymentSession,
    {
      enabled: false,
    },
  );

  const proceedWithSession = async () => {
    const fetchResult = await getPaymentSession();
    if (fetchResult.isError) {
      addErrorToast(fetchResult.error.response.data || 'Something went wrong');
      setButtonLoading(false);
      return;
    }

    const response = fetchResult.data.data;
    if (response.skippedPayment) {
      queryClient.invalidateQueries('invoicesListingQuery');
      queryClient.invalidateQueries(`invoice-${invoiceId}`);
      return;
    }

    const { sessionId, connectedAccountId } = response;

    if (sessionId && connectedAccountId) {
      const stripePromise = loadStripe(config.stripePublicKey, {
        stripeAccount: connectedAccountId,
      });
      const stripe = await stripePromise;

      await stripe.redirectToCheckout({
        sessionId,
      });
    }

    if (!connectedAccountId) {
      addErrorToast('Company did not connect bank account');
    }
  };

  const handleButtonClick = async (event) => {
    if (!portalId || !invoiceId) {
      addErrorToast('Something went wrong');
      return;
    }

    event.stopPropagation();
    event.preventDefault();

    setButtonLoading(true);

    const customerCards = await getCustomerCards(portal?.id);
    const hasCards = !!customerCards.length;
    if (hasCards) {
      showModal(InvoicePaymentOptionModal, { cards: customerCards, invoiceId });
    } else {
      proceedWithSession();
    }

    setButtonLoading(false);
  };

  return (
    <Button
      {...restProps}
      onClick={handleButtonClick}
      isLoading={isButtonLoading}
    >
      Pay Invoice
    </Button>
  );
}

InvoicePayButton.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  portalId: PropTypes.string.isRequired,

};

export default memo(InvoicePayButton);
