import React, { useRef, useState } from 'react';
import { useField } from 'formik';
import heic2any from 'heic2any';
import PropTypes from 'prop-types';

import { useViewAttachmentModal } from 'components/view-attachment-modal/context';
import { useModal as useSecondaryModal } from 'components/secondary-modal/context/secondaryModalContext';
import ConfirmationModal from 'components/ui-kit/buttons/confirmation-button/modal';
import Tooltip from 'components/ui-kit/tooltip';

import { useToast } from 'hooks';
import { convertMegabytesToBytes } from 'helpers/file.helper';

import {
  Container,
  ImageWrapper,
  UploadPlaceholder,
  HoverOverlay,
  IconButton,
  TrashIcon,
  ShowIcon,
  UploadIcon,
  TextWrapper,
  EmptyContainer,
} from './styles';

const ACCEPTED_IMAGE_FORMATS = 'image/jpeg, image/jpg, image/png, image/heic, image/heif';

const MAX_SIZE = convertMegabytesToBytes(10);

const getPreview = (isEdit, value) => {
  if (isEdit && typeof value === 'string') {
    return value;
  }

  return value ? URL.createObjectURL(value) : null;
};

const SimplePhotoUploader = ({
  fieldName,
  disabled,
  size,
  isEdit,
}) => {
  const [field, , helpers] = useField(fieldName);
  const { value } = field;
  const { setValue } = helpers;
  const { openViewAttachmentModal } = useViewAttachmentModal();
  const fileInputRef = useRef(null);
  const { showModal } = useSecondaryModal();
  const { showError } = useToast();
  const [preview, setPreview] = useState(getPreview(isEdit, value));
  const handleRemoveImage = () => {
    if (!disabled) {
      showModal(ConfirmationModal, {
        modalTitle: 'Delete Image',
        modalContent: 'Are you sure you want to delete this image?',
        buttonTitle: 'Delete',
        buttonColor: 'danger',
        isSecondary: true,
        onConfirm: () => {
          setValue(null);
          setPreview(null);

          if (fileInputRef.current) {
            fileInputRef.current.value = '';
          }
        },
      });
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > MAX_SIZE) {
        showError('The file size cannot exceed 10 MB.');
        return;
      }

      let imageFile = file;

      if (file.type === 'image/heic' || file.type === 'image/heif') {
        const convertedBlob = await heic2any({ blob: file, toType: 'image/png' });

        imageFile = new File([convertedBlob], file.name.replace(/\..+$/, '.png'), {
          type: 'image/png',
        });
      }

      setValue(imageFile);
      setPreview(URL.createObjectURL(imageFile));
    }
  };

  const handleClickUpload = () => {
    if (!disabled) {
      fileInputRef.current.click();
    }
  };

  const handlePreview = () => {
    if (preview) {
      openViewAttachmentModal({
        imageUrl: preview,
      });
    }
  };

  return (
    <Container size={size}>
      {preview ? (
        <ImageWrapper>
          <img src={preview} alt="Preview" />

          <HoverOverlay>
            <IconButton type="button" onClick={handlePreview}>
              <Tooltip text="View" icon={<ShowIcon />} />
            </IconButton>

            {!disabled && (
              <IconButton type="button" onClick={handleRemoveImage}>
                <Tooltip text="Delete" icon={<TrashIcon />} />
              </IconButton>
            )}
          </HoverOverlay>
        </ImageWrapper>
      ) : (
        <EmptyContainer onClick={handleClickUpload}>
          <UploadPlaceholder>
            <UploadIcon />

            <TextWrapper>Upload Image</TextWrapper>
          </UploadPlaceholder>
        </EmptyContainer>
      )}
      <input
        ref={fileInputRef}
        type="file"
        accept={ACCEPTED_IMAGE_FORMATS}
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
    </Container>
  );
};

SimplePhotoUploader.propTypes = {
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  isEdit: PropTypes.bool,
};

SimplePhotoUploader.defaultProps = {
  disabled: false,
  size: '100px',
  isEdit: false,
};

export default SimplePhotoUploader;
