import axios from 'axios';
import { stringify } from 'qs';
import config from 'config';

const { apiUrl } = config;

const querySerializerParameters = { arrayFormat: 'indices', allowDots: true };

export default axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  paramsSerializer: (params) => stringify(params, querySerializerParameters),
});
