import styled, { css } from 'styled-components';
import {
  spacing, radius, defaultColor, companyColor, font, fluidFont,
} from 'styles/theme';

export const FormControlStlyes = css`
  width: 100%;
  height: 50px;
  border-radius: ${radius('small')};
  border: 1px solid ${defaultColor('background')};
  font-family: ${font('regular')};
  color: ${companyColor('font')};
  outline: none;
  padding: 1.5rem 1rem 0.5rem 1rem;
`;

export const FormWrapper = styled.div`
  display: grid;
  gap: ${spacing('default')};
  grid-template:
    'number number'
    'expiry cvc'
    'button button';

  grid-template-columns: 1fr 1fr;
`;

export const GridWrapper = styled.div`
  grid-area: ${({ gridArea }) => gridArea};
`;

export const StripeFieldBox = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
`;

export const StripeInput = styled.div`
  ${FormControlStlyes};
`;

export const Label = styled.label`
  color: ${defaultColor('metallic')};
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  position: absolute;
  left: 1rem;
  top: 15px;
  width: 100%;
  transform: translateY(-50%) scale(1);
  transition: transform 190ms ease-in-out;

  &.focused {
    transform: translateY(-100%) scale(0.85) translateX(-0.4rem);
  }
`;
