import styled from 'styled-components';
import { spacing } from 'styles/theme';

export const Group = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  justify-items: ${({ justifyItems }) => justifyItems};
  align-items: ${({ alignItems }) => alignItems};
  flex-direction: ${({ flexDirection }) => flexDirection};
  gap: ${spacing('default')};
  ${({ topGap }) => (topGap === 'initial' ? '' : `margin-top: ${topGap}`)};
`;
