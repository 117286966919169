import { getPortalPreview } from 'api';
import Spinner from 'components/ui-kit/spinner';
import { useApp } from 'hooks';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { Outlet, useSearchParams } from 'react-router-dom';

const paramsHasColors = (params) => {
  return params.has('main') && params.has('background') && params.has('font');
};

const extractColors = (params) => {
  return {
    main: params.get('main'),
    background: params.get('background'),
    font: params.get('font'),
  };
};

export default function PreviewRoute() {
  const {
    setAppData,
    setIsPreviewMode,
    setColorTheme,
    isPreviewMode,
  } = useApp();
  const portalId = window.location.pathname.split('/')[1];
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isSuccess, isLoading } = useQuery(
    ['portalPreview', portalId],
    getPortalPreview,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: true,
    },
  );

  useEffect(() => {
    if (paramsHasColors(searchParams)) {
      const colors = extractColors(searchParams);
      setColorTheme(colors);
      setSearchParams({});
      setIsPreviewMode(true);
    }
  // because we should check it once page is loaded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      if (!paramsHasColors(searchParams) && !isPreviewMode) {
        setColorTheme(data?.data?.portal?.colors);
        setAppData(data?.data);
      } else {
        setAppData(data?.data);
      }
      setIsPreviewMode(true);
    }
  }, [
    data?.data,
    isSuccess,
    setAppData,
    setIsPreviewMode,
    setColorTheme,
    searchParams,
    isPreviewMode,
  ]);

  return (
    (isLoading || !isSuccess) ? <Spinner /> : <Outlet />
  );
}
