import {
  Outlet,
  useNavigate,
  useLocation,
  useSearchParams,
} from 'react-router-dom';
import { useQuery } from 'react-query';
import { login, getCurrent } from 'api';
import { useApp } from 'hooks';
import Spinner from 'components/ui-kit/spinner';

export default function CheckinRoute() {
  const { setAppData, setColorTheme } = useApp();
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const token = searchParams.get('token');
  const portalId = pathname.split('/')[1];
  const navigate = useNavigate();

  const {
    isLoading,
  } = useQuery(['login', portalId, token], login, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!token,
    onSuccess: (r) => {
      setAppData(r?.data);
      setColorTheme(r?.data?.portal?.colors);

      searchParams.delete('token');
      setSearchParams(searchParams);
    },
    onError: () => {
      if (portalId) {
        navigate(`${portalId}/login`, { state: { portalId } });
      }
    },
  });

  // fethcing current user for case with no token in query
  const {
    isLoading: isCurrentUserLoading,
  } = useQuery(['current', portalId], getCurrent, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !token,
    onSuccess: (r) => {
      if (r?.status === 200) {
        setAppData(r?.data);
        setColorTheme(r?.data?.portal.colors);
      }

      if (r?.status === 204) {
        navigate('login', { state: { portalId } });
      }
    },
  });

  const isPageLoading = isLoading || isCurrentUserLoading;

  if (isPageLoading) {
    return <Spinner />;
  }

  return <Outlet />;
}
