import React from 'react';
import PropTypes from 'prop-types';

import { toPixels } from 'styles/theme';

import { SBox } from './style';

function Box({
  children,
  h,
  mah,
  mih,
  w,
  miw,
  maw,
  m,
  mt,
  mb,
  ml,
  mr,
  mx,
  my,
  p,
  pt,
  pb,
  pl,
  pr,
  py,
  px,
  r,
  bgColor,
  ...rest
}) {
  const propsWithPx = {
    h: toPixels(h),
    mah: toPixels(mah),
    mih: toPixels(mih),
    w: toPixels(w),
    miw: toPixels(miw),
    maw: toPixels(maw),
    m: toPixels(m),
    mt: toPixels(mt),
    mb: toPixels(mb),
    ml: toPixels(ml),
    mr: toPixels(mr),
    mx: toPixels(mx),
    my: toPixels(my),
    p: toPixels(p),
    pt: toPixels(pt),
    pb: toPixels(pb),
    pl: toPixels(pl),
    pr: toPixels(pr),
    py: toPixels(py),
    px: toPixels(px),
    r: toPixels(r),
    bgColor,
    ...rest,
  };

  Object.keys(propsWithPx).forEach(
    (key) => propsWithPx[key] === undefined && delete propsWithPx[key],
  );

  return <SBox {...propsWithPx}>{children}</SBox>;
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  h: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mah: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mih: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  miw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  my: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  r: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bgColor: PropTypes.string,
};

Box.defaultProps = {
  h: 'auto',
  mih: 'auto',
  mah: 'none',
  w: 'auto',
  miw: 'auto',
  maw: 'none',
  m: undefined,
  mt: undefined,
  mb: undefined,
  ml: undefined,
  mr: undefined,
  mx: undefined,
  my: undefined,
  p: undefined,
  pt: undefined,
  pb: undefined,
  pl: undefined,
  pr: undefined,
  px: undefined,
  py: undefined,
  r: 0,
  bgColor: 'transparent',
};

export default Box;
