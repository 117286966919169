import ContentLoader from 'react-content-loader';
import theme from 'styles/theme';

function NextAppointmentsLoader() {
  return (
    <ContentLoader
      speed={2}
      backgroundColor={theme.defaultColors.background}
      foregroundColor={theme.defaultColors.backgroundLight}
      height="182px"
    >
      <rect x="0" y="0" rx="3" ry="3" width="100%" height="50px" />
      <rect x="0" y="66" rx="3" ry="3" width="100%" height="50px" />
      <rect x="0" y="132" rx="3" ry="3" width="100%" height="50px" />
    </ContentLoader>
  );
}

export default NextAppointmentsLoader;
