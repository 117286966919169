import { css } from 'styled-components';
import { themeVar, colorThemeVar, viewports } from './utils';

export const COMPANY_COLOR_KEYS = {
  main: 'main',
  font: 'font',
  background: 'background',
};

const theme = {
  companyColors: {
    main: '',
    font: '',
    background: '',
  },

  defaultColors: {
    primary: '#2E3758',
    background: '#EFF2F7',
    backgroundLight: '#F3F6FA',
    backgroundDark: '#E5EBF3',
    green: '#3AC09D',
    greenDark: '#2E997D',
    blue: '#1D95DC',
    yellow: '#F2C94C',
    red: '#EB5757',
    redDark: '#D14848',
    metallic: '#B8BEC8',
    white: '#ffffff',
    black: '#000000',
    dark: '#1F2D5C',
    richBlack: '#0D0C22',
    gray: '#7D8493',
    grey: '#7D8493',
    neutral100: '#FFFFFF',
    neutral200: '#F7F8FB',
    neutral300: '#EFF2F7',
    neutral500: '#B8BEC8',
    secondary300: '#8E95AD',
    secondary100: '#E9EAEF',
    light: '#EFF2F7', // Anti-flash white
  },

  statusColors: {
    green: '#3AC09D',
    blue: '#1D95DC',
    red: '#EB5757',
    metallic: '#B8BEC8',
  },

  radius: {
    full: '100%',
    extraSmall: '4px',
    small: '6px',
    medium: '25px',
  },

  spacing: {
    layoutBox: '70px 40px',
    extraLarge: '70px',
    large: '40px',
    medium: '20px',
    small: '8px',
    extraSmall: '4px',
    default: '1rem',
  },

  font: {
    regular: '"Poppins", sans-serif',
    semibold: '"Poppins-semibold", sans-serif',
    bold: '"Poppins-bold", sans-serif',
    fieldLabel: {
      landing: '0.625rem',
      app: '0.75rem',
    },
    SansPro: 'SourceSansPro, sans-serif',
  },

  devices: {
    mobileSmall: `max-width: ${viewports.mobileSmall}`,
    mobile: `min-width: ${viewports.mobile}`,
    tablet: `min-width: ${viewports.tablet}`,
    laptop: `min-width: ${viewports.laptop}`,
    laptopHidpi: `min-width: ${viewports.laptopHidpi}`,
    desktop: `min-width: ${viewports.desktop}`,
  },

  sidebarSize: '280px',

  // Easing function for animations
  easing: {
    in: 'cubic-bezier(.51,-0.21,.39,1.46)',
    out: 'cubic-bezier(.68,-0.5,.56,1.23)',
  },
};

export const defaultColor = (key, opacity) => (colorThemeVar(`defaultColors.${key}`, opacity));
export const getDefaultColor = (key) => theme.defaultColors[key];
export const statusColor = (key, opacity) => (colorThemeVar(`statusColors.${key}`, opacity));
export const companyColor = (key, opacity) => (colorThemeVar(`companyColors.${key}`, opacity));
export const spacing = (key) => (themeVar(`spacing.${key}`));
export const radius = (key) => (themeVar(`radius.${key}`));
export const font = (key) => (themeVar(`font.${key}`));
export const device = (key) => (themeVar(`devices.${key}`));
export const sidebarSize = () => (themeVar('sidebarSize'));
export const toPixels = (value) => (typeof value === 'number' ? `${value}px` : value);
export const easing = (key) => (themeVar(`easing.${key}`));

export const fluidFont = (
  minFontSize,
  maxFontSize,
) => {
  const minViewportWidth = '280px';
  const maxViewportWidth = '1920px';

  return css`
  font-size: calc(${minFontSize} + (
    ${parseInt(maxFontSize, 10) - parseInt(minFontSize, 10)}
  ) * (100vw - ${minViewportWidth})/(${parseInt(maxViewportWidth, 10) - parseInt(minViewportWidth, 10)}));
  `;
};

// Mixin for adaptive grid template columns named Holly Albatros
// https://blog.logrocket.com/flexible-layouts-without-media-queries/
export const hollyAlbatros = (cols, gridGap) => css`
  grid-template-columns: repeat(
    auto-fill,
    minmax(
      clamp(
        (100% / ${cols}) - ${gridGap},
        (40rem - 100%) * 999,
        100%
      ),
      1fr
    )
  );
`;

export default theme;
