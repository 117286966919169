import PageHeader from 'components/ui-kit/page-header';
import RequestsList from 'components/requests-list';

const EstimatesRequests = () => {
  return (
    <>
      <PageHeader title="Estimate's requests" />

      <RequestsList />
    </>
  );
};

export default EstimatesRequests;
