import React from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import format from 'date-fns/format';

import routes from 'router/routes';
import { getAppointment, getServiceVisitDetailsPdfAsync } from 'api';

import {
  Breadcrumbs,
  ButtonLink,
  EmployeeBadge,
  InfoBox,
  InfoBoxItem,
  InfoBoxGroup,
  LabelMark,
  PageHeader,
  PaymentSummary,
  Group,
} from 'components/ui-kit';
import AttachedFormsList from 'components/attached-forms-list';
import { InvoiceSmallIcon } from 'assets/icons';

import { defaultDateWithTimeFormat } from 'helpers/datetime.helper';
import { appointmentInvoiceStatuses } from 'helpers/enums';

import { getColor, appointmentTypes, appointmentBusinessTypes } from 'constants/appointments.js';

import AppointmentLoader from './loader';

import { getAppointmentNotesLabel } from './utils';

import * as S from './styles';

const AppointmentItemDetails = ({
  breadcrumbs,
}) => {
  const { portalId, id } = useParams();
  const {
    isError, isLoading, isFetching, data,
  } = useQuery(
    ['appointment', portalId, id],
    getAppointment,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!portalId,
    },
  );

  if (isError) {
    return 'Error occurred while fetching appointment';
  }

  if (isLoading || isFetching) {
    return <AppointmentLoader />;
  }

  if (!data) {
    return null;
  }

  const { data: appointment } = data;
  const {
    id: appointmentId,
    invoiceStatus,
    title,
    status,
    checkLists,
    serviceAddress,
    unitNumber,
    businessEntityType,
    employees,
    lineItems,
    taxRate,
    discount,
    appointmentNotes,
    servicePlanName,
    servicePlanDescription,
    scheduledStartTime,
    actualStartTime,
    actualEndTime,
    preCalculatedSummary,
  } = appointment || {};

  const getDocumentButton = (entity) => {
    if ((entity.invoiceSent || invoiceStatus === appointmentInvoiceStatuses.paid)
      && businessEntityType === appointmentBusinessTypes.job) {
      return (
        <ButtonLink
          to={`/${portalId}/${routes.invoices.path}/${entity.invoiceId}`}
          icon={<InvoiceSmallIcon />}
        >
          Invoice Details
        </ButtonLink>
      );
    }

    if (entity.wasEstimateSent) {
      return (
        <ButtonLink
          to={`/${portalId}/${routes.invoices.path}`}
          icon={<InvoiceSmallIcon />}
        >
          Estimate Details
        </ButtonLink>
      );
    }

    return null;
  };

  return (
    <S.AppointmentBox>
      {appointment && (
        <>
          <Breadcrumbs
            items={[
              ...breadcrumbs,
              { text: title },
            ]}
            gap="1rem"
          />

          <S.ContentBox>
            <PageHeader
              title={title}
              actionItems={getDocumentButton(appointment)}
            >
              <LabelMark
                text={appointmentTypes[businessEntityType]}
                color={getColor(businessEntityType)}
              />
            </PageHeader>

            <InfoBoxGroup>
              <InfoBox title="Appointment information">
                <InfoBoxItem
                  title="Property"
                  value={serviceAddress}
                  description={unitNumber ? `Unit #: ${unitNumber}` : ''}
                />

                {scheduledStartTime && (
                  <InfoBoxItem
                    title="Scheduled date"
                    value={
                      format(new Date(scheduledStartTime), defaultDateWithTimeFormat)
                    }
                  />
                )}

                {actualStartTime && (
                  <InfoBoxItem
                    title="Start date"
                    value={
                      format(new Date(actualStartTime), defaultDateWithTimeFormat)
                    }
                  />
                )}

                {actualEndTime && (
                  <InfoBoxItem
                    title="Finished date"
                    value={
                      format(new Date(actualEndTime), defaultDateWithTimeFormat)
                    }
                  />
                )}

                <InfoBoxItem
                  title="Status"
                  value={status}
                />

                {!!servicePlanName && (
                  <>
                    <InfoBoxItem
                      title="Service plan"
                      value={servicePlanName}
                    />

                    <InfoBoxItem
                      title="Service Plan Details"
                      value={servicePlanDescription}
                      link={{
                        href: getServiceVisitDetailsPdfAsync(portalId, id),
                        text: 'Download visit details',
                      }}
                    />
                  </>
                )}

                {!!checkLists?.length && (
                  <AttachedFormsList
                    entityType={businessEntityType}
                    checkLists={checkLists}
                    eventId={appointmentId}
                  />
                )}
              </InfoBox>

              <InfoBox title="Employees">
                <S.Employees>
                  {employees.map((employee) => (
                    <EmployeeBadge
                      key={employee.id}
                      name={`${employee.firstName} ${employee.lastName}`}
                      phoneNumber={employee.phoneNumber}
                      isSmall={false}
                      imageUrl={employee.logoUrl}
                    />
                  ))}
                </S.Employees>
              </InfoBox>
            </InfoBoxGroup>

            {businessEntityType !== appointmentBusinessTypes.visit && (
              <S.SummaryBox>
                <PaymentSummary
                  lineItems={lineItems}
                  taxRate={taxRate}
                  discount={discount}
                  preCalculatedSummary={preCalculatedSummary || {}}
                />
              </S.SummaryBox>
            )}

            {!!appointmentNotes && (
              <Group mt={40}>
                <InfoBoxItem
                  title={getAppointmentNotesLabel(businessEntityType)}
                  value={appointmentNotes}
                  showColon={false}
                />
              </Group>
            )}
          </S.ContentBox>
        </>
      )}
    </S.AppointmentBox>
  );
};

AppointmentItemDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AppointmentItemDetails;
