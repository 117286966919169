import styled from 'styled-components';

import {
  getDefaultColor,
  radius,
} from 'styles/theme';

export const Tabs = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: ${({ noGap }) => (noGap ? '0' : '24px')};
  background-color: ${getDefaultColor('neutral100')};
  border-radius: ${radius('medium')};
  border: ${({ withoutBorder }) => (withoutBorder ? 'none' : `1px solid ${getDefaultColor('secondary100')}`)};
  height: ${({ h }) => (h)};
  padding: ${({ withoutPadding }) => (withoutPadding ? 0 : '4px')};
  gap: 8px;
  grid-area: tabs;
  margin-right: ${({ fullWidth }) => (fullWidth ? 'unset' : 'auto')};
`;
