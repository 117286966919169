import { useApp } from 'hooks';
import * as S from './styles';

export default function Logo() {
  const { portal } = useApp();

  if (!portal?.logoUrl) {
    return (
      <S.Placeholder>
        {portal?.company?.businessName}
      </S.Placeholder>
    );
  }

  return (
    <S.Logo src={portal?.logoUrl} alt={`${portal?.company?.businessName} logo`} />
  );
}
