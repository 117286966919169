import styled from 'styled-components';
import { companyColor, defaultColor } from 'styles/theme';
import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule.svg';

export const DateLineBox = styled.div`
  display: flex;
  align-items: center;
  color: ${defaultColor('metallic')};
  gap: 4px;
`;

export const TextLine = styled.span`
  color: ${({ color }) => {
    if (color === 'default') {
      return companyColor('font');
    }
    return defaultColor(color) || companyColor('font');
  }};
`;

export const DateIcon = styled(ScheduleIcon)`
  fill: ${defaultColor('metallic')};
  width: 18px;
  height: 16px;
  margin-top: -2px;
  flex-shrink: 1;
`;

export const Pending = styled.p`
  color: ${defaultColor('green')};
`;
