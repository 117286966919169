import styled from 'styled-components';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import {
  companyColor,
  defaultColor,
  fluidFont,
  font,
  radius,
  device,
} from 'styles/theme';

export const DownloadIcon = styled(Download)`
  fill: ${companyColor('font')};
`;

export const Box = styled.div`
  border-radius: ${radius('small')};
  border: 1px solid ${defaultColor('backgroundLight')};
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const PayButton = styled.div`
  align-self: end;
  justify-self: end;
`;

export const Header = styled.div`
  display: grid;
  grid-template-areas:
    'number number'
    'company company'
    'amountDue amountDue';
  grid-row-gap: 1rem;

  @media only screen and (${device('laptop')}) {
    grid-template-areas:
      'number company'
      'amountDue company';
    grid-row-gap: 2rem;
  }
`;

export const InvoiceNumber = styled.span`
  display: flex;
  flex-direction: column;

  @media only screen and (${device('laptop')}) {
    flex-direction: row;
    gap: 1rem;
  }
`;

export const AmountDue = styled.div`
  grid-area: amountDue;
  align-self: end;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const CompanyInfo = styled.div`
  grid-area: company;
  justify-self: end;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${fluidFont('12px', '14px')}
  max-width: 16em;
  gap: 6px;

  @media only screen and (${device('laptop')}) {
    justify-self: end;
    align-items: flex-end;
  }
`;

export const CompanyInfoItem = styled.span`
  text-align: end;
  color: ${defaultColor('metallic')};
  align-self: flex-end;
`;

export const TextLine = styled.span`
  text-align: ${({ align }) => align || 'start'};
  color: ${({ color }) => (color ? defaultColor(color) : companyColor('font'))};
  font-family: ${({ family }) => (family ? font(family) : font('regular'))};
  max-width: ${({ maxWidth }) => (maxWidth || null)};
  ${({ size }) => (size ? fluidFont(`${size - 2}px`, `${size}px`) : fluidFont('12px', '16px'))};
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${({ dark }) => (dark ? companyColor('font') : defaultColor('backgroundLight'))};
`;

export const General = styled.div`
  display: grid;
  grid-template-areas:
    'billedTo billedTo'
    'number number'
    'dates dates'
    'message message';
  justify-content: space-between;
  grid-row-gap: 1rem;

  @media only screen and (${device('laptop')}) {
    grid-template-areas:
      'billedTo dates'
      'number dates'
      'message message';
    grid-row-gap: 2rem;
  }
`;

export const TextGroup = styled.div`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  gap: 6px;
  grid-area: ${({ area }) => (area || null)};
`;

export const DatesWrapper = styled.div`
  ${fluidFont('12px', '14px')};
`;

export const TextGroupHeader = styled.span`
  color: ${defaultColor('metallic')};
`;

export const PaymentSummary = styled.div`
  margin-top: 16px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;
