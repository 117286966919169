import styled from 'styled-components';
import {
  companyColor,
  radius,
  fluidFont,
  font,
  defaultColor,
  device,
} from 'styles/theme';

export const Card = styled.div`
  border: 1px solid ${defaultColor('background')};
  border-radius: ${radius('small')};
  padding: 1.25rem;
  display: grid;
  align-items: center;
  grid-template-columns:  max-content max-content 1fr;
  grid-template-areas:
    'icon date date'
    'hiddenDigits hiddenDigits hiddenDigits';
  align-items: center;
  gap: 1rem;
  ${fluidFont('13px', '16px')};
  font-family: ${font('bold')};
  color: ${companyColor('font')};

  @media only screen and (${device('laptop')}) {
    grid-template-areas: 'icon hiddenDigits date';
  }
`;

export const HiddenDigits = styled.span`
  display: flex;
  align-items: center;
  gap: 0.85rem;
  grid-area: hiddenDigits;
`;

export const Dot = styled.span`
  width: 0.5rem;
  svg {
    width: 100%;
    fill: ${companyColor('font')};
  }
`;

export const DotsGroup = styled.span`
  display: flex;
  gap: 0.3rem;
`;

export const Expiry = styled.span`
  grid-area: date;
  font-family: ${font('regular')};
`;

export const CardIcon = styled.span`
  border: 1px solid #D1D9E0;
  border-radius: 4px;
  width: 2.125rem;
  height: 1.5rem;
  grid-area: icon;

  svg {
    width: 100%;
  }
`;
