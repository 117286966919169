import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { companyColor, defaultColor } from 'styles/theme';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const SpinnerBox = styled.svg`
  --spinner-size: ${({ size }) => (
    size === 'small' ? '18px' : '50px'
  )};
  animation: ${rotate} 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: calc(var(--spinner-size) / -2) 0 0 calc(var(--spinner-size) / -2);
  width: var(--spinner-size);
  height: var(--spinner-size);
`;

const Circle = styled.circle`
  stroke: ${({ color }) => (
    color === 'main'
      ? companyColor('main')
      : defaultColor(color)
  )};
  stroke-linecap: round;
  animation: ${dash} 1.5s ease-in-out infinite;
`;

export default function Spinner({ size, color }) {
  return (
    <SpinnerBox viewBox="0 0 50 50" size={size}>
      <Circle
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
        color={color}
      />
    </SpinnerBox>
  );
}

Spinner.propTypes = {
  size: PropTypes.oneOf(['normal', 'small']),
  color: PropTypes.oneOf([
    'main',
    'green',
    'blue',
    'white',
    'yellow',
  ]),
};

Spinner.defaultProps = {
  size: 'normal',
  color: 'main',
};
