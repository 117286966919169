import CardsListing from 'components/cards-listing';
import PaymentsHeader from 'components/payments-header';

export default function Payments() {
  return (
    <>
      <PaymentsHeader />
      <CardsListing />
    </>
  );
}
