import moment from 'moment';

import { getCompanyEvents } from 'api';

import { getTimeIntervals } from 'helpers/datetime.helper';

export const getField = (name, form) => {
  return form.fields.find((x) => x.name === name);
};

export const getMinDateTime = (form) => {
  const { availableSlotsType = null, availableSlotsValue = null } = form?.availableSlotsDetails || {};

  if (availableSlotsType == null) return moment();

  let minDate = moment();

  if (availableSlotsType === 0) {
    minDate = minDate.add(availableSlotsValue, 'hours');
  } else if (availableSlotsType === 1) {
    minDate = minDate.add(availableSlotsValue, 'days');
  }

  return minDate;
};

export const filterDate = (date, bookingForm, timeSlotsMap) => {
  const day = date.getDay();
  const formattedDate = moment(date).format('YYYY-MM-DD');

  const workingDays = bookingForm.workingSchedule
    .filter((x) => x.isWorkingDay)
    .map((x) => x.weekDay);

  const isWorkingDay = workingDays.includes(day);

  const hasTimeSlots = timeSlotsMap.has(formattedDate) && timeSlotsMap.get(formattedDate).length > 0;

  return isWorkingDay && hasTimeSlots;
};

const handleTimeReset = (values, setFieldValue) => {
  if (values.customTimeRangeEnabled) {
    setFieldValue('time', '');
  }
};

export const handleDateChange = (newDate, setFieldValue, values, updateEventMap) => {
  let selectedDate = newDate;

  if (Array.isArray(selectedDate)) {
    [selectedDate] = selectedDate;
  }

  if (selectedDate instanceof Date) {
    setFieldValue('date', selectedDate);
    handleTimeReset(values, setFieldValue);
    updateEventMap(selectedDate);
  }
};

export const getKeyForDate = (date) => `${date.getFullYear()}-${date.getMonth() + 1}`;

export const getStartEndDates = (month, year) => {
  const startLocal = moment().year(year).month(month).startOf('month');
  const endLocal = moment().year(year).month(month).endOf('month');

  return {
    start: startLocal.utc().toISOString(),
    end: endLocal.utc().toISOString(),
  };
};

const convertEventsToUserTimezone = (events) => {
  return events.map((event) => {
    const startLocal = moment.utc(event.eventStart).local().format();
    const endLocal = moment.utc(event.eventEnd).local().format();

    return {
      ...event,
      eventStart: startLocal,
      eventEnd: endLocal,
    };
  });
};

const filterTimeRanges = (timeRanges, isSameMinDate, minDateTime) => {
  return timeRanges.filter((time) => {
    const [startTime] = time.split(' - ');
    const timeMoment = moment(startTime, 'h:mm a');

    if (isSameMinDate) {
      const minTimeMoment = moment(minDateTime.format('HH:mm'), 'HH:mm');
      return timeMoment.isAfter(minTimeMoment);
    }

    return true;
  });
};

const filterTimeSlots = (timeSlots, eventsByDate, selectedDate) => {
  const formattedDate = moment(selectedDate).format('YYYY-MM-DD');

  return timeSlots.filter((slot) => {
    const [startTime, endTime] = slot.split(' - ');
    const startMoment = moment(`${formattedDate} ${startTime}`, 'YYYY-MM-DD h:mm a');
    const endMoment = moment(`${formattedDate} ${endTime}`, 'YYYY-MM-DD h:mm a');

    return !eventsByDate.some((event) => {
      const eventStart = moment(event.eventStart);
      const eventEnd = moment(event.eventEnd);

      return (
        (startMoment.isBetween(eventStart, eventEnd, null, '[)')
        || endMoment.isBetween(eventStart, eventEnd, null, '(]')
        || (startMoment.isBefore(eventEnd) && endMoment.isAfter(eventStart)))
      );
    });
  });
};

const calculateTimeSlotsForDay = (date, values, form, minDateTime, eventMap) => {
  const dayOfWeek = date.getDay();
  const schedule = form.workingSchedule.find((x) => x.weekDay === dayOfWeek);

  if (!schedule) return [];

  if (!values.customTimeRangeEnabled) return getField('Time', form).options.map((o) => ({ value: o, label: o }));

  const timeRanges = getTimeIntervals(schedule.start, schedule.end, form.customTimeRange * 60);
  const isSameMinDate = moment(date).isSame(minDateTime, 'day');
  const filteredRanges = filterTimeRanges(timeRanges, isSameMinDate, minDateTime);

  const dateKey = getKeyForDate(date);

  return filterTimeSlots(filteredRanges, eventMap.get(dateKey) || [], date)
    .map((time) => ({ value: time, label: time }));
};

const calculateTimeSlotsForMonth = (month, year, values, form, minDateTime, eventMap, timeSlotsMap) => {
  const daysInMonth = moment().year(year).month(month).daysInMonth();

  for (let day = 1; day <= daysInMonth; day += 1) {
    const date = new Date(year, month, day);
    const dateKey = moment(date).format('YYYY-MM-DD');

    const availableTimeSlots = calculateTimeSlotsForDay(date, values, form, minDateTime, eventMap);

    timeSlotsMap.set(dateKey, availableTimeSlots);
  }

  return timeSlotsMap;
};

export const fetchCompanyEvents = async (month, year, values, form, minDateTime, eventMap, timeSlotsMap) => {
  const key = `${year}-${month + 1}`;

  if (eventMap.has(key)) return;

  let events = [];

  if (form.isShowOnlyNonBooked) {
    const { start, end } = getStartEndDates(month, year);
    const { data } = await getCompanyEvents(values.portalId, start, end);
    events = convertEventsToUserTimezone(data);
  }

  eventMap.set(key, events);

  calculateTimeSlotsForMonth(month, year, values, form, minDateTime, eventMap, timeSlotsMap);
};
