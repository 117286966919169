import ServicePlansHeader from 'components/service-plan-subscriptions/service-plan-subscriptions-header';
import NavSwitcher from 'components/ui-kit/nav-switcher';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

const getSwitcherList = (pendingCount) => {
  const items = [
    {
      label: 'Active',
      path: '',
    },
    {
      label: 'Pending',
      path: 'pending',
    },
  ];

  if (pendingCount) {
    items[1].indicator = {
      color: 'red',
      value: pendingCount,
    };
  }

  return items;
};

export default function ServicePlanSubscriptionsPageContent() {
  const [pendingCount, setPendingCount] = useState(0);
  return (
    <>
      <ServicePlansHeader />
      <NavSwitcher items={getSwitcherList(pendingCount)} />
      <main>
        <Outlet context={[pendingCount, setPendingCount]} />
      </main>
    </>
  );
}
