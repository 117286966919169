import development from 'config/environment/development.json';
import devtest from 'config/environment/devtest.json';
import demo from 'config/environment/demo.json';
import staging from 'config/environment/staging.json';
import production from 'config/environment/production.json';

const environments = {
  development, devtest, demo, staging, production,
};

const getEnvironmentName = () => process.env.REACT_APP_APP_ENV.toLowerCase();

const getConfig = () => environments[getEnvironmentName()];

export default getConfig();
