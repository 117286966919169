import { Field, Select, Datepicker } from 'components/ui-kit/form';
import PropTypes from 'prop-types';
import {
  getYear,
  format,
} from 'date-fns';
import { useApp } from 'hooks';
import * as S from './styles';

export default function Filters({
  fields,
  handleClickChange,
  daterange: {
    minDate,
    maxDate,
  },
  select: {
    options,
    label,
    name: selectName,
  },
  showViewToggle,
}) {
  const { appointment: { toggleCompact, isCompact } } = useApp();
  const dateFormat = getYear(fields.daterange[0]) === getYear(fields.daterange[1])
    ? 'LLL d'
    : 'LLL d, yyyy';

  const inputValue = `${format(fields.daterange[0], dateFormat)} - ${format(fields.daterange[1], dateFormat)}`;
  return (
    <S.FiltersGroup>
      <Field
        name="daterange"
        label="Date range"
        onClickChange={handleClickChange}
        minDate={minDate}
        maxDate={maxDate}
        value={inputValue}
        defaultValue={fields.daterange}
        element={Datepicker}
      />
      <Field
        name={selectName}
        label={label}
        value={fields[selectName]}
        onChange={handleClickChange}
        options={options}
        element={Select}
      />
      {showViewToggle
        && (
        <S.ViewToggler onClick={toggleCompact} className={isCompact ? 'active' : null}>
          <S.ToggleIcon />
        </S.ViewToggler>
        )}
    </S.FiltersGroup>
  );
}

Filters.propTypes = {
  fields: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.instanceOf(Date),
  ])).isRequired,
  handleClickChange: PropTypes.func.isRequired,
  daterange: PropTypes.shape({
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
  }),
  select: PropTypes.shape({
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })).isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  showViewToggle: PropTypes.bool,
};

Filters.defaultProps = {
  showViewToggle: false,
  daterange: {
    minDate: null,
    maxDate: null,
  },
};
