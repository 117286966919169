export const downloadFile = (blobData, name) => {
  const url = URL.createObjectURL(blobData);
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.target = '_self';
  a.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
};
