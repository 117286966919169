import { format as formatDate, parseISO } from 'date-fns';
import { defaultDateFormat } from 'helpers/datetime.helper';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function DateLine({
  label,
  date,
  labelColor,
  dateColor,
  format,
  placeholder,
}) {
  const dateObj = typeof date === 'string' ? parseISO(date) : date;

  return (
    <S.DateLineBox>
      <S.DateIcon />
      <S.TextLine color={labelColor}>
        {label}
        :
      </S.TextLine>
      <S.TextLine color={dateColor}>
        {dateObj ? formatDate(dateObj, format) : placeholder}
      </S.TextLine>
    </S.DateLineBox>
  );
}

const availableColors = PropTypes.oneOf([
  'green',
  'metallic',
  'default',
  'blue',
]);

DateLine.propTypes = {
  label: PropTypes.string.isRequired,
  date: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  labelColor: availableColors,
  dateColor: availableColors,
  format: PropTypes.string,
  placeholder: PropTypes.string,
};

DateLine.defaultProps = {
  date: null,
  format: defaultDateFormat,
  labelColor: 'metallic',
  dateColor: 'default',
  placeholder: 'Pending...',
};
