import { useState } from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

const isValuePresent = (value) => (typeof value === 'number' || value);

export default function Field({
  element: Element,
  name,
  label,
  addon,
  onChange,
  value,
  options,
  ...restProps
}) {
  const [isFocused, setIsFocused] = useState(false);
  const isLabelUp = isFocused || isValuePresent(value);

  return (
    <S.FieldBox>
      <Element
        name={name}
        isFocused={isFocused}
        onChange={onChange}
        setIsFocused={setIsFocused}
        value={value}
        options={options}
        label={label}
        {...restProps}
      />
      <S.Label className={isLabelUp ? 'label_up' : null}>{label}</S.Label>
      {!!addon && <S.Addon isFocused={isFocused}>{addon}</S.Addon>}
    </S.FieldBox>
  );
}

Field.propTypes = {
  element: PropTypes.elementType.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  addon: PropTypes.node,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })),
};

Field.defaultProps = {
  name: '',
  addon: null,
  options: null,
  label: '',
  onChange: () => {},
};
