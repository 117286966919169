import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CircleIcon } from 'assets/icons/circle.svg';
import cardIcons from '../card-icons';
import * as S from './styles';

const getIcon = (brand) => {
  switch (brand) {
    case 'amex':
      return cardIcons.AmericanExpressIcon;
    case 'visa':
      return cardIcons.VisaIcon;
    case 'mastercard':
      return cardIcons.MastercardIcon;
    case 'diners':
      return cardIcons.DinersIcon;
    case 'discover':
      return cardIcons.DiscoverIcon;
    case 'jcb':
      return cardIcons.JcbIcon;
    case 'unionpay':
      return cardIcons.UnionPayIcon;
    default:
      return null;
  }
};

const dots = [1, 2, 3, 4];
const dotsGroup = [1, 2, 3];

export default function CardItem({
  last4,
  expMonth,
  expYear,
  brand,
}) {
  const Icon = getIcon(brand);

  return (
    <S.Card>
      <S.CardIcon><Icon /></S.CardIcon>
      <S.HiddenDigits>
        {dotsGroup.map((group, groupIndex) => (
          <S.DotsGroup key={`${String(groupIndex)}`}>
            {dots.map((dot, dotIndex) => (
              <S.Dot key={`${String(groupIndex)}-${String(dotIndex)}`}>
                <CircleIcon />
              </S.Dot>
            ))}
          </S.DotsGroup>
        ))}
        <span>{last4}</span>
      </S.HiddenDigits>
      <S.Expiry>{`${expMonth}/${expYear}`}</S.Expiry>
    </S.Card>
  );
}

CardItem.propTypes = {
  last4: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  expMonth: PropTypes.number.isRequired,
  expYear: PropTypes.number.isRequired,
};
