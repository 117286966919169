import styled from 'styled-components';
import {
  hollyAlbatros, spacing, fluidFont, companyColor, device,
} from 'styles/theme';

export const Box = styled.div`
  display: grid;
  gap: ${spacing('default')};
  ${hollyAlbatros(3, spacing('default'))};
  margin: 2rem 0;

  @media(${device('laptopHidpi')}) {
    ${hollyAlbatros(2, spacing('default'))};
  }

  @media(${device('desktop')}) {
    ${hollyAlbatros(4, spacing('default'))};
  }
`;

export const TextInfo = styled.span`
  ${fluidFont('12px', '18px')};
  color: ${companyColor('font')};
`;
