import React, {
  createContext,
  useContext,
  useState,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';

const ViewAttachmentModalContext = createContext({
  isOpen: false,
  openViewAttachmentModal: () => {},
  closeViewAttachmentModal: () => {},
  modalProps: {},
});

export const useViewAttachmentModal = () => useContext(ViewAttachmentModalContext);

export const ViewAttachmentModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalProps, setModalProps] = useState({});

  const openViewAttachmentModal = (props) => {
    setIsOpen(true);
    setModalProps(props);
  };

  const closeViewAttachmentModal = () => {
    setIsOpen(false);
    setModalProps({});
  };

  const contextValue = useMemo(() => ({
    isOpen,
    openViewAttachmentModal,
    closeViewAttachmentModal,
    modalProps,
  }), [isOpen, modalProps]);

  return (
    <ViewAttachmentModalContext.Provider value={contextValue}>
      {children}
    </ViewAttachmentModalContext.Provider>
  );
};

ViewAttachmentModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]).isRequired,
};
