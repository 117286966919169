import PropTypes from 'prop-types';
import { ReactComponent as Icon } from 'assets/icons/placeholder.svg';
import * as S from './styles';

export default function ListPlaceholder({ message }) {
  return (
    <S.Placeholder>
      <S.Image>
        <Icon />
      </S.Image>
      <S.Message>{message}</S.Message>
    </S.Placeholder>
  );
}

ListPlaceholder.propTypes = {
  message: PropTypes.string,
};

ListPlaceholder.defaultProps = {
  message: 'Items not found',
};
