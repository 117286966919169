import styled from 'styled-components';
import { companyColor } from 'styles/theme';

export const ButtonGroup = styled.div`
  display: flex;
  justify-items: space-between;
  gap: 1rem;
`;

export const Text = styled.p`
  color: ${companyColor('font')};
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
