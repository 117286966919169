import React from 'react';
import PropTypes from 'prop-types';

import { getAppointmentChecklist } from 'api';

import { useApp } from 'hooks';
import { downloadFile } from 'helpers/file-download.helper';

import { Group, Typography } from 'components/ui-kit';
import { ChecklistItemIcon, DownloadIcon } from 'assets/icons';

import { getDefaultColor } from 'styles/theme';

export default function AttachedForm({ entityType, checkList, eventId }) {
  const { id, name } = checkList;
  const { portal } = useApp();

  const download = async () => {
    const response = await getAppointmentChecklist({
      portalId: portal.id,
      entityType,
      eventId,
      checkListId: id,
    });

    downloadFile(response.data, `${name}.pdf`);
  };

  return (
    <Group
      w="100%"
      maw={240}
      p={8}
      r={4}
      alignItems="center"
      justifyContent="space-between"
      bgColor={getDefaultColor('backgroundLight')}
    >
      <Group gap={8} alignItems="center">
        <ChecklistItemIcon />

        <Typography
          maw={140}
          fluidFont={{ min: 12, max: 14 }}
          fontWeight={500}
          lh="138%"
          truncate
        >
          {name}
        </Typography>
      </Group>

      <DownloadIcon
        fill={getDefaultColor('dark')}
        cursor="pointer"
        onClick={() => download()}
      />
    </Group>
  );
}

AttachedForm.propTypes = {
  entityType: PropTypes.number.isRequired,
  checkList: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  eventId: PropTypes.string.isRequired,
};
