import { discountTypes } from './enums';

export const roundUp = (money) => {
  // to avoid float inaccurate like 0.1 * 0.2 = 0.020000000000000004
  const fixed = parseFloat(money).toFixed(12);

  return Math.round(fixed * 100) / 100;
};

export const getItemAmount = ({ unitPrice = 0, quantity = 0 }) => {
  return roundUp(unitPrice * roundUp(quantity));
};

export const getTotal = (lineItems) => {
  if (!lineItems.length) return 0;

  return lineItems.reduce((total, item) => total + getItemAmount(item), 0);
};

const calculateTax = (amount, taxRate) => {
  return roundUp(amount * ((taxRate?.percentage || 0) / 100));
};

export const getDiscount = (amount, discount) => {
  if (!discount) {
    return 0;
  }

  const discountAmount = discount.amount ? Number(discount.amount) : 0;

  let result = String(discount.type) === discountTypes.percent
    ? (discountAmount / 100) * amount
    : discountAmount;

  result = roundUp(result);

  return Math.min(result, amount);
};

export const calculateSummary = ({
  lineItems,
  taxRate,
  discount,
}) => {
  const [taxableAmount, nonTaxableAmount] = lineItems.reduce(
    ([taxableAmt, nonTaxableAmt], lineItem) => {
      const taxableVal = taxableAmt + (lineItem.isTaxable ? getItemAmount(lineItem) : 0);
      const nonTaxableVal = nonTaxableAmt + (lineItem.isTaxable ? 0 : getItemAmount(lineItem));

      return [taxableVal, nonTaxableVal];
    },
    [0, 0],
  );

  const amountWithoutDiscountAndTax = taxableAmount + nonTaxableAmount;
  const discountAmount = getDiscount(amountWithoutDiscountAndTax, discount);

  let taxableAmountDiscount = 0;

  if (discount) {
    if (String(discount.type) === discountTypes.percent) {
      taxableAmountDiscount = getDiscount(taxableAmount, discount);
    } else {
      taxableAmountDiscount = Math.max(discountAmount - nonTaxableAmount, 0);
    }
  }

  const taxableAmountAfterDiscount = Math.max(taxableAmount - taxableAmountDiscount, 0);
  const taxAmount = calculateTax(taxableAmountAfterDiscount, taxRate);
  const totalAmount = amountWithoutDiscountAndTax - discountAmount + taxAmount;

  return {
    subtotalAmount: amountWithoutDiscountAndTax,
    discountAmount,
    taxAmount,
    totalAmount,
  };
};

export const calculateEntitySummary = ({
  lineItems,
  taxRate,
  discount,
  preCalculatedSummary,
}) => {
  const [requiredLineItems, optionalLineItems] = lineItems.reduce(
    ([required, optional], lineItem) => {
      (lineItem.optionalState ? optional : required).push(lineItem);
      return [required, optional];
    },
    [[], []],
  );

  const acceptedOptionalItems = optionalLineItems.filter((item) => item.optionalState?.isAccepted);

  const usePreCalculated = Object.keys(preCalculatedSummary || {}).length > 0;

  const {
    subtotalAmount,
    discountAmount,
    taxAmount,
    totalAmount,
  } = usePreCalculated
    ? preCalculatedSummary
    : calculateSummary({
      lineItems: [...requiredLineItems, ...acceptedOptionalItems],
      taxRate,
      discount,
    });

  return {
    requiredLineItems,
    optionalLineItems,
    requiredSubtotal: getTotal(requiredLineItems),
    optionalSubtotal: getTotal(acceptedOptionalItems),
    subtotalAmount,
    discountAmount,
    taxAmount,
    totalAmount,
  };
};
