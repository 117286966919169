import styled from 'styled-components';
import {
  companyColor,
  defaultColor,
  radius,
  device,
} from 'styles/theme';

export const NavSwitcher = styled.nav`
  display: flex;
  align-items: center;
  background-color: ${defaultColor('backgroundLight')};
  border-radius: ${radius('medium')};
  height: 40px;
  padding: 0 5px;
  flex: 0 1 auto;
  width: 100%;

  @media(${device('tablet')}) {
    width: calc(100% / 2.5);
  }
`;

export const Highlighter = styled.li`
  border-radius: ${radius('medium')};
  background-color: ${companyColor('font')};
  position: absolute;
  width: 50%;
  height: 30px;
  z-index: 0;
  transform: translateX(0);
  transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);;
`;

export const List = styled.ul`
  list-style: none;
  border-radius: ${radius('medium')};
  margin: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  & li {
    width: 50%;
  }

  & li:nth-child(2).active ~ ${Highlighter} {
    transform: translateX(100%);
  }
`;
