import { useTransition } from 'react-spring';
import * as S from './styles';

function SelectMenu({
  isFocused,
  name,
  options,
  onChange,
  value,
  fullWidth,
  isMulti,
}) {
  const transitions = useTransition(isFocused, {
    config: {
      duration: 210,
    },
    from: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
  });

  const handleChange = (v, id) => {
    onChange(v, id);
  };

  return transitions((styles, item) => item && (
    <S.List $fullWidth={fullWidth} style={styles}>
      {options.map((option, index) => (
        <li
          key={`${option.value}${String(index)}`}
          style={{ display: (isMulti && value.includes(option.value)) ? 'none' : 'list-item' }}
        >
          <S.ListItem
            onClick={() => handleChange(option.value, name)}
            isActive={value === option.value}
          >
            {option.label}
          </S.ListItem>
        </li>
      ))}
    </S.List>
  ));
}

export default SelectMenu;
