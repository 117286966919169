import styled from 'styled-components';
import {
  defaultColor,
  font,
  fluidFont,
} from 'styles/theme';

export const FieldBox = styled.div`
  position: relative;
  width: 100%;
  min-height: 50px;
`;

export const Label = styled.label`
  color: ${defaultColor('metallic')};
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  position: absolute;
  left: 1rem;
  top: 25px;
  width: 100%;
  transform: translateY(-50%) scale(1);
  transition: transform 190ms ease-in-out;
  pointer-events: none;

  transform-origin: 0 0;
  &.label_up {
    transform: translateY(-1.2rem) scale(0.85);
  }
`;

export const Addon = styled.div`
  position: absolute;
  right: 1rem;
`;
