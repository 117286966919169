import PropTypes from 'prop-types';
import * as S from './styles';

export default function SimpleLayout({ children, size }) {
  return (
    <S.Wrapper size={size}>
      <S.Content size={size}>
        {children}
      </S.Content>
    </S.Wrapper>
  );
}

SimpleLayout.propTypes = {
  size: PropTypes.oneOf(['normal', 'large']),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

SimpleLayout.defaultProps = {
  size: 'normal',
};
