import styled from 'styled-components';

import { defaultColor } from 'styles/theme';

export const Header = styled.div`
  color: ${defaultColor('dark')};
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
`;

export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
`;
