import ContentLoader from 'react-content-loader';

export default function ServicePlanLoader() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="800px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="3" ry="3" width="50%" height="15px" />
      <rect x="0" y="40px" rx="3" ry="3" width="30%" height="25px" />

      <rect x="0" y="90px" rx="3" ry="3" width="95%" height="200px" />

      <rect x="0" y="330px" rx="3" ry="3" width="20%" height="15px" />
      <rect x="0" y="360px" rx="3" ry="3" width="95%" height="90px" />

      <rect x="0" y="480px" rx="3" ry="3" width="20%" height="15px" />
      <rect x="0" y="510px" rx="3" ry="3" width="95%" height="90px" />

    </ContentLoader>
  );
}
