import styled from 'styled-components';
import {
  font,
  companyColor,
  fluidFont,
} from 'styles/theme';

export const Logo = styled.img`
  max-width: 100%;
  max-height: 150px;
  object-fit: contain;
  margin: 0 auto;
  border-radius: 8px;
`;

export const Placeholder = styled.h2`
  font-family: ${font('semibold')};
  ${fluidFont('18px', '20px')};
  color: ${companyColor('font')};
`;
