import PropTypes from 'prop-types';

import { toPixels } from 'styles/theme';

import * as S from './styles';

export default function Indicator({
  value,
  color,
  size,
  ml,
}) {
  return (
    <S.Indicator color={color} size={size} ml={toPixels(ml)}>{value}</S.Indicator>
  );
}

Indicator.defaultProps = {
  color: 'default',
  size: 'normal',
  ml: null,
};

Indicator.propTypes = {
  value: PropTypes.number.isRequired,
  color: PropTypes.oneOf(['default', 'red']),
  size: PropTypes.oneOf(['normal', 'small']),
  ml: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
