import styled from 'styled-components';
import { companyColor, fluidFont, font } from 'styles/theme';
import { ReactComponent as SuccessIcon } from 'assets/icons/success-responsive.svg';

export const Paid = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  font-family: ${font('semibold')};
  ${fluidFont('18px', '20px')};
  gap: 4px;
`;

export const PaidIcon = styled(SuccessIcon)`
  fill: ${companyColor('font')};
  width: 24px;
  height: 24px;
`;
