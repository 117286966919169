import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { createSetupIntentSecret, trySetDefaultCard } from 'api';
import Button from 'components/ui-kit/buttons/button';
import { useApp, useModal, useToast } from 'hooks';
import { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import Theme, { companyColor, defaultColor } from 'styles/theme';
import * as S from './styles';

const redColor = defaultColor('red')({ theme: Theme });
const metallicColor = defaultColor('metallic')({ theme: Theme });
const fontColor = companyColor('font')({ theme: Theme });

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: fontColor,
      fontFamily: 'Poppins, "Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: metallicColor,
      },
    },
    invalid: {
      color: redColor,
      iconColor: redColor,
    },
  },
};

export default function InputCardForm() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { portal } = useApp();
  const { addErrorToast, addSuccessToast } = useToast();

  const stripe = useStripe();
  const elements = useElements();
  const { hideModal } = useModal();
  const queryClient = useQueryClient();

  const createSecretMutation = useMutation((portalId) => createSetupIntentSecret(portalId));
  const defaultCardMutation = useMutation((portalId) => trySetDefaultCard(portalId));

  const addPaymentMethod = async () => {
    if (!stripe || !elements) {
      return false;
    }

    const { data: setupWorkflow } = await createSecretMutation.mutateAsync(portal.id);

    const stripeResponse = await stripe.confirmCardSetup(setupWorkflow.clientSecret, {
      payment_method: {
        card: elements.getElement('cardNumber'),
      },
    });

    if (stripeResponse.error) {
      addErrorToast(stripeResponse.error.message);
    } else {
      await defaultCardMutation.mutateAsync(portal.id);
      queryClient.invalidateQueries('cardsListingQuery');
      addSuccessToast('Card was added');
    }

    return !stripeResponse.error;
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const result = await addPaymentMethod();
      if (result) {
        hideModal();
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <S.FormWrapper>
      <S.GridWrapper gridArea="number">
        <S.StripeFieldBox>
          <S.StripeInput>
            <S.Label>Card number</S.Label>
            <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
          </S.StripeInput>
        </S.StripeFieldBox>
      </S.GridWrapper>

      <S.GridWrapper gridArea="expiry">
        <S.StripeFieldBox>
          <S.StripeInput>
            <S.Label>Exp. date</S.Label>
            <CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
          </S.StripeInput>
        </S.StripeFieldBox>
      </S.GridWrapper>

      <S.GridWrapper gridArea="cvc">
        <S.StripeFieldBox>
          <S.StripeInput>
            <S.Label>CVV/CVC</S.Label>
            <CardCvcElement options={CARD_ELEMENT_OPTIONS} />
          </S.StripeInput>
        </S.StripeFieldBox>
      </S.GridWrapper>

      <S.GridWrapper gridArea="button">
        <Button stretched onClick={handleSubmit} color="primary" isLoading={isSubmitting}>Add payment method</Button>
      </S.GridWrapper>
    </S.FormWrapper>
  );
}
