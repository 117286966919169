import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
} from 'react';
import { Field as FormikField } from 'formik';
import { useQuery } from 'react-query';
import moment from 'moment';
import PropTypes from 'prop-types';

import { isInServiceArea } from 'api';

import { Input } from 'components/ui-kit/form';
import FormikDatepicker from 'components/ui-kit/form/formik-datepicker';
import FormikSelect from 'components/ui-kit/form/formik-select';
import LocationInput from 'components/ui-kit/form/location-input';

import {
  getMinDateTime,
  filterDate,
  handleDateChange,
  getKeyForDate,
  fetchCompanyEvents,
} from './utils';

import * as S from '../styles';

const ContactDetailsStep = ({
  values,
  form,
  setFieldValue,
}) => {
  const [currentKey, setCurrentKey] = useState('');
  const eventMapRef = useRef(new Map());
  const timeSlotsMapRef = useRef(new Map());
  const minDateTime = getMinDateTime(form);

  const fetchInitialEvents = useCallback((now) => {
    const nextMonth = now.clone().add(1, 'month');

    fetchCompanyEvents(
      now.month(),
      now.year(),
      values,
      form,
      minDateTime,
      eventMapRef.current,
      timeSlotsMapRef.current,
    );
    fetchCompanyEvents(
      nextMonth.month(),
      nextMonth.year(),
      values,
      form,
      minDateTime,
      eventMapRef.current,
      timeSlotsMapRef.current,
    );
    setCurrentKey(getKeyForDate(now.toDate()));
  }, [form, minDateTime, values]);

  useEffect(() => {
    const now = moment();
    fetchInitialEvents(now);
  }, [fetchInitialEvents]);

  const handleMonthChange = (date) => {
    const newMonth = date.getMonth();
    const newYear = date.getFullYear();
    const key = getKeyForDate(date);

    if (!eventMapRef.current.has(key)) {
      fetchCompanyEvents(
        newMonth,
        newYear,
        values,
        form,
        minDateTime,
        eventMapRef.current,
        timeSlotsMapRef.current,
      );
    }

    setCurrentKey(key);
  };

  const updateEventMap = (newDate) => {
    const selectedYear = newDate.getFullYear();
    const selectedMonth = newDate.getMonth();
    const key = getKeyForDate(newDate);

    const nextMonth = (selectedMonth + 1) % 12;
    const nextYear = selectedMonth === 11 ? selectedYear + 1 : selectedYear;
    const nextKey = getKeyForDate(new Date(nextYear, nextMonth));

    if (currentKey !== key && !eventMapRef.current.has(key)) {
      fetchCompanyEvents(
        selectedMonth,
        selectedYear,
        values,
        form,
        minDateTime,
        eventMapRef.current,
        timeSlotsMapRef.current,
      );
    }

    if (!eventMapRef.current.has(nextKey)) {
      fetchCompanyEvents(
        nextMonth,
        nextYear,
        values,
        form,
        minDateTime,
        eventMapRef.current,
        timeSlotsMapRef.current,
      );
    }

    setCurrentKey(key);
  };

  const getTimeOptons = (date) => {
    return timeSlotsMapRef.current.get(moment(date).format('YYYY-MM-DD'));
  };

  useQuery(
    [
      'serviceAreaCheck',
      values.portalId,
      values.geoData?.latitude,
      values.geoData?.longitude,
    ],
    isInServiceArea,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: (
        !!values.geoData?.latitude
      && !!values.geoData?.longitude
      ),
      onSuccess: ({ data: { isInServiceArea: flag } }) => {
        setFieldValue('isAddressInArea', flag);
      },
    },
  );

  return (
    <S.Content>
      <S.Header>
        Book Online
      </S.Header>

      <S.FieldContainer>
        <S.Label>Address</S.Label>

        <FormikField
          name="address"
          component={LocationInput}
          label="Property"
          isGap={false}
        />
      </S.FieldContainer>

      <S.FieldContainer>
        <S.Label>Unit/Suite/Apt. number (optional)</S.Label>

        <FormikField
          name="unitNumber"
          component={Input}
          label="Unit Number"
          isGap={false}
        />
      </S.FieldContainer>

      <S.FieldContainer>
        <S.Label>If available, what day works best for you?</S.Label>

        <FormikField
          name="date"
          component={FormikDatepicker}
          selectRange={false}
          shouldDisableDay={(date) => !filterDate(date, form, timeSlotsMapRef.current)}
          isGap={false}
          disableYearNavigation
          disableViewChange
          showOutsideDays={false}
          selected={values.date}
          minDate={minDateTime.toDate()}
          onChange={(newDate) => handleDateChange(newDate, setFieldValue, values, updateEventMap)}
          onMonthChange={handleMonthChange}
        />
      </S.FieldContainer>

      <S.FieldContainer>
        <S.Label>What are you preferred arrival times?</S.Label>

        <FormikField
          name="time"
          placeholder="Any time"
          component={FormikSelect}
          label="Arrival time"
          options={getTimeOptons(values.date)}
          isDisabled={values.customTimeRangeEnabled && !(values.date instanceof Date)}
          closeOnSelect
          isGap={false}
        />
      </S.FieldContainer>
    </S.Content>
  );
};

export const workingScheduleItemPropTypes = PropTypes.shape({
  start: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  end: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired,
  ]),
  isWorkingDay: PropTypes.bool.isRequired,
  weekDay: PropTypes.number.isRequired,
});

ContactDetailsStep.propTypes = {
  form: PropTypes.shape({
    customTimeRange: PropTypes.number,
    workingSchedule: PropTypes.arrayOf(workingScheduleItemPropTypes).isRequired,
    services: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })),
    fields: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  values: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    services: PropTypes.arrayOf(PropTypes.string),
    address: PropTypes.string,
    geoData: PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
    }),
    unitNumber: PropTypes.string,
    customTimeRangeEnabled: PropTypes.bool,
    portalId: PropTypes.string,
    isAddressInArea: PropTypes.bool,
  }).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    services: PropTypes.string,
    address: PropTypes.string,
    unitNumber: PropTypes.string,
    isInServiceArea: PropTypes.string,
  }),
};

ContactDetailsStep.defaultProps = {
  errors: {},
};

export default ContactDetailsStep;
