import React from 'react';
import PropTypes from 'prop-types';

import { Stack, Money, Typography } from 'components/ui-kit';

import * as S from './styles';

const ServiceCard = ({
  name,
  description,
  showPricing,
  price,
  imageUrl,
  isSelected,
  onClick,
}) => {
  return (
    <S.Card onClick={onClick} isSelected={isSelected}>
      <Stack gap={6}>
        <Typography font="regular" fontWeight={600} color="dark">
          {name}
        </Typography>

        {description && (
          <Typography
            font="regular"
            fontSize={13}
            color="dark"
            breakWord={false}
          >
            {description}
          </Typography>
        )}

        {showPricing && price != null && (
          <Typography font="regular" fontWeight={600} color="dark">
            <Money amount={price} />
          </Typography>
        )}
      </Stack>

      <S.ImageContainer>
        {imageUrl ? (
          <S.Image src={imageUrl} alt={name} />
        ) : (
          <S.EmptyContainer />
        )}
      </S.ImageContainer>
    </S.Card>
  );
};

ServiceCard.defaultProps = {
  description: null,
  price: null,
  imageUrl: null,
};

ServiceCard.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  showPricing: PropTypes.bool.isRequired,
  price: PropTypes.number,
  imageUrl: PropTypes.string,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ServiceCard;
