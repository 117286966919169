import { css } from 'styled-components';
import {
  companyColor,
  defaultColor,
  font,
  radius,
} from 'styles/theme';

export const FormControlStylesWithoutLabel = css`
  width: 100%;
  height: 50px;
  
  font-family: ${font('regular')};
  color: ${companyColor('font')};

  outline: none;
  border-radius: ${radius('small')};
  border: 1px solid ${({ isError }) => { return isError ? defaultColor('red') : defaultColor('background'); }};
  transition: border-color 190ms ease-in-out;

  padding-left: 1rem;
  padding-right: 1rem;

  &.focused {
    border-color: ${({ isError }) => { return isError ? defaultColor('redDark') : companyColor('font'); }};
  }
`;

export const FormControlStlyes = css`
  ${FormControlStylesWithoutLabel};
  padding-top: 0.8rem;
`;
