import React from 'react';

import { SecondaryModalConsumer } from './secondaryModalContext';

export default function SecondaryModalRoot() {
  return (
    <SecondaryModalConsumer>
      {({ component: Component, props }) => (
        Component ? <Component {...props} /> : null
      )}
    </SecondaryModalConsumer>
  );
}
