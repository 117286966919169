import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import {
  companyColor, defaultColor, fluidFont, radius,
} from 'styles/theme';

export const ToastContainerWrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 100;
  overflow: hidden;
  padding-left: 4rem;
`;

export const ToastBox = styled(animated.div)`
  display: flex;
  align-items: center;
  gap: 1rem;

  position: relative;

  margin-right: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-bottom: calc(1rem + 4px);

  min-width: 23rem;
  max-width: 23rem;
  min-height: 5.5rem;

  ${fluidFont('12px', '14px')};

  color: ${companyColor('font')};
  background: ${defaultColor('background')};
  border-radius: ${radius('small')};
`;

export const ToastIcon = styled.div`
  flex-shrink: 0;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 8px;
  right: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  background: none;
  height: 24px;
  svg {
    height: 100%;
    fill: ${companyColor('font')};
    transform: rotate(45deg);
  }
`;

const ProgressBarStyles = css`    
  background: ${({ isErrorMessage }) => (
    isErrorMessage ? defaultColor('red') : defaultColor('green')
  )};
  border-bottom-left-radius: ${radius('small')};
`;

export const ProgressBar = styled(animated.progress)`
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  height: 4px;
  appearance: none;
  border: none;
  color: white;
  
  border-bottom-left-radius: ${radius('small')};
  border-bottom-right-radius: ${radius('small')};

  background-color: transparent;

  ::-moz-progress-bar {
    ${ProgressBarStyles}
  }
  ::-webkit-progress-value {
    ${ProgressBarStyles}
`;
