import InvoicesListing from 'components/invoices/listing';
import PageHeader from 'components/ui-kit/page-header';

export default function Invoices() {
  return (
    <>
      <PageHeader title="Invoices" />
      <InvoicesListing />
    </>
  );
}
