import React, {
  useState,
  Children,
} from 'react';

import PropTypes from 'prop-types';

import Tab from 'components/tab';
import TabsWrapper from 'components/tabs-wrapper';

import * as S from './styles';

export default function TabContainer({
  tabs,
  selectedTab,
  children,
  onChange,
  h,
  fullWidth,
  noGap,
  withoutBorder,
  withoutPadding,
  borderedItems,
}) {
  const mappedTabs = tabs.map((t) => {
    if (typeof t === 'string') {
      return { id: t, component: <span>{t}</span> };
    }

    return t;
  });

  const tabIdx = selectedTab ? mappedTabs.findIndex((item) => item.id === selectedTab) : 0;
  const [activeTab, setActiveTab] = useState(tabIdx >= 0 ? tabIdx : 0);

  const tabContents = Children.toArray(children);

  if (activeTab === null) {
    return null;
  }

  const onTabChange = (tab) => {
    setActiveTab(tab);
    onChange(tab);
  };

  return (
    <>
      <TabsWrapper
        noGap={noGap || !tabContents.length}
        h={h}
        fullWidth={fullWidth}
        withoutBorder={withoutBorder}
        withoutPadding={withoutPadding}
      >
        {mappedTabs.map((mappedTab, i) => (
          <Tab
            key={`${mappedTab.id}${String(i)}`}
            content={mappedTab.component}
            onClicked={() => onTabChange(i)}
            isActive={activeTab === i}
            fullWidth={fullWidth}
            bordered={borderedItems}
          />
        ))}
      </TabsWrapper>

      {!!tabContents.length && (
        <S.Body>{tabContents[activeTab]}</S.Body>
      )}
    </>
  );
}

const tabPropType = PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
  id: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
})]);

TabContainer.propTypes = {
  selectedTab: PropTypes.string,
  tabs: PropTypes.arrayOf(tabPropType).isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func,
  h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullWidth: PropTypes.bool,
  noGap: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  withoutPadding: PropTypes.bool,
  borderedItems: PropTypes.bool,
};

TabContainer.defaultProps = {
  selectedTab: '',
  children: null,
  onChange: () => {},
  h: 50,
  fullWidth: false,
  noGap: null,
  withoutBorder: false,
  withoutPadding: false,
  borderedItems: false,
};
