import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';

import theme from 'styles/theme';
import GlobalStyles from 'styles/globalStyles';

import AppContext from './context';

const DEFAULT_COLORS = {
  main: '#3AC09D',
  font: '#1F2D5C',
  background: '#F3F6FA',
};

const getColors = ({ main, font, background }) => {
  return {
    main: main || DEFAULT_COLORS.main,
    font: font || DEFAULT_COLORS.font,
    background: background || DEFAULT_COLORS.background,
  };
};

export default function AppContextProvider({ children }) {
  const [appData, setAppData] = useState({});
  const [colorTheme, setColorTheme] = useState(DEFAULT_COLORS);
  const [isCompactEntity, setIsCompactEntity] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [isSidebarShown, setSidebarShow] = useState(false);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    portal: appData?.portal,
    user: appData?.customer,
    stripeAccountId: appData?.stripeAccountId,
    isServicePlansAvailable: appData?.isServicePlansAvailable,
    isBankAccountConnected: appData?.isBankAccountConnected,
    pendingEstimates: appData?.pendingEstimates,
    country: appData?.country,
    setAppData,
    setColorTheme,
    colorTheme,
    appointment: {
      isCompact: isCompactEntity,
      toggleCompact: () => setIsCompactEntity(!isCompactEntity),
    },
    sidebar: {
      isShow: isSidebarShown,
      toggle: () => setSidebarShow(!isSidebarShown),
      hide: () => setSidebarShow(false),
    },
    isPreviewMode,
    setIsPreviewMode,
  };

  const appTheme = {
    ...theme,
    companyColors: getColors(colorTheme),
  };

  return (
    <AppContext.Provider value={values}>
      <ThemeProvider theme={appTheme}>
        <GlobalStyles />

        {children}
      </ThemeProvider>
    </AppContext.Provider>
  );
}

AppContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
