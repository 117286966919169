import styled, { keyframes } from 'styled-components';

import { CloseIcon } from 'assets/icons';

import { getDefaultColor, spacing, font } from 'styles/theme';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50%) translateZ(0);
  }
  to {
    opacity: 1;
    transform: translateY(0) translateZ(0);
  }
`;

export const Backdrop = styled.div`
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(13, 12, 34, 0.4);
  z-index: 100;
  animation: ${fadeIn} 0.19s ease-out forwards;
  align-items: center;
  overflow-y: auto;
`;

export const ModalContainer = styled.div`
  background: ${getDefaultColor('neutral100')};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.33s ease-in;
  padding: ${spacing('large')};
  border-radius: 16px;
  margin: auto auto;
  display: grid;
  gap: 24px;
  width: auto;
  max-width: 100%;
`;

export const CloseButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  color: ${getDefaultColor('neutral500')};

  &:hover {
    color: ${getDefaultColor('dark')};
  }
`;

export const CloseIconStyled = styled(CloseIcon)`
  width: 36px;
  height: 36px;

  fill: ${getDefaultColor('neutral500')};
  stroke: ${getDefaultColor('neutral500')};

  &:hover {
    fill: ${getDefaultColor('dark')};
    stroke: ${getDefaultColor('dark')};
  }
`;

export const ModalTitle = styled.h3`
  font-size: 32px;
  color: ${getDefaultColor('dark')};
  line-height: 45px;
  font: ${font('blockTitle')};
`;

export const Image = styled.img`
  width: 480px;
  height: 480px;
  border-radius: 8px;

  object-fit: contain;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;
