import ContentLoader from 'react-content-loader';

export default function ProfileBtnLoader() {
  return (
    <ContentLoader
      speed={2}
      width={120}
      height={30}
      viewBox="0 0 120 30"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <circle cx="15" cy="15" r="15" />
      <rect x="38" y="9" rx="3" ry="3" width="100" height="11" />
    </ContentLoader>
  );
}
