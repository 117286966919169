import PropTypes from 'prop-types';
import SimpleLayout from 'layouts/simple-layout';
import Content404 from 'components/404-content';

export default function NoMatches({
  isUnlogged,
  title,
  message,
}) {
  return (
    <SimpleLayout size="large">
      <Content404
        isUnlogged={isUnlogged}
        title={title}
        message={message}
      />
    </SimpleLayout>
  );
}

NoMatches.propTypes = {
  isUnlogged: PropTypes.bool,
  title: PropTypes.string,
  message: PropTypes.string,
};

NoMatches.defaultProps = {
  isUnlogged: false,
  title: '',
  message: '',
};
