import React from 'react';
import PropTypes from 'prop-types';

import { toPixels } from 'styles/theme';

import * as S from './styles';

const TabsWrapper = ({
  children,
  noGap,
  h,
  fullWidth,
  withoutBorder,
  withoutPadding,
}) => {
  return (
    <S.Tabs
      noGap={noGap}
      h={toPixels(h)}
      fullWidth={fullWidth}
      withoutBorder={withoutBorder}
      withoutPadding={withoutPadding}
    >
      {children}
    </S.Tabs>
  );
};

TabsWrapper.propTypes = {
  children: PropTypes.node,
  noGap: PropTypes.bool,
  h: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fullWidth: PropTypes.bool,
  withoutBorder: PropTypes.bool,
  withoutPadding: PropTypes.bool,
};

TabsWrapper.defaultProps = {
  children: null,
  noGap: false,
  h: 50,
  fullWidth: false,
  withoutBorder: false,
  withoutPadding: false,
};

export default TabsWrapper;
