import styled from 'styled-components';
import {
  companyColor,
  font,
  fluidFont,
  defaultColor,
} from 'styles/theme';

export const AppointmentBox = styled.div`
  position: relative;
`;

export const ContentBox = styled.div`
  border: 1px solid ${defaultColor('backgroundLight')};
  border-radius: 8px;
  padding: 25px 20px;
  color: ${companyColor('font')};

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 4px 0.75rem rgba(0, 0, 0, 0.05);
`;

export const Title = styled.div`
  margin-top: 1rem;
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
`;
export const SubTitle = styled.div`
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  width: 50%;
`;

export const Employees = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const Label = styled.div`
  color: ${defaultColor('metallic')};
  font-family: ${font('semibold')};
  ${fluidFont('14px', '15px')};
`;

export const Info = styled.div`
  font-family: ${font('semybold')};
  ${fluidFont('12px', '14px')};
  color: ${companyColor('font')};
  margin-bottom: 10px;
`;

export const InfoLink = styled.a`
  font-family: ${font('semybold')};
  ${fluidFont('13px', '14px')};
  line-height: 13px;
  color: ${companyColor('main')};
  cursor: pointer;
  margin-bottom: 0px;
  &:hover {
    text-decoration: underline;
  }
`;

export const TotalAmount = styled.div`
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
`;

export const SubAmount = styled.div`
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  ${fluidFont('14px', '14px')};
`;

export const SubTitleSmall = styled.div`
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  ${fluidFont('14px', '14px')};
`;

export const SummaryBox = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
