import LogoutButton from 'components/logout-button';
import { useApp } from 'hooks';
import ProfileBtnLoader from './loader';
import * as S from './styles';

export default function ProfileButton() {
  const { user } = useApp();

  return (
    <S.Wrapper>
      <S.ProfileBtn disabled={!user} to="profile">
        {!user ? (
          <ProfileBtnLoader />
        ) : (
          `${user.firstName} ${user.lastName}`
        )}
      </S.ProfileBtn>
      <LogoutButton />
    </S.Wrapper>
  );
}
