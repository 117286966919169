import { useTransition } from 'react-spring';
import PropTypes from 'prop-types';

import * as S from './styles';

function SelectMenu(props) {
  const {
    onSelect,
    isShown,
    options,
    selectedValue,
    fullWidth,
    noOptionsMessage,
  } = props;

  const transitions = useTransition(isShown, {
    config: {
      duration: 210,
    },
    from: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
  });

  return transitions((styles, item) => item && (
    <S.List $fullWidth={fullWidth} style={styles}>
      {options.length > 0 ? (
        options.map((option, index) => (
          <li
            key={`${option.value}${String(index)}`}
            style={{ display: 'list-item' }}
          >
            <S.ListItem
              onClick={() => onSelect(option.value)}
              isActive={selectedValue === option.value}
            >
              {option.label}
            </S.ListItem>
          </li>
        ))
      ) : (
        <S.NoOptions>{noOptionsMessage || 'No options available'}</S.NoOptions>
      )}
    </S.List>
  ));
}

SelectMenu.propTypes = {
  onSelect: PropTypes.func.isRequired,
  isShown: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  })),
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fullWidth: PropTypes.bool,
  noOptionsMessage: PropTypes.string,
};

SelectMenu.defaultProps = {
  options: [],
  selectedValue: '',
  fullWidth: false,
  noOptionsMessage: 'No options',
};

export default SelectMenu;
