const ServicesStep = () => true;

const ServiceDetailsStep = () => true;

const ContactDetailsStep = ({ errors }) => (
  !errors.address
  && !errors.unitNumber
  && !errors.date
  && !errors.time
);

export default [
  ServicesStep,
  ServiceDetailsStep,
  ContactDetailsStep,
];
