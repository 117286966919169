import ContentLoader from 'react-content-loader';
import theme from 'styles/theme';

export default function CompanyInfoLoader() {
  return (
    <ContentLoader
      speed={2}
      viewBox="0 0 120 45"
      backgroundColor={theme.defaultColors.white}
      foregroundColor={theme.defaultColors.backgroundLight}
    >
      <rect x="0" y="0" rx="3" ry="3" width="70" height="15" />
      <rect x="0" y="20" rx="3" ry="3" width="100" height="8" />
      <rect x="0" y="33" rx="3" ry="3" width="100" height="8" />
    </ContentLoader>
  );
}
