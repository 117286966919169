import styled from 'styled-components';
import { ReactComponent as MenuIcon } from 'assets/icons/menu-icon.svg';
import { device, companyColor, spacing } from 'styles/theme';

export const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 0 ${spacing('medium')};
  background-color: ${companyColor('background')};
  grid-area: header;
  position: fixed;
  z-index: 3;

  @media(${device('laptop')}) {
    display: none;
  }
`;

export const Icon = styled(MenuIcon)`
  fill: ${companyColor('font')};
`;
