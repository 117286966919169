import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { useApp } from 'hooks';
import { getServicePlanSubscriptions } from 'api';
import ServicePlanItem from 'components/ui-kit/service-plan-item';
import { servicePlanSubscriptionStatuses } from 'helpers/enums';
import { useOutletContext } from 'react-router-dom';
import ListPlaceholder from 'components/ui-kit/list-placeholder';
import ServicePlanLoader from './loader';
import * as S from './styles';

const LOADER_ITEMS = [1, 2, 3];
const pendingStatus = Number(servicePlanSubscriptionStatuses.pending.value);

const getFilterByStatus = (status) => {
  switch (status) {
    case 'active':
      return (item) => item.status !== pendingStatus;
    case 'pending':
      return (item) => item.status === pendingStatus;
    default:
      return () => true;
  }
};

const filterSubscriptions = (fetchedItems) => {
  const pending = fetchedItems.filter(getFilterByStatus('pending'));

  return {
    pending,
    pendingCount: pending.length,
    active: fetchedItems.filter(getFilterByStatus('active')),
  };
};

export default function ServicePlansListing({ show }) {
  const { portal } = useApp();
  const [, setPendingCount] = useOutletContext();

  const [items, setItems] = useState([]);

  const {
    isError, isLoading, data, isSuccess, refetch,
  } = useQuery(
    ['subscriptions', portal?.id],
    getServicePlanSubscriptions,
    {
      enabled: !!portal?.id,
    },
  );

  useEffect(() => {
    if (portal?.id) {
      refetch();
    }
  }, [show, refetch, portal]);

  useEffect(() => {
    if (!isSuccess) return;

    const processedData = filterSubscriptions(data.data);
    setPendingCount(processedData.pendingCount);

    const itemsToDisplay = show === 'active'
      ? processedData.active
      : processedData.pending;

    setItems(itemsToDisplay);
  }, [isSuccess, data?.data, setPendingCount, show]);

  if (isLoading) {
    return (
      <S.Box>
        {LOADER_ITEMS.map((item) => (
          <ServicePlanLoader key={`${String(item)}`} />
        ))}
      </S.Box>
    );
  }

  if (isError) {
    return (
      <S.TextInfo>Error occured while fetching subscriptions data</S.TextInfo>
    );
  }

  if (!data?.data.length && isSuccess) {
    return (
      <ListPlaceholder
        message="No service subscriptions. Request a service subscription"
      />
    );
  }

  return (
    <S.Box>
      {items?.map((item, index) => (
        <ServicePlanItem
          key={`${String(index)}`}
          id={item.id}
          name={item.servicePlanName}
          address={item.location?.location}
          status={item.status}
          startDate={item.startDate}
          endDate={item.endDate}
          visitsPerYear={item.numberOfVisitsPerYear}
        />
      ))}
    </S.Box>
  );
}

ServicePlansListing.propTypes = {
  show: PropTypes.string,
};

ServicePlansListing.defaultProps = {
  show: '',
};
