import { useState, useCallback } from 'react';

const useFields = (initialState) => {
  const [fields, setFields] = useState(initialState);

  const handleChange = useCallback((event) => {
    setFields({
      ...fields,
      [event.target.id]: event.target.value,
    });
  }, [fields]);

  const handleClickChange = useCallback((value, id) => {
    setFields({
      ...fields,
      [id]: value,
    });
  }, [fields]);

  const handleAddChange = useCallback((value, id) => {
    const previousValue = fields[id];
    setFields({
      ...fields,
      [id]: [...previousValue, value],
    });
  }, [fields]);

  const handleRemoveChange = useCallback((value, id) => {
    const resultValue = [...fields[id]];
    const removeIndex = resultValue.indexOf(value);
    if (removeIndex > -1) { resultValue.splice(removeIndex, 1); }
    setFields({
      ...fields,
      [id]: resultValue,
    });
  }, [fields]);

  return {
    fields,
    handleChange,
    handleClickChange,
    handleAddChange,
    handleRemoveChange,
  };
};

export default useFields;
