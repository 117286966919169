import styled from 'styled-components';
import { animated } from '@react-spring/web';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import {
  radius,
  companyColor,
  fluidFont,
} from 'styles/theme';

export const Box = styled(animated.div)`
  border-radius: ${radius('small')};
  background-color: ${companyColor('background')};
  height: auto;
`;

export const Toggle = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  width: 100%;
  height: 50px;
  padding: 1rem;
  justify-content: space-between;
  color: ${companyColor('font')};
  ${fluidFont('12px', '14px')};
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
`;

export const Icon = styled(Chevron)`
  fill: ${companyColor('font')};
  transition: transform 250ms ease-in-out;

  &.opened {
    transform: rotateX(180deg);
  }
`;

export const AnimatedWrapper = styled(animated.div)`
  will-change: height, opacity, transform;
  overflow: hidden;
`;

export const Content = styled(animated.div)`
  will-change: opacity, transform;
`;

export const ContentBody = styled.div`
  padding: 0 1rem 1rem 1rem;
`;
