export const truncate = (str, n) => {
  return !!str && (str.length > n) ? `${str.substring(0, n - 1)}...` : str;
};

export const capitalizeFirst = (str) => (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase());

export const capitalizeWords = (str) => (str.split(' ').map((word) => capitalizeFirst(word)).join(' '));

export const getPluralForm = (count, singular, plural = `${singular}s`) => {
  return (count % 10 === 1 && count % 100 !== 11) ? singular : plural;
};
