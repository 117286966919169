import React from 'react';
import PropTypes from 'prop-types';

import { toPixels } from 'styles/theme';

import { FlexBox } from './style';

function Flex({
  children,
  direction,
  justifyContent,
  justifyItems,
  alignItems,
  gap,
  ...rest
}) {
  return (
    <FlexBox
      direction={direction}
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      alignItems={alignItems}
      gap={toPixels(gap)}
      {...rest}
    >
      {children}
    </FlexBox>
  );
}

Flex.propTypes = {
  children: PropTypes.node.isRequired,
  direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse']),
  justifyContent: PropTypes.oneOf([
    'initial',
    'center',
    'flex-end',
    'flex-start',
    'space-around',
    'space-between',
  ]),
  justifyItems: PropTypes.oneOf([
    'initial',
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'self-start',
    'self-end',
    'left',
    'right',
  ]),
  alignItems: PropTypes.oneOf(['initial', 'center', 'flex-end', 'flex-start']),
  gap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Flex.defaultProps = {
  direction: 'row',
  justifyContent: 'initial',
  justifyItems: 'initial',
  alignItems: 'initial',
  gap: 8,
};

export default Flex;
