import styled from 'styled-components';
import {
  defaultColor,
  radius,
  font,
  fluidFont,
  companyColor,
} from 'styles/theme';

const getColor = (colorName, opacity) => (
  colorName === 'font'
    ? companyColor(colorName, opacity)
    : defaultColor(colorName, opacity)
);

const getOpacity = (colorName) => (
  colorName === 'font'
    ? 0.04
    : 0.15
);

export const Box = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  text-transform: capitalize;
  color: ${({ $color }) => getColor($color)};
  background-color: ${({ $color }) => getColor($color, getOpacity($color))};
  font-family: ${font('semibold')};
  ${fluidFont('10px', '12px')}
  border-radius: ${radius('extraSmall')};
`;
