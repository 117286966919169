import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function PageHeader({
  title,
  children,
  actionItems,
}) {
  return (
    <S.HeaderWrapper>
      <S.Title>{title}</S.Title>
      <S.Children>
        {children}
      </S.Children>
      <S.ActionItems>
        {actionItems}
      </S.ActionItems>
    </S.HeaderWrapper>
  );
}

PageHeader.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]),
  actionItems: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]),
};

PageHeader.defaultProps = {
  children: null,
  actionItems: [],
};
