import styled from 'styled-components';
import { defaultColor, device } from 'styles/theme';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ size }) => (
    size === 'large' ? 'repeat(9, 1fr)' : 'repeat(8, 1fr)'
  )};
  background-color: ${defaultColor('background')};
  align-items: center;
  height: 100vh;
`;

export const Content = styled.main`
  grid-column: 2 / 8;

  @media(${device('tablet')}) {
    grid-column: ${({ size }) => (
    size === 'large' ? '4 / 7' : '4 / 6'
  )};
  }
`;
