import PageHeader from 'components/ui-kit/page-header';
import { InfoBox, InfoBoxItem, InfoBoxGroup } from 'components/ui-kit/info-box';
import InfoPlaceholder from 'components/ui-kit/info-placeholder';
import getPhoneNumber from 'helpers/getPhoneNumber.helper';
import { useApp } from 'hooks';

export default function Profile() {
  const { user } = useApp();

  // Billing address move to first position
  const properties = user?.addresses?.map((x, index) => ({
    ...x,
    title: x.location === user.billingAddress?.location ? 'Billing property' : `Property ${index + 1}`,
  }));
  const sortedProperties = properties?.sort((a, b) => ((a.title > b.title ? 1 : -1)));

  return (
    <>
      <PageHeader title="Profile" />
      <InfoBoxGroup>
        <InfoBox title="Personal Info">
          <InfoBoxItem title="Name" value={`${user?.firstName} ${user?.lastName}`} />
          <InfoBoxItem title="Email" value={user?.email} />
          <InfoBoxItem
            title="Primary Phone"
            value={getPhoneNumber(user?.phoneNumber) || '-'}
          />
          {user?.secondaryPhoneNumbers?.length > 0 && (
            <InfoBoxItem
              title="Additional Phone(s)"
              value={user?.secondaryPhoneNumbers.map((n) => `${getPhoneNumber(n)}\n`)}
            />
          )}
        </InfoBox>
        <InfoBox title="Properties">
          {(sortedProperties?.length)
            ? sortedProperties?.map((item, index) => (
              <InfoBoxItem
                key={`address-${String(index)}`}
                title={item?.title}
                value={item?.location}
                description={item?.unitNumber ? `Unit #: ${item.unitNumber}` : ''}
              />
            ))
            : <InfoPlaceholder text="No properties set" />}
        </InfoBox>
      </InfoBoxGroup>
    </>
  );
}
