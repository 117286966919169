import styled from 'styled-components';
import { spacing, device, sidebarSize } from 'styles/theme';

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: 'main main main';
  height: 100vh;

  @media(${device('laptop')}) {
    grid-template-columns: ${sidebarSize} 1fr 372px;
    grid-template-areas: 'sidebar main aside';
  }
`;

export const Main = styled.main`
  grid-area: main;
  padding: ${spacing('extraLarge')} ${spacing('medium')};
  overflow: ${({ isBlur }) => (isBlur ? 'hidden' : 'initial')};

  &:after {
    content: '';
    display: block;
    visibility: ${({ isBlur }) => (isBlur ? 'visible' : 'hidden')};
    background-color: ${({ isBlur }) => (isBlur ? 'rgba(0, 0, 0, 0.75)' : 'transparent')};
    position: fixed;
    backdrop-filter: blur(5px);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    transition: background-color 210ms ease-in-out;
    z-index: 1;
  }

  @media(${device('tablet')}) and (orientation: landscape) {
    padding: ${spacing('layoutBox')};
  }
  

  @media(${device('laptop')}) {
    padding: ${spacing('layoutBox')};
    &:after {
      display: none;
    }
  }
`;
