import Button from 'components/ui-kit/buttons/button';
import PageHeader from 'components/ui-kit/page-header';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { useModal } from 'hooks';
import AddPaymentMethodModal from 'components/add-payment-method';
import * as S from './styles';

export default function PaymentsHeader() {
  const { showModal } = useModal();
  const AddIcon = (
    <S.PlusIconWrapper>
      <PlusIcon />
    </S.PlusIconWrapper>
  );

  return (
    <PageHeader
      title="Payments"
      actionItems={(
        <Button icon={AddIcon} onClick={() => showModal(AddPaymentMethodModal)}>
          Add payment method
        </Button>
      )}
    />
  );
}
