import styled from 'styled-components';
import {
  font,
  fluidFont,
  defaultColor,
  companyColor,
  radius,
  device,
} from 'styles/theme';

export const LogoutBtn = styled.button.attrs({
  type: 'button',
})`
  border: 1px solid ${defaultColor('backgroundLight')};
  outline: none;
  cursor: pointer;
  background: none;
  color: ${companyColor('font')};
  font-family: ${font('semibold')};
  font-weight: 500;
  ${fluidFont('10px', '13px')};
  transition: background-color 190ms linear;
  flex-basis: ${({ isMobile }) => (isMobile ? 'initial' : '21%')};

  &:hover {
    background-color: ${companyColor('main', 0.15)};
  }
  border-top-right-radius: ${radius('small')};
  border-bottom-right-radius: ${radius('small')};
  display: flex;
  align-items: center;
  justify-content: ${({ isMobile }) => (isMobile ? 'flex-start' : 'center')};
  margin-bottom: ${({ isMobile }) => (isMobile ? '1rem' : '0')};

  @media(${device('laptop')}) {
    ${({ isMobile }) => (isMobile ? 'display: none' : 'display: flex')};
  }

  & svg {
    stroke: ${companyColor('font')};
  }
`;

export const Text = styled.span`
  margin-left: 0.5rem;
`;
