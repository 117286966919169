import { useEffect, memo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useApp } from 'hooks';

import {
  getInvoiceDetails,
  getInvoicePdfAsync,
  getInvoiceReceiptPdfAsync,
} from 'api';

import { getFullName } from 'helpers/business.helper';

import DateLine from 'components/ui-kit/date-line';
import Breadcrumbs from 'components/ui-kit/breadcrumbs';
import PaymentSummary from 'components/ui-kit/payment-summary';
import PageHeader from 'components/ui-kit/page-header';
import ButtonLink from 'components/ui-kit/buttons/button-link';
import InvoicePayButton from 'components/invoices/invoice-pay-button';
import Money from 'components/ui-kit/money';

import InvoiceItemLoader from './loader';
import { getBreadcrumbs, populateAmount } from './utils';

import * as S from './styles';

const InvoiceItemDetails = () => {
  const { portal, isBankAccountConnected } = useApp();
  const { id: invoiceId } = useParams();
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();

  const stripeRedirectResult = searchParams.get('stripe_result');

  useEffect(() => {
    let invalidatorTimeout;

    if (stripeRedirectResult === 'success') {
      invalidatorTimeout = setTimeout(() => {
        queryClient.invalidateQueries(`invoice-${invoiceId}`);
        setSearchParams({});
      }, 3000);
    }

    if (stripeRedirectResult === 'cancel') {
      setSearchParams({});
    }

    return () => {
      clearInterval(invalidatorTimeout);
    };
  }, [stripeRedirectResult, invoiceId, queryClient, setSearchParams]);

  const {
    isLoading, data, isError, isFetched,
  } = useQuery(
    [`invoice-${invoiceId}`, portal?.id, invoiceId],
    getInvoiceDetails,
    {
      enabled: !!portal?.id,
    },
  );

  if (isLoading) {
    return <InvoiceItemLoader />;
  }

  if (isError) {
    return <h1>Error</h1>;
  }

  if (!data) {
    return null;
  }

  const { data: invoice } = data;
  const {
    company,
    customer,
    jobTitle,
    invoiceNumber,
    totalAmount,
    lineItems,
    taxRate,
    discount,
    sentDate,
    paidDate,
    message,
    billingAddress,
    preCalculatedSummary,
  } = invoice || {};
  const populatedLineItems = lineItems?.map(populateAmount);
  const isPaid = !!paidDate;

  const getInvoiceButton = () => (
    <S.ButtonGroup>
      {isPaid && (
        <ButtonLink
          newTab
          href={getInvoiceReceiptPdfAsync(portal?.id, invoiceId)}
          icon={<S.DownloadIcon />}
        >
          Download Receipt
        </ButtonLink>
      )}

      <ButtonLink
        newTab
        href={getInvoicePdfAsync(portal?.id, invoiceId)}
        icon={<S.DownloadIcon />}
      >
        Download Invoice
      </ButtonLink>
    </S.ButtonGroup>
  );

  return (
    isFetched && (
      <>
        <Breadcrumbs
          gap="1rem"
          items={getBreadcrumbs(jobTitle || 'Job')}
        />

        <PageHeader
          title={jobTitle || 'Job'}
          actionItems={getInvoiceButton()}
        />

        <S.Box>
          <S.Header>
            <S.InvoiceNumber>
              <S.TextLine family="bold" size={24}>
                Invoice
              </S.TextLine>

              <S.TextLine family="bold" color="metallic" size={24}>
                {invoiceNumber}
              </S.TextLine>
            </S.InvoiceNumber>

            <S.AmountDue>
              <S.TextLine color="metallic">Amount due (USD)</S.TextLine>

              <S.TextLine family="bold" size={28}>
                <Money amount={totalAmount} />
              </S.TextLine>
            </S.AmountDue>

            <S.CompanyInfo>
              <S.CompanyInfoItem>
                {`${company?.location.location}
                  ${company?.location.unitNumber ? `, ${company?.location.unitNumber}` : ''}`}
              </S.CompanyInfoItem>

              <S.CompanyInfoItem>
                {company?.businessPhoneNumber}
              </S.CompanyInfoItem>

              <S.CompanyInfoItem>{company?.email}</S.CompanyInfoItem>
            </S.CompanyInfo>
          </S.Header>

          <S.Divider />

          <S.General>
            <S.TextGroup area="billedTo">
              <S.TextGroupHeader>Billed to</S.TextGroupHeader>

              <S.TextGroup row>
                <S.TextLine family="semibold">
                  {getFullName(customer)}
                </S.TextLine>

                {customer.ownCompanyName && (
                  <>
                    <S.TextLine>|</S.TextLine>

                    <S.TextLine>{customer.ownCompanyName}</S.TextLine>
                  </>
                )}
              </S.TextGroup>

              <S.TextLine maxWidth="80%">
                {billingAddress.location}
              </S.TextLine>

              {billingAddress.unitNumber && (
                <S.TextLine maxWidth="80%">
                  Unit #:&nbsp;
                  {billingAddress.unitNumber}
                </S.TextLine>
              )}
            </S.TextGroup>

            <S.TextGroup area="number">
              <S.TextGroupHeader>Invoice number</S.TextGroupHeader>

              <S.TextLine>{invoiceNumber}</S.TextLine>
            </S.TextGroup>

            <S.DatesWrapper>
              <S.TextGroup area="dates">
                <DateLine
                  label="Sent Date"
                  date={sentDate}
                  placeholder={sentDate ? null : 'n/a'}
                />

                <DateLine
                  label="Due Date"
                  date={sentDate}
                  placeholder="Upon receipt"
                />

                <DateLine
                  label="Paid Date"
                  date={paidDate}
                />
              </S.TextGroup>
            </S.DatesWrapper>
          </S.General>

          <S.PaymentSummary>
            <PaymentSummary
              isPaid={isPaid}
              title="Payment Summary"
              lineItems={populatedLineItems}
              taxRate={taxRate}
              discount={discount}
              preCalculatedSummary={preCalculatedSummary || {}}
            />
          </S.PaymentSummary>

          <S.PayButton>
            {(!isPaid && isBankAccountConnected) ? (
              <InvoicePayButton
                stretched
                color="primary"
                invoiceId={invoiceId}
                portalId={portal?.id}
              />
            ) : <div />}
          </S.PayButton>

          {!!message && (
            <S.TextGroup area="message">
              <S.TextGroupHeader>Invoice Notes</S.TextGroupHeader>

              <S.TextLine>{message}</S.TextLine>
            </S.TextGroup>
          )}
        </S.Box>
      </>
    )
  );
};

export default memo(InvoiceItemDetails);
