import React from 'react';
import PropTypes from 'prop-types';
import Fieldbox from 'components/ui-kit/form/field-box';

import * as S from '../styles';

function Textarea({ childRef, size, ...restProps }) {
  return (
    <Fieldbox
      {...restProps}
      isTextareaValue
    >
      <S.Area
        ref={childRef}
        size={size}
      />
    </Fieldbox>
  );
}

Textarea.propTypes = {
  childRef: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.node]),
  size: PropTypes.oneOf(['small', 'medium']),
};

Textarea.defaultProps = {
  childRef: null,
  size: 'small',
};

export default React.memo(Textarea);
