import Modal from 'components/ui-kit/modal';
import { useApp } from 'hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from 'config';
import InputCardForm from './form';

const poppinsFontSource = {
  cssSrc: 'https://fonts.googleapis.com/css2?family=Poppins&display=swap',
};

export default function AddPaymentMethodModal() {
  const { stripeAccountId } = useApp();

  const stripePromise = loadStripe(
    config.stripePublicKey,
    { stripeAccount: stripeAccountId },
  );

  return (
    <Modal title="Add payment method">
      <Elements stripe={stripePromise} options={{ fonts: [poppinsFontSource] }}>
        <InputCardForm />
      </Elements>
    </Modal>
  );
}
