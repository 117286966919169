import { Outlet } from 'react-router-dom';

import NavSwitcher from 'components/ui-kit/nav-switcher';
import PageHeader from 'components/ui-kit/page-header';

const switcherList = [
  {
    label: 'Upcoming',
    path: '',
  },
  {
    label: 'Past',
    path: 'past',
  },
];

const Appointments = () => {
  return (
    <>
      <PageHeader
        title="Appointments"
      />

      <NavSwitcher items={switcherList} />

      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Appointments;
