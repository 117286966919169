import React from 'react';
import { useQuery } from 'react-query';
import { getServicePlanSubscription } from 'api';
import { useParams } from 'react-router-dom';
import routes from 'router/routes';
import format from 'date-fns/format';
import PageHeader from 'components/ui-kit/page-header';
import Breadcrumbs from 'components/ui-kit/breadcrumbs';
import {
  servicePlanStatuses,
} from 'constants/service-plans';
import { servicePlanSubscriptionStatuses } from 'helpers/enums';
import ServicePlanLoader from './loader';
import * as S from './styles';
import PaymentsBlock from './components/payments-block';
import VisitsBlock from './components/visitsBlock';

export default function ServicePlanItemDetails() {
  const { portalId, id } = useParams();
  const {
    isError, isLoading, isFetching, data,
  } = useQuery(
    ['appointment', portalId, id],
    getServicePlanSubscription,
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!portalId,
    },
  );

  if (isError) {
    return 'Error occured while fetching appointment';
  }
  if (isLoading || isFetching) {
    return <ServicePlanLoader />;
  }
  const servicePlanSubscription = data?.data;
  const isPending = Number(servicePlanSubscriptionStatuses.pending.value) === servicePlanSubscription?.status;
  return (
    servicePlanSubscription
    && (
    <>
      <Breadcrumbs
        gap="1rem"
        items={[
          {
            text: 'Service plans',
            to: `/${portalId}/${routes.servicePlans.path}`,
          },
          { text: servicePlanSubscription.name },
        ]}
      />

      <S.Box>
        <PageHeader title={servicePlanSubscription.name} />

        <S.Info>
          <S.InfoItem>
            <S.Label>Status:</S.Label>

            {servicePlanStatuses[servicePlanSubscription.status]}
          </S.InfoItem>

          <S.InfoItem>
            <S.Label>Visits per year:</S.Label>

            {servicePlanSubscription.numberOfVisitsPerYear}
          </S.InfoItem>

          {!isPending && (
            <S.InfoItem>
              <S.Label>Billing period:</S.Label>

              {servicePlanSubscription.billingPeriod}
            </S.InfoItem>
          )}

          <S.InfoItem>
            <S.Label>Start date:</S.Label>

            {format(new Date(servicePlanSubscription.startDate), 'MM/dd/yyyy')}
          </S.InfoItem>

          <S.InfoItem>
            <S.Label>End date:</S.Label>

            {servicePlanSubscription.endDate ? format(new Date(servicePlanSubscription.endDate), 'MM/dd/yyyy') : '-'}
          </S.InfoItem>
        </S.Info>

        {servicePlanSubscription?.description && (
          <S.InfoItem stretch>
            <S.Label>Service Plan Details:</S.Label>

            {servicePlanSubscription.description}
          </S.InfoItem>
        )}

        <S.InfoItem stretch>
          <S.Label>Service property:</S.Label>

          <div>{servicePlanSubscription.address}</div>

          {servicePlanSubscription.unitNumber && (
            <div>
              Unit #:&nbsp;
              {servicePlanSubscription.unitNumber}
            </div>
          )}
        </S.InfoItem>

        {servicePlanSubscription.payments !== null
              && servicePlanSubscription.payments.length > 0
              && <PaymentsBlock payments={servicePlanSubscription.payments} />}

        {servicePlanSubscription.visits !== null
              && servicePlanSubscription.visits.length > 0
              && <VisitsBlock visits={servicePlanSubscription.visits} />}
      </S.Box>
    </>
    )
  );
}
