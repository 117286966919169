export const servicePlanStatuses = {
  0: 'Pending',
  1: 'Active',
  4: 'Cancelled',
};

export const paymentMethodTypes = {
  0: 'Cash',
  1: 'Check',
  2: 'Card',
  3: 'Other',
};

export const paymentStatuses = {
  0: 'Not paid',
  1: 'Paid',
  2: 'Pending',
  3: 'Failed',
  4: 'Uncollectible',
};

export const visitStatues = {
  1: 'Scheduled',
  2: 'On the way',
  3: 'In process',
  4: 'Finished',
  5: 'Paid',
  6: 'Canceled',
};
