import PropTypes from 'prop-types';
import InfoBoxItem from './info-box-item';
import InfoBoxGroup from './info-box-group';
import * as S from './styles';

function InfoBox({ title, children }) {
  return (
    <S.Box>
      <S.BoxTitle>{title}</S.BoxTitle>
      <S.List>
        {children}
      </S.List>
    </S.Box>
  );
}

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export { InfoBox, InfoBoxItem, InfoBoxGroup };
