import Breadcrumbs from 'components/ui-kit/breadcrumbs';
import ServicePlanInvoiceListing from 'components/service-plan-subscription-payments/listing';

export default function ServicePlansPayments() {
  return (
    <>
      <Breadcrumbs items={[
        {
          text: 'Service Plans', to: '../service-plans',
        },
        {
          text: 'Payments',
        },
      ]}
      />
      <ServicePlanInvoiceListing />
    </>
  );
}
