import { useState } from 'react';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { Input } from 'components/ui-kit/form/input';

import config from 'config';
import { useOutsideClick } from 'hooks';

import SelectMenu from '../formik-select/menu';

import * as S from './styles';

const { googleApiKey } = config;
const countryRestrictions = {
  country: 'us',
};

const LocationInput = ({
  form,
  field,
  geoDataName,
  label,
  isGap,
}) => {
  const [isShown, setShown] = useState(false);

  const { setFieldValue } = form;
  const { onChange, name } = field;

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
  } = usePlacesAutocompleteService({
    apiKey: googleApiKey,
    debounce: 600,
    libraries: ['places', 'geometry'],
  });

  async function getPlaceDetails(placeId) {
    return new Promise((resolve, reject) => {
      placesService.getDetails({ placeId }, (details, status) => {
        if (status !== 'OK') {
          reject();
        }
        resolve(details);
      });
    });
  }

  async function setPlace(place) {
    const details = await getPlaceDetails(place.place_id);
    if (!details.geometry) return;

    const geoData = {
      placeId: details.place_id,
      latitude: details.geometry.location.lat(),
      longitude: details.geometry.location.lng(),
    };

    const humanValue = details.formatted_address || place.description;

    setFieldValue(geoDataName, geoData);
    setFieldValue(name, humanValue);
    setShown(false);
  }

  const handleBlur = () => {
    setShown(false);
  };

  const selectRef = useOutsideClick(() => {
    if (isShown) {
      handleBlur();
    }
  });

  const handleChangeByGoogle = (val) => {
    getPlacePredictions({ input: val, componentRestrictions: countryRestrictions });
    setShown(true);
  };

  const handleInputChange = (e) => {
    onChange(e);
    handleChangeByGoogle(e.target.value);
    setShown(!!e.target.value);
    setFieldValue(geoDataName, {});
  };

  const onSelectClick = async (val) => {
    await setPlace(val);
    handleBlur();
  };

  return (
    <S.Select ref={selectRef}>
      <Field
        component={Input}
        name={name}
        label={label}
        onChange={handleInputChange}
        isGap={isGap}
      />

      <SelectMenu
        onSelect={onSelectClick}
        isShown={isShown}
        options={placePredictions.map((item) => {
          return { label: item.description, value: item };
        })}
        fullWidth
      />
    </S.Select>
  );
};

LocationInput.propTypes = {
  form: PropTypes.shape({
    setFieldValue: PropTypes.func.isRequired,
  }).isRequired,
  field: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  label: PropTypes.string,
  geoDataName: PropTypes.string,
  isGap: PropTypes.bool,
};

LocationInput.defaultProps = {
  geoDataName: 'geoData',
  label: 'Property Input',
  isGap: true,
};

export default LocationInput;
