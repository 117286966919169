import PropTypes from 'prop-types';

import DateLine from 'components/ui-kit/date-line';
import RequestTitle from 'components/ui-kit/request-title';
import Money from 'components/ui-kit/money';

import { estimateApprovalTypes } from 'helpers/enums';

import * as S from './styles';

const approvalStatuses = Object.values(estimateApprovalTypes);

const RequestItem = ({
  id,
  title,
  sentDate,
  address,
  unitNumber,
  totalAmount,
  approvalStatus,
  estimateNumber,
  optionNumber,
  hasOptions,
}) => {
  return (
    <S.Box to={id} state={{ title }}>
      <S.TypeIcon />

      <RequestTitle
        title={title}
        number={estimateNumber}
        option={optionNumber}
        hasOptions={hasOptions}
        baseFontSize={14}
      />

      <S.Dates>
        <DateLine date={sentDate} label="Sent Date" placeholder="n/a" />

        <div>{address}</div>

        {unitNumber && (
          <div>
            Unit #:&nbsp;
            {unitNumber}
          </div>
        )}
      </S.Dates>

      <S.Amount>
        <Money amount={totalAmount} />
      </S.Amount>

      <S.Status>
        <S.StatusText $statusColor={approvalStatuses[approvalStatus].color}>
          {approvalStatuses[approvalStatus].label}
        </S.StatusText>
      </S.Status>
    </S.Box>
  );
};

RequestItem.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sentDate: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  unitNumber: PropTypes.string,
  totalAmount: PropTypes.number.isRequired,
  approvalStatus: PropTypes.number.isRequired,
  estimateNumber: PropTypes.string.isRequired,
  optionNumber: PropTypes.number,
  hasOptions: PropTypes.bool,
};

RequestItem.defaultProps = {
  unitNumber: '',
  optionNumber: 0,
  hasOptions: false,
};

export default RequestItem;
