import styled from 'styled-components';
import { ReactComponent as InvoicesIcon } from 'assets/icons/my-money-square.svg';

export const PaymentsIcon = styled(InvoicesIcon)`
  height: 24px;
  fill: currentColor;
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 2rem;
`;
