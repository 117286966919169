import styled from 'styled-components';

import {
  companyColor, defaultColor, fluidFont, font, getDefaultColor,
} from 'styles/theme';

import { EyeIcon } from 'assets/icons';

export const Table = styled.table`
  border: 1px solid ${defaultColor('background')};
  border-radius: 12px;
  width: 100%;
  border-spacing: 0px;
  overflow: hidden;
  margin-top: 10px;
  & > tbody > tr:last-child > td {
    border-bottom: none;
  }
`;

export const TableHeader = styled.th`
  text-align: right;
  &:first-child {
    text-align: left;
  }
  background-color: ${defaultColor('background')};
  font-family: ${font('semibold')};
  ${fluidFont('11px', '13px')};
  color: ${companyColor('font')};
  padding: 1rem 1.25rem;
`;

export const Cell = styled.td`
  text-align: right;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid ${defaultColor('background')};
  color: ${({ isBold }) => (isBold ? companyColor('font') : defaultColor('font'))};
  font-family: ${({ isBold }) => (isBold ? font('semibold') : font('regular'))};
  ${fluidFont('12px', '14px')};

  min-width: ${({ sizes }) => sizes.minWidth};
  width: ${({ sizes }) => sizes.width};

  &:first-child {
    text-align: left;
  }
`;

export const LineItemDescription = styled.div`
  color: ${defaultColor('dark')};
  white-space: pre-line;
  line-height: 1.3;
`;

export const StyledImageWrapper = styled.div`
  position: relative;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;

`;

export const StyledImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 2px;
  object-fit: contain;
`;

export const Placeholder = styled.div`
  width: 64px;
  height: 64px;
  background: ${getDefaultColor('white')};
`;

export const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  background: rgba(31, 45, 92, 0.8);

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  transition: opacity 0.3s;

  &:hover {
    opacity: 1;
  }
`;

export const IconButton = styled.button`
  background: none;
  border: none;
  color: white;
  margin: 0 5px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ShowIcon = styled(EyeIcon)`
  width: 24px;
  height: 24px;
  stroke: ${getDefaultColor('white')};
  fill: ${getDefaultColor('white')};
`;
