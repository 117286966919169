import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../flex';

const Group = ({ children, direction, ...rest }) => {
  return (
    <Flex direction={direction} {...rest}>
      {children}
    </Flex>
  );
};

Group.propTypes = {
  direction: PropTypes.oneOf(['row', 'row-reverse']),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.node,
  ]).isRequired,
};

Group.defaultProps = {
  direction: 'row',
};

export default Group;
