import styled from 'styled-components';
import {
  font, defaultColor, fluidFont, companyColor,
} from 'styles/theme';

const getFontColor = ({ isError, isDisabled }) => {
  if (isError && isDisabled) {
    return defaultColor('red', 0.5);
  }

  if (isError) {
    return defaultColor('red');
  }

  if (isDisabled) {
    return defaultColor('metallic');
  }

  return companyColor('font', 0.5);
};

export const Label = styled.label`
  color: ${(props) => (getFontColor(props))};
  font-family: ${font('regular')};
  ${fluidFont('12px', '14px')};
  position: absolute;
  left: 1rem;
  top: 25px;
  width: 95%;
  transform: translateY(-50%) scale(1);
  transition: all 190ms ease-in-out;
  pointer-events: none;
  transform-origin: 0 0;
  &.label_up {
    transform: translateY(-1.2rem) scale(0.85);
    width: 100%;
    color: ${defaultColor('metallic')};
  }

  &.hide_label {
    display: none;
  }
`;

export const Addon = styled.div`
  position: absolute;
  right: 1rem;
`;

export const LabelContent = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;

  &.label_up {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    -webkit-box-align: start;
    overflow: hidden;
    white-space: normal;
  }
`;

export const HelpIconWrapper = styled.div`
  margin-bottom: -4px;
  pointer-events: all;
  position: relative;
  & svg {
    fill: ${defaultColor('grey')};
    width: 16px;
    height: 16px;
  }
  &:hover svg {
    fill: #7583B5;
  }
`;
