import PropTypes from 'prop-types';
import * as S from './styles';

export default function SuccessView({ email }) {
  return (
    <S.SuccessView>
      <S.Rocket />
      <S.SuccessTitle>
        Magic Link Sent!
      </S.SuccessTitle>
      <S.SuccessMessage>
        We sent an email to you at
        <S.CustomerEmail>
          {' '}
          {email}
          {' '}
        </S.CustomerEmail>
        It has a magic link that will sign you in.
      </S.SuccessMessage>
    </S.SuccessView>
  );
}

SuccessView.propTypes = {
  email: PropTypes.string.isRequired,
};
