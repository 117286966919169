import styled from 'styled-components';
import {
  companyColor,
  fluidFont,
  spacing,
} from 'styles/theme';

export const Listing = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('default')};
`;

export const TextInfo = styled.span`
  ${fluidFont('12px', '18px')};
  color: ${companyColor('font')};
`;
