import React from 'react';
import PropTypes from 'prop-types';
import * as S from './styles';

export default function ButtonGroup({
  justifyContent,
  justifyItems,
  alignItems,
  flexDirection,
  children,
  topGap,
}) {
  return (
    <S.Group
      justifyContent={justifyContent}
      justifyItems={justifyItems}
      alignItems={alignItems}
      flexDirection={flexDirection}
      topGap={topGap}
    >
      {children}
    </S.Group>
  );
}

ButtonGroup.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.node,
  ]).isRequired,
  justifyContent: PropTypes.oneOf(['initial', 'center', 'flex-end', 'flex-start', 'space-around', 'space-between']),
  justifyItems: PropTypes.oneOf([
    'initial',
    'center',
    'start',
    'end',
    'flex-start',
    'flex-end',
    'self-start',
    'self-end',
    'left',
    'right',
  ]),
  alignItems: PropTypes.oneOf(['initial', 'center', 'flex-end', 'flex-start']),
  flexDirection: PropTypes.oneOf(['initial', 'column', 'row']),
  topGap: PropTypes.string,
};

ButtonGroup.defaultProps = {
  justifyContent: 'initial',
  justifyItems: 'initial',
  alignItems: 'initial',
  flexDirection: 'initial',
  topGap: 'initial',
};
