import { colord } from 'colord';

export const get = (obj, key, def, p, undef) => {
  let objKey = key;
  let targetObj = obj;
  let point = p;
  objKey = objKey && objKey.split ? objKey.split('.') : [objKey];
  for (point = 0; point < objKey.length; point += 1) {
    targetObj = targetObj ? targetObj[objKey[point]] : undef;
  }
  return targetObj === undef ? def : targetObj;
};

export const hexToRgba = (hex) => {
  const color = colord(hex);

  if (!color.isValid()) {
    // eslint-disable-next-line no-throw-literal
    throw 'Not valid hex color provided';
  }

  return color.rgba;
};

export const colorThemeVar = (path, opacity, fallback = null) => ({ theme }) => {
  const hex = get(theme, path, fallback);
  if (typeof opacity !== 'number') {
    return hex;
  }

  const {
    r, g, b, a,
  } = hexToRgba(hex);
  return `rgba(${r}, ${g}, ${b}, ${opacity * a})`;
};

export const themeVar = (path, fallback = null) => ({ theme }) => get(theme, path, fallback);

export const viewports = {
  mobileSmall: '280px',
  mobile: '320px',
  tablet: '768px',
  laptop: '1366px',
  laptopHidpi: '1440px',
  desktop: '1920px',
};
