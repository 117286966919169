import styled from 'styled-components';
import { companyColor, radius, spacing } from 'styles/theme';
import { ReactComponent as Eraser } from 'assets/icons/eraser.svg';

export const SignatureBox = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid ${companyColor('background')};
  border-radius: ${radius('small')};

  position: relative;
`;

export const ClearButton = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;

  position: absolute;
  bottom: 20px;
  right: 20px;

  display: flex;
  align-items: center;
  gap: ${spacing('extraSmall')};
`;

export const EraserIcon = styled(Eraser)`
  fill: ${companyColor('font')}
`;
