import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ReactComponent as Download } from 'assets/icons/download.svg';
import {
  companyColor,
  font,
  fluidFont,
  radius,
  defaultColor,
  device,
} from 'styles/theme';

export const Title = styled.div`
  display: flex;
  align-self: start;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
`;

export const Box = styled.div`
  border-radius: ${radius('small')};
  border: 1px solid ${defaultColor('background')};
  padding: 20px;
  width: 100%;
  gap: 1rem;
  display: flex;
  flex-direction: column;

  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 4px 0.75rem rgba(0, 0, 0, 0.05);
`;

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  border-bottom: 1px solid ${defaultColor('background')};
`;

export const Label = styled.div`
  color: ${defaultColor('dark')};
  font-family: ${font('semibold')};
  ${fluidFont('14px', '15px')};
`;

export const Info = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 1rem;

  @media only screen and (${device('laptop')}) {
    flex-direction: row;
    gap: 0;
  }
`;

export const InfoItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  font-family: ${font('semybold')};
  ${fluidFont('12px', '14px')};
  color: ${companyColor('font')};
  grid-column: ${({ stretch }) => (stretch ? '1 / -1' : 'auto')};
  white-space: pre-line;
`;

export const HeaderPath = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: -35px 0 20px 0
  `;

export const SubRoute = styled.div`
  color: ${defaultColor('metallic')};
  ${fluidFont('12px', '13px')};
`;

export const Route = styled(Link)`
  ${fluidFont('14px', '14px')};
  color: ${companyColor('font')};
  text-decoration: none;
`;

export const SubTitleSmall = styled.div`
  gap: 10px;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  ${fluidFont('14px', '14px')};
`;

export const Cell = styled.td`
  text-align: right;
  &:first-child {
    text-align: left;
  }
  padding: 15px 20px;
  border-bottom: 1px solid ${defaultColor('background')};
  color: ${({ isBold }) => (isBold ? companyColor('font') : defaultColor('font'))};
  font-family: ${({ isBold }) => (
    isBold ? font('semibold') : font('regular')
  )};
  ${fluidFont('11px', '13px')}
`;

export const CellWide = styled(Cell)`
  min-width: 160px;
`;

export const DownloadLink = styled.a`
  font-family: ${font('semibold')};
  ${fluidFont('11px', '13px')};
  color: ${companyColor('font')};
  padding: 8px 16px;
  background-color: ${defaultColor('background')};
  border-radius: 5px;
  text-decoration: none;
`;

export const DownloadIcon = styled(Download)`
  fill: ${companyColor('font')};
  width: 17px;
  height: 17px;
  margin-bottom: -5px;
`;

export const Table = styled.div`
  width: 100%;
  overflow-x: auto;

  @media only screen and (${device('laptop')}) {
    overflow: unset;
  }
`;
