import { ReactComponent as AmericanExpressIcon } from 'assets/icons/cards/amex.svg';
import { ReactComponent as DinersIcon } from 'assets/icons/cards/diners.svg';
import { ReactComponent as DiscoverIcon } from 'assets/icons/cards/discover.svg';
import { ReactComponent as JcbIcon } from 'assets/icons/cards/jcb.svg';
import { ReactComponent as MastercardIcon } from 'assets/icons/cards/mastercard.svg';
import { ReactComponent as UnionPayIcon } from 'assets/icons/cards/unionpay.svg';
import { ReactComponent as VisaIcon } from 'assets/icons/cards/visa.svg';

const icons = {
  AmericanExpressIcon,
  DinersIcon,
  DiscoverIcon,
  JcbIcon,
  MastercardIcon,
  UnionPayIcon,
  VisaIcon,
};

export default icons;
