import React from 'react';
import PropTypes from 'prop-types';
import {
  visitStatues,
} from 'constants/service-plans';
import GenericTable from 'components/ui-kit/generic-table';
import format from 'date-fns/format';
import * as S from '../../styles';

export default function VisitsBlock({ visits }) {
  const visitDateCell = (value, index) => (
    <S.Cell key={index}>
      {format(new Date(value), 'MMM dd, yyyy')}
    </S.Cell>
  );

  const visitStatusCell = (value, index) => (
    <S.Cell key={index}>
      {visitStatues[value]}
    </S.Cell>
  );

  const visitColumns = [
    { name: 'description', label: 'Visit' },
    { name: 'invoiceNumber', label: 'Invoice #' },
    { name: 'scheduledStartTime', label: 'Date', component: visitDateCell },
    { name: 'status', label: 'Status', component: visitStatusCell },
  ];

  return (
    <>
      <S.Separator />
      <S.SubTitleSmall>List of Visits:</S.SubTitleSmall>
      <S.Table>
        <GenericTable
          items={visits.sort((item) => item.scheduledStartTime)}
          columns={visitColumns}
        />
      </S.Table>
    </>
  );
}

VisitsBlock.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  visits: PropTypes.arrayOf(PropTypes.object).isRequired,
};
