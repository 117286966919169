import React, { useEffect, useRef } from 'react';

import Group from '../ui-kit/group';

import { useViewAttachmentModal } from './context';

import {
  Backdrop,
  ModalContainer,
  ModalTitle,
  CloseButton,
  CloseIconStyled,
  Image,
} from './styles';

const ViewAttachmentModal = () => {
  const { isOpen, closeViewAttachmentModal, modalProps } = useViewAttachmentModal();
  const backdropRef = useRef();
  const { title = 'View Attachment', imageUrl } = modalProps;

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (backdropRef.current && e.target === backdropRef.current) {
        closeViewAttachmentModal();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [closeViewAttachmentModal]);

  if (!isOpen) return null;

  return (
    <Backdrop isVisible={isOpen} ref={backdropRef}>
      <ModalContainer>
        <Group w="100%" justifyContent="space-between" align="center">
          <ModalTitle>{title}</ModalTitle>

          <CloseButton
            onClick={closeViewAttachmentModal}
          >
            <CloseIconStyled width="20" height="20" />
          </CloseButton>
        </Group>

        <Group justifyContent="center">
          <Image src={imageUrl} alt="Attachment" />
        </Group>
      </ModalContainer>
    </Backdrop>
  );
};

export default ViewAttachmentModal;

export { useViewAttachmentModal };
