import PropTypes from 'prop-types';
import * as S from './styles';

export default function PaidSection({ justifyContent }) {
  return (
    <S.Paid justifyContent={justifyContent}>
      <S.PaidIcon />
      Paid
    </S.Paid>
  );
}

PaidSection.propTypes = {
  justifyContent: PropTypes.string,
};

PaidSection.defaultProps = {
  justifyContent: 'start',
};
