import { useState } from 'react';
import PropTypes from 'prop-types';
import { useTransition } from '@react-spring/core';
import { useOutsideClick } from 'hooks';
import { getIn } from 'formik';
import * as S from './styles';
import 'react-calendar/dist/Calendar.css';

export default function Datepicker(props) {
  const {
    value,
    form,
    onClickChange,
    name,
    label,
    defaultValue,
    minDate,
    maxDate,
    placeholder,
    selectRange,
    errorMessage,
    disableDateFilter,
    setFieldTouched,
    ...restProps
  } = props;
  const [isShown, setIsShown] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = useOutsideClick(() => {
    setIsShown(false);
  });
  const transitions = useTransition(isShown, {
    config: {
      duration: 210,
    },
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(20px)' },
  });

  const touched = getIn(form?.touched, name);
  const error = getIn(form?.errors, name);
  const isError = Boolean(touched?.date && error?.date);
  const handleFocus = () => {
    setIsFocused(true);
    setIsShown(true);
  };

  const handleBlur = () => {
    if (setFieldTouched) {
      setFieldTouched('date', true);
    }
  };

  return (
    <div ref={inputRef}>
      <S.Input
        value={value}
        name={name}
        id={name}
        isFocused={isFocused}
        onFocus={handleFocus}
        onBlur={handleBlur}
        placeholder={placeholder}
        label={label}
        isError={isError}
        errorMessage={errorMessage}
        {...restProps}
      />
      {transitions((styles, item) => item && (
        <S.PickerMenu style={styles}>
          <S.CalendarBox
            selectRange={selectRange}
            defaultValue={defaultValue}
            minDate={minDate}
            maxDate={maxDate}
            onChange={(v) => onClickChange(v, name)}
            tileDisabled={disableDateFilter}
          />
        </S.PickerMenu>
      ))}
      {isError && (
        <S.ErrorMessage>{error?.date}</S.ErrorMessage>
      )}
    </div>
  );
}

Datepicker.propTypes = {
  form: PropTypes.shape({
    touched: PropTypes.shape({}),
    errors: PropTypes.shape({}),
  }),
  setFieldTouched: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  placeholder: PropTypes.string,
  selectRange: PropTypes.bool,
  onClickChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.instanceOf(Date)), PropTypes.instanceOf(Date)]),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  isError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disableDateFilter: PropTypes.func,
};

Datepicker.defaultProps = {
  form: null,
  setFieldTouched: null,
  label: null,
  minDate: null,
  maxDate: null,
  placeholder: '',
  selectRange: true,
  isError: false,
  errorMessage: null,
  defaultValue: null,
  disableDateFilter: () => false,
};
