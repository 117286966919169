import React from 'react';
import PropTypes from 'prop-types';

import Flex from '../flex';

function Stack({ children, direction, ...rest }) {
  return (
    <Flex direction={direction} {...rest}>
      {children}
    </Flex>
  );
}

Stack.propTypes = {
  direction: PropTypes.oneOf(['column', 'column-reverse']),
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.node,
  ]).isRequired,
};

Stack.defaultProps = {
  direction: 'column',
};

export default Stack;
