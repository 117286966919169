import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';

import { CheckIcon } from 'assets/icons';

import { toPixels } from 'styles/theme';

import { OriginalCheckbox, StyledCheckbox } from './styles';

const NoTextCheckbox = ({
  checked,
  onChange,
  size,
  disabled,
}) => {
  const uniqueId = uuidv4();

  const handleClick = () => {
    if (disabled) return;

    if (onChange) {
      onChange(!checked);
    }
  };

  return (
    <>
      <OriginalCheckbox
        id={uniqueId}
        checked={checked}
        onChange={() => {}}
        aria-labelledby={`${uniqueId}-label`}
      />

      <StyledCheckbox
        checked={checked}
        disabled={disabled}
        size={toPixels(size)}
        onClick={handleClick}
      >
        <CheckIcon />
      </StyledCheckbox>
    </>
  );
};

NoTextCheckbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  size: PropTypes.number,
  disabled: PropTypes.bool,
};

NoTextCheckbox.defaultProps = {
  size: 20,
  disabled: false,
};

export default NoTextCheckbox;
