import api from './api';

// export const login = (params) => {
//   const { portalId, token } = params;
//   return api.post(`${portalId}/account/sign-in/${token}`);
// }

// USER

export const sendMagikLink = (portalId, email) => {
  return api.post(`${portalId}/account/magic-link`, { email });
};

export const login = ({ queryKey }) => {
  const [, portalId, token] = queryKey;
  return api.post(`${portalId}/account/sign-in/${token}`);
};

export const logout = (portalId) => {
  return api.post(`${portalId}/account/log-out`);
};

export const getPortal = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/`);
};

export const getPortalPreview = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/preview`);
};

export const getCurrent = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/account/current`);
};

export const getAllAppointements = ({ queryKey }) => {
  const [, portalId, { from, to, type }] = queryKey;
  const businessType = type === null ? '' : `&businessEntityType=${type}`;

  return api.get(
    `${portalId}/appointment/all?from=${from}&to=${to}${businessType}`,
  );
};

export const getAppointments = ({ queryKey }) => {
  const [, portalId, from, to] = queryKey;
  return api.get(
    `${portalId}/appointment/all?from=${from.toISOString()}&to=${to.toISOString()}`,
  );
};

export const getAppointment = ({ queryKey }) => {
  const [, portalId, id] = queryKey;
  return api.get(`${portalId}/appointment?id=${id}`);
};

// ESTIMATES

export const getEstimatesRequests = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(
    `${portalId}/estimates/requests`,
  );
};

export const approveEstimate = (portalId, {
  id,
  signature,
  lineItems,
}) => {
  return api.put(`${portalId}/estimates/${id}/approve`, { signature, lineItems });
};

export const declineEstimate = (portalId, id) => {
  return api.put(`${portalId}/estimates/${id}/decline`);
};

export const requestEstimateChanges = ({ portalId, estimateId, content }) => {
  return api.post(`${portalId}/estimates/${estimateId}/request-changes`, { content });
};

// PAYMENTS

export const getCards = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/customer/cards`);
};

export const createSetupIntentSecret = (portalId) => {
  return api.post(`${portalId}/payments/setup-intent-secret`);
};

export const trySetDefaultCard = (portalId) => {
  return api.post(`${portalId}/payments/try-set-default`);
};

// SUBSCRIPTIONS

export const getServicePlanSubscriptions = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/service-plan-subscriptions`);
};

export const getServicePlanSubscription = ({ queryKey }) => {
  const [, portalId, id] = queryKey;
  return api.get(`${portalId}/service-plan-subscriptions/${id}`);
};

export const getServicePlanReceiptPdfAsync = (portalId, id) => {
  return `${api.defaults.baseURL}/${portalId}/service-plan-subscriptions/receipt-pdf/${id}`;
};

// INVOICES

export const getAllInvoices = ({ queryKey }) => {
  const [, portalId, params] = queryKey;
  return api.get(`${portalId}/invoices/all`, { params });
};

export const getInvoiceDetails = ({ queryKey }) => {
  const [, portalId, invoiceId] = queryKey;
  return api.get(`${portalId}/invoices/${invoiceId}`);
};

export const getServicePlanInvoices = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/service-plan-subscriptions/invoices`);
};

export const getInvoicePaymentSession = ({ queryKey }) => {
  const [, portalId, invoiceId] = queryKey;
  return api.get(`${portalId}/invoices/${invoiceId}/pay`);
};

export const payInvoiceWithSavedCard = (portalId, { paymentMethodId, invoiceId }) => {
  return api.put(`${portalId}/invoices/${invoiceId}/pay`, { paymentMethodId });
};

// ATTACHMENTS

export const getServiceVisitDetailsPdfAsync = (portalId, id) => {
  return `${api.defaults.baseURL}/${portalId}/appointment/visit/${id}/pdf`;
};

export const getInvoicePdfAsync = (portalId, id) => {
  return `${api.defaults.baseURL}/${portalId}/invoices/${id}/pdf`;
};

export const getInvoiceReceiptPdfAsync = (portalId, id) => {
  return `${api.defaults.baseURL}/${portalId}/invoices/${id}/receipt-pdf`;
};

export const getEstimatePdfAsync = (portalId, id, lineItems = []) => {
  const lineItemIdsString = lineItems
    .filter(({ optionalState }) => !optionalState || optionalState.isAccepted)
    .map(({ id: lineItemId }) => lineItemId)
    .join(',');
  const queryString = lineItemIdsString ? `?lineItemIds=${lineItemIdsString}` : '';

  return `${api.defaults.baseURL}/${portalId}/estimates/${id}/pdf${queryString}`;
};

// HELP CENTER

export const sendHelpRequest = (portalId, customerId, message) => {
  return api.post(`${portalId}/account/send-help-request`, { customerId, message });
};

// REQUEST WORK
export const getWorkReqeustForm = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/appointment/request-work`);
};

export const sendWorkReqeust = (portalId, data) => {
  return api.post(`${portalId}/appointment/request-work`, data);
};

export const isInServiceArea = ({ queryKey }) => {
  const [, portalId, latitude, longitude] = queryKey;
  return api.get(`${portalId}/company/check-service-area`, { params: { latitude, longitude } });
};

export const getPublicServicesAndLists = ({ queryKey }) => {
  const [, portalId] = queryKey;
  return api.get(`${portalId}/appointment/public-services`);
};

export const getCompanyEvents = (portalId, startDate, endDate) => {
  return api.get(`${portalId}/appointment/events`, { params: { startDate, endDate } });
};

export const uploadBookingDetailsImage = (portalId, file) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);

  return api.post(`/${portalId}/appointment/upload-question-image`, bodyFormData);
};

// CheckLists
export const getAppointmentChecklist = async ({
  portalId,
  eventId,
  checkListId,
  entityType,
}) => {
  return api.get(`${portalId}/appointment/${eventId}/checklist/${checkListId}/pdf`, {
    params: {
      businessEntityType: entityType,
    },
    responseType: 'blob',
  });
};

export default api;
