import styled, { css } from 'styled-components';
import {
  defaultColor,
  companyColor,
  font,
  radius,
} from 'styles/theme';

export const onAutoFillStartEventName = 'onAutoFillStart';
export const onAutoFillEndEventName = 'onAutoFillEnd';

export const InputStyles = css`
  height: 50px;
  border-width: 1px;
  border-style: solid;
  outline: none;
  padding: 0 8px;
  width: 100%;
  border-color: ${defaultColor('background')};
  min-width: ${({ size }) => (size === 'responsive' ? null : '100px')};
  background: none;
  font-family: ${font('regular')};
  font-size: 0.9rem;
  border-radius: ${radius('small')};
  color: ${companyColor('font')};
  transition: all 240ms linear;

  &:focus {
    border-color: ${defaultColor('dark')};
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 50px;
  
  font-family: ${font('regular')};
  color: ${companyColor('font')};

  outline: none;
  border-radius: ${radius('small')};
  border: 1px solid ${({ isError }) => { return isError ? defaultColor('red') : defaultColor('background'); }};
  transition: border-color 190ms ease-in-out;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.8rem;

  &.focused {
    border-color: ${({ isError }) => { return isError ? defaultColor('redDark') : companyColor('font'); }};
  }
`;

export const Area = styled.textarea`
  width: 100%;
  height: auto;
  min-height: ${({ size }) => (size === 'small' ? '100px' : '180px')};
  resize: vertical;
  font-family: ${font('regular')};
  color: ${companyColor('font')};

  outline: none;
  border-radius: ${radius('small')};
  border: 1px solid ${({ isError }) => { return isError ? defaultColor('red') : defaultColor('background'); }};
  transition: border-color 190ms ease-in-out;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.2rem;

  &.focused {
    border-color: ${({ isError }) => { return isError ? defaultColor('redDark') : companyColor('font'); }};
  }
`;
