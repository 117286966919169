import React, { useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import ModalContext from './modalContext';

export default function ModalProvider({ children }) {
  const [isExit, setLeaveAnimation] = useState(false);
  const [state, setState] = useState({
    component: null,
    props: {},
    onHide: noop,
  });

  function showModal(modalComponent, modalProps = {}, options = {}) {
    setLeaveAnimation(false);
    setState({
      component: modalComponent,
      props: modalProps,
      onHide: options.onHide || noop,
    });
  }

  function hideModal() {
    setLeaveAnimation(true);
    setTimeout(() => {
      state.onHide();
      setState({
        component: null,
        props: {},
        onHide: noop,
      });
    }, 270);
  }

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    component: state.component,
    props: state.props,
    showModal,
    hideModal,
    isExit,
  };

  return (
    <ModalContext.Provider value={values}>
      {children}
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object, PropTypes.array]).isRequired,
};
