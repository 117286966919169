import PropTypes from 'prop-types';
import * as S from './styles';

export default function GenericTable({ items, columns }) {
  return (
    <S.Table>
      <thead>
        <tr>
          {columns.map((col, i) => <S.TableHeader key={`${String(i)}`}>{col.label}</S.TableHeader>)}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr key={`${String(index)}`}>
            {columns.map((col, i) => {
              return col.component
                ? col.component(item[col.name], i, item)
                : <S.Cell key={`${String(i)}`}>{item[col.name]}</S.Cell>;
            })}
          </tr>
        ))}
      </tbody>
    </S.Table>
  );
}

GenericTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    component: PropTypes.func,
  })).isRequired,
};
