import React from 'react';
import PropTypes from 'prop-types';

import Typography from 'components/ui-kit/typography';
import Money from 'components/ui-kit/money';

import * as S from './styles';

const SubtotalSection = ({ title, amount }) => {
  return (
    <S.SubtotalLineBox>
      <Typography color="dark" fluidFont={{ min: 11, max: 13 }}>
        {`${title}`}
      </Typography>

      <Typography bold fluidFont={{ min: 11, max: 13 }}>
        <Money amount={amount} />
      </Typography>
    </S.SubtotalLineBox>
  );
};

SubtotalSection.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
};

export default SubtotalSection;
