import { useApp } from 'hooks';

export default function useCurrency() {
  const { country } = useApp();

  const getCurrency = () => {
    switch (country) {
      case 'US': return 'USD';
      case 'CA': return 'CAD';
      default: return 'USD';
    }
  };

  const formatCurrency = (amount, options = {}) => {
    if (typeof amount !== 'number') return options.fallback || '';

    const value = new Intl.NumberFormat('en', {
      style: 'decimal',
      minimumFractionDigits: options.minimumFractionDigits || 2,
      maximumFractionDigits: options.maximumFractionDigits || 2,
    }).format(amount);

    return `${value} ${getCurrency()}`;
  };

  return { getCurrency, formatCurrency };
}
