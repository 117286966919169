import styled, { css } from 'styled-components';
import { ReactComponent as ScheduleIcon } from 'assets/icons/schedule.svg';
import { Link } from 'react-router-dom';
import {
  defaultColor,
  companyColor,
  radius,
  font,
  fluidFont,
  device,
} from 'styles/theme';
import { ReactComponent as InvoiceIconSmall } from 'assets/icons/icon-invoice-small.svg';

const rowStyles = css`
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  column-gap: 1rem;
  grid-template-rows: repeat(3, max-content);
  grid-template-areas:
    'icon name date'
    'icon address invoice'
    'employees employees total';
`;

const colStyles = css`
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  column-gap: 5px;
  row-gap: 1rem;
  grid-template-areas:
    'icon name invoice'
    'status status status'
    'address address address'
    'employees employees employees'
    'total total total';
`;

const mobileStyles = css`
  display: grid;
  grid-template-columns: min-content 1fr max-content;
  column-gap: 5px;
  row-gap: 1rem;
  grid-template-areas:
    'icon name date'
    'status status invoice'
    'address address address'
    'employees employees employees'
    'total total total';
`;

const transition = css`
  transition: all 190ms ease-in-out;
`;

export const Box = styled(Link)`
  background-color: ${({ $isRow }) => (
    $isRow ? 'transparent' : defaultColor('white')
  )};
  border-radius: ${radius('small')};
  border: 1px solid ${({ $isRow }) => (
    $isRow ? defaultColor('background') : 'transparent'
  )};
  padding: 20px;
  position: relative;
  ${mobileStyles}
  ${transition};
  text-decoration: none;
  color: ${companyColor('font')};
  position: relative;

  &:hover {
    border-color: ${({ type }) => {
    switch (type) {
      case 0:
        return defaultColor('green');
      case 1:
        return defaultColor('blue');
      case 2:
        return defaultColor('yellow');
      default:
        return null;
    }
  }};
  }

  &:after {
    content: '';
    display: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 30%;
    width: 1px;
    height: 85%;
    background-color: ${defaultColor('background')};

    @media(${device('tablet')}) {
      display: ${({ $isRow }) => (
    $isRow ? 'block' : 'none'
  )};
    }
  }

  @media(${device('tablet')}) {
    ${({ $isRow }) => (
    $isRow ? rowStyles : colStyles
  )};
  }
`;

export const Header = styled.div`
  display: flex;
  align-self: start;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  justify-content: ${({ isRow }) => (isRow ? 'flex-start' : 'space-between')};
`;

export const Title = styled.div`
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  ${fluidFont('13px', '14px')};
  ${transition};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media(${device('tablet')}) {
    ${({ isCompact }) => (
    isCompact ? fluidFont('13px', '14px') : fluidFont('14px', '16px')
  )};
    max-width: ${({ isRow }) => (isRow ? '50%' : '100%')};
  }
`;

export const Status = styled.div`
  grid-area: status;
  color: ${companyColor('font')};
  font-family: ${font('regular')};
  ${fluidFont('10px', '12px')};
  align-self: center;

  @media only screen and (${device('tablet')}) {
    display: ${({ isRow }) => (isRow ? 'none' : 'block')};
  }
`;

export const StatusMark = styled.div`
  display: none;

  @media only screen and (${device('tablet')}) {
    display: flex;
  }
`;

export const Address = styled.div`
  grid-area: address;
  font-family: ${font('regular')};
  ${fluidFont('10px', '12px')};
  color: ${companyColor('font')};
  max-width: 85%;
`;

export const Employees = styled.div`
  position: relative;
  grid-area: employees;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 0;

  @media(${device('tablet')}) {
    flex-direction: ${({ isRow }) => (
    isRow ? 'row' : 'column'
  )};
    margin-top: ${({ isRow, isCompact }) => {
    if (!isRow && !isCompact) {
      return '0';
    }
    if (isRow && isCompact) {
      return '10px';
    }
    return '18px';
  }};
  }
`;

export const DateBox = styled.div`
  grid-area: date;
  align-self: center;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  gap: 0.15rem;
  font-family: ${font('semibold')};
  color: ${companyColor('font')};
  ${fluidFont('10px', '12px')};
  line-height: 13px;
  ${transition};

  @media(${device('tablet')}) {
    ${({ isCompact }) => (
    isCompact ? fluidFont('10px', '12px') : fluidFont('11px', '13px')
  )};
  }
`;

export const Date = styled.div`
  display: flex;
  gap: 0.15rem;
  align-items: center;
`;

export const Time = styled.div`
`;

export const DateIcon = styled(ScheduleIcon)`
  fill: ${companyColor('font')};
  width: 24px;
  height: 22px;
  margin-top: -2px;
`;

export const TypeIcon = styled.div`
  --type-icon-size: ${({ isRow, isCompact }) => {
    if (isRow && !isCompact) {
      return '42px';
    }
    if (isRow && isCompact) {
      return '34px';
    }
    if (!isRow && !isCompact) {
      return '24px';
    }
    return '42px';
  }};
  margin-right: 2px;
  grid-area: icon;
  width: 24px;
  height: 24px;
  ${transition};

  @media(${device('tablet')}) {
    width: var(--type-icon-size);
    height: var(--type-icon-size);
  }
`;

export const Total = styled.div`
  grid-area: total;
  align-self: end;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${font('semibold')};
  ${({ isRow, isCompact }) => {
    if (isRow && isCompact) {
      return fluidFont('16px', '18px');
    }
    if (!isRow && !isCompact) {
      return fluidFont('12px', '14px');
    }
    if (isRow && !isCompact) {
      return fluidFont('19px', '21px');
    }
    return null;
  }};
  color: ${companyColor('font')};
  ${transition};

  @media(${device('tablet')}) {
    justify-self: ${({ isRow }) => (
    isRow ? 'end' : 'stretch'
  )};
  }
`;

export const InvoiceIcon = styled(InvoiceIconSmall)`
  fill: ${defaultColor('green')};
`;

export const InvoiceRedirectButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;

  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2px;

  color: ${defaultColor('green')};
`;

export const InvoiceLink = styled(Link)`
  grid-area: invoice;
  align-self: start;
  justify-self: end;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 0.15rem;
  ${fluidFont('10px', '12px')};
  line-height: 13px;
  color: ${companyColor('main')};
  & svg {
    fill: ${companyColor('main')};
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const Label = styled.div`
  color: ${({ isBold }) => (isBold ? companyColor('font') : defaultColor('metallic'))};
  font-family: ${({ isBold }) => (
    isBold ? font('semibold') : font('regular')
  )};
  ${({ isBold }) => (
    isBold ? fluidFont('10px', '12px') : fluidFont('10px', '11px')
  )};
  text-transform: ${({ isBold }) => (
    isBold ? 'uppercase' : 'capitalize'
  )};

  @media(${device('tablet')}) {
    display: ${({ isRow }) => (isRow ? 'none' : 'block')};
  }
`;
