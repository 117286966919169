export const downloadFile = (blobData, name) => {
  const url = URL.createObjectURL(blobData);
  const a = document.createElement('a');
  a.download = name;
  a.href = url;
  a.target = '_self';
  a.click();

  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
};

export const getFileExtension = (fileUrl) => {
  return fileUrl
    .split('?')[0]
    .split('\\')
    .pop()
    .split('/')
    .pop()
    .split('.')
    .pop();
};

export const getFileName = (fileUrl) => {
  return fileUrl
    .split('?')[0]
    .split('\\')
    .pop()
    .split('/')
    .pop()
    .split('.')
    .slice(0, -1)
    .join('.');
};

export const getFullFileName = (fileUrl) => {
  return fileUrl
    .split('?')[0]
    .split('\\')
    .pop()
    .split('/')
    .pop();
};

export const convertMegabytesToBytes = (megabytes) => {
  return megabytes * 1024 * 1024;
};
